import * as Actions from "./openTitleActions";

export interface IOpenTitleState {
    titleId?: string,
    titleNumber?: string,
    isResolved: boolean
}

const initialState: IOpenTitleState = {
    titleId: undefined,
    titleNumber: undefined,
    isResolved: false
}

export function openTitleReducer(state: IOpenTitleState = initialState, action: Actions.IOpenTitleActions): IOpenTitleState {
    switch (action.type) {
        case Actions.openTitleActions.GetTitleIdRequest:
            return {
                titleNumber: action.titleNumber,
                titleId: undefined,
                isResolved: false
            };
        case Actions.openTitleActions.GetTitleIdResponse:
            return {
                ...state,
                titleId: action.titleId,
                isResolved: true
            };
        default:
            return state;
    }
}
