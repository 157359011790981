import * as Client from "../../api/Client";

export enum shellActions {
    setBusyRequest = "SHELL.SET_BUSY_REQUEST",
    clearBusyRequest = "SHELL.CLEAR_BUSY_REQUEST",
    setLayout = "SHELL.SET_LAYOUT",
    refreshStaticConfigurationRequest = "SHELL.REFRESH_STATIC_CONFIGURATION_REQUEST",
    refreshStaticConfigurationResponse = "SHELL.REFRESH_STATIC_CONFIGURATION_RESPONSE",
}

export interface IShellActions {
    type: shellActions;
    busyCount?: number;
    pageTitle?: string;
    showPageHeader?: boolean;
    includePadding?: boolean;
    isStaticConfigurationLoaded?: boolean;
    response?: Client.GetPublicShellFeatureSwitchesResponseDto | undefined;
}

export function setBusyAction() {
    return {
        type: shellActions.setBusyRequest,
    };
}

export function clearBusyAction() {
    return {
        type: shellActions.clearBusyRequest,
    };
}

export function setLayout(pageTitle: string, showPageHeader: boolean = true, includePadding: boolean = true): IShellActions {
    return {
        type: shellActions.setLayout,
        pageTitle: pageTitle,
        showPageHeader: showPageHeader,
        includePadding: includePadding,
    };
}

export function refreshStaticConfigurationRequestAction(): IShellActions {
    return {
        type: shellActions.refreshStaticConfigurationRequest,
    };
}

export function refreshStaticConfigurationResponseAction(response: Client.GetShellFeatureSwitchesResponseDto): IShellActions {
    return {
        type: shellActions.refreshStaticConfigurationResponse,
        isStaticConfigurationLoaded: true,
        response: response,
    };
}
