// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { ITitleDetailsActions, titleDetailsActions, getTitleDetailsResponseAction } from "./titleDetailsActions";
// api
import { createOpggsTitlesClient } from "../../../../api/util";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";
import { simpleCall } from "../../../../api/callHelper";

export default function* watcherSaga() {
    yield takeEvery(titleDetailsActions.GetTitleDetailsRequest, workerSaga);
}

function* workerSaga(action: ITitleDetailsActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicTitleDetailsResponseDto = yield call(query, action.titleId!);
        // post response
        yield put(getTitleDetailsResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(titleId: string): Promise<Client.GetPublicTitleDetailsResponseDto> {
    const client = createOpggsTitlesClient();

    const request = new Client.GetPublicTitleDetailsRequestDto({ titleId: titleId });

    const response = await simpleCall(() => client.getPublicTitleDetails(request));
    return response;
}
