// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// shell
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { clearOpggsApplicationTrackingDetailsAction, getOpggsApplicationTrackingDetailsRequestAction } from "../redux/opggsApplicationTrackingDetailsActions";
// children views
import OpggsApplicationTrackingDetailsView from "./OpggsApplicationTrackingDetailsView";
import { IApplicationTrackingDetailsState } from "../redux/opggsApplicationTrackingDetailsReducers";

interface IRouteParameters {
    applicationId: string;
}

function OpggsApplicationTrackingDetailsController(props: any) {
    const { applicationId } = useParams<IRouteParameters>();
    const { setLayout, getOpggsApplicationTrackingDetailsRequestAction, clearOpggsApplicationTrackingDetailsAction } = props;
    const state: IApplicationTrackingDetailsState = props.state;

    // Application Tracking Details
    const response = state.response;

    // - initial load
    useEffect(() => {
        getOpggsApplicationTrackingDetailsRequestAction(applicationId);

        return function onUnmount() {
            clearOpggsApplicationTrackingDetailsAction();
        };
    }, [getOpggsApplicationTrackingDetailsRequestAction, clearOpggsApplicationTrackingDetailsAction, applicationId]);

    // - set page header when response received
    useEffect(() => {
        if (!response) {
            setLayout("");
            return;
        }

        setLayout(response.titleNumber + " - " + response.applicationTypeName);
    }, [setLayout, response]);

    // return
    return <OpggsApplicationTrackingDetailsView response={response} />;
}

// connect redux
export default connect((state: any) => ({ state: state.applicationTrackingDetails }), { setLayout, getOpggsApplicationTrackingDetailsRequestAction, clearOpggsApplicationTrackingDetailsAction })(
    OpggsApplicationTrackingDetailsController
);
