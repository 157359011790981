import * as Client from "../../../api/Client";

export enum getWellActions {
    GetWellRequest = "NOPIMS.GET_WELL_REQUEST",
    GetWellResponse = "NOPIMS.GET_WELL_RESPONSE",

    GetReferenceDataRequest = "NOPIMS.GET_REFERENCE_DATA_REQUEST",
    GetReferenceDataResponse = "NOPIMS.GET_REFERENCE_DATA_RESPONSE",

    GetWellExportRequest = "NOPIMS.GET_WELL_EXPORT_REQUEST",
    GetWellExportResponse = "NOPIMS.GET_WELL_EXPORT_RESPONSE",
    ClearWellExport = "NOPIMS.GET_WELL_EXPORT_CLEAR",
}

export interface IGetWellActions {
    type: getWellActions;
    filterName?: string | undefined;
    filterTitleId?: string | undefined;
    filterBasinId?: number | undefined;
    filterJurisdictionId?: number | undefined;
    filterTitleholderId?: number | undefined;
    filterFromSpudDate?: Date | undefined;
    filterToSpudDate?: Date | undefined;
    items?: Client.GetPublicWellListItemDto[] | undefined;
    titles?: Client.PublicWellTitleDto[] | undefined;
    basins?: Client.PublicWellBasinDto[] | undefined;
    states?: Client.PublicWellStateTerritoryDto[] | undefined;
    titleholders?: Client.PublicWellTitleholderDto[] | undefined;
    exportResponse?: Client.GetPublicWellListExportResponseDto | undefined;
}

export function getWellRequestAction(
    filterName: string,
    filterTitleId: string,
    filterBasinId: number,
    filterJurisdictionId: number,
    filterTitleholderId: number,
    filterFromSpudDate: Date,
    filterToSpudDate: Date
): IGetWellActions {
    return {
        type: getWellActions.GetWellRequest,
        filterName: filterName,
        filterTitleId: filterTitleId,
        filterBasinId: filterBasinId,
        filterJurisdictionId: filterJurisdictionId,
        filterTitleholderId: filterTitleholderId,
        filterFromSpudDate: filterFromSpudDate,
        filterToSpudDate: filterToSpudDate,
    };
}

export function getWellResponseAction(items: Client.GetPublicWellListItemDto[]): IGetWellActions {
    return {
        type: getWellActions.GetWellResponse,
        items: items,
    };
}

export function getReferenceDataRequestAction(): IGetWellActions {
    return {
        type: getWellActions.GetReferenceDataRequest,
    };
}

export function getReferenceDataResponseAction(referenceData: Client.GetPublicWellReferenceDataResponseDto): IGetWellActions {
    return {
        type: getWellActions.GetReferenceDataResponse,
        titles: referenceData.titles,
        basins: referenceData.basins,
        states: referenceData.states,
        titleholders: referenceData.titleholders,
    };
}

export function getWellExportRequestAction(
    filterName: string,
    filterTitleId: string,
    filterBasinId: number,
    filterJurisdictionId: number,
    filterTitleholderId: number,
    filterFromSpudDate: Date,
    filterToSpudDate: Date
): IGetWellActions {
    return {
        type: getWellActions.GetWellExportRequest,
        filterName: filterName,
        filterTitleId: filterTitleId,
        filterBasinId: filterBasinId,
        filterJurisdictionId: filterJurisdictionId,
        filterTitleholderId: filterTitleholderId,
        filterFromSpudDate: filterFromSpudDate,
        filterToSpudDate: filterToSpudDate,
    };
}

export function getWellExportResponseAction(response: Client.GetPublicWellListExportResponseDto): IGetWellActions {
    return {
        type: getWellActions.GetWellExportResponse,
        exportResponse: response,
    };
}

export function clearWellExportResponseAction(): IGetWellActions {
    return {
        type: getWellActions.ClearWellExport,
    };
}
