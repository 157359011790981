import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../redux/shellActions";
import { LockIcon } from "@primer/octicons-react";

function UnauthorisedPage(props: any) {
    const { setLayout } = props;

    // initial load
    useEffect(() => {
        setLayout("Unauthorised", false);
    }, [setLayout]);

    return (
        <div className="container mt-5">
            <div className="p-5 mb-4 bg-light rounded-3">
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2" style={{ minWidth: 100 }}>
                            <LockIcon size={96} className="me-4" aria-label="unauthorised" />
                        </div>
                        <div className="col-10">
                            <h1 className="d-inline-block">You are not authorised to view this page or perform this action.</h1>
                            <p className="mt-4">If the problem persists, contact support for assistance.</p>
                            <p>
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.history.back();
                                    }}
                                >
                                    Go Back
                                </a>{" "}
                                | <a href="/">Return Home</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: any) => ({}), { setLayout })(UnauthorisedPage);
