// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { setLayout } from "../../../shell/redux/shellActions";
import NotFoundPage from "../../../shell/components/error/NotFoundPage";
// api
import * as Actions from "../redux/openLicenceActions";
import { getLicenceIdRequest } from "../redux/openLicenceActions";
import * as Reducers from "../redux/openLicenceReducers";
// other
import * as OpenLicenceHelper from "../openLicenceHelper";

interface IOpenLicenceControllerProps {
    state: Reducers.IOpenLicenceState;
    getLicenceIdRequest(licenceNumber: string): Actions.IOpenLicenceActions;
    setLayout(pageLicence: string, showPageHeader: boolean, includePadding: boolean): void;
}

function OpenLicenceController(props: IOpenLicenceControllerProps) {
    // using query string - /Open/Licence?licenceNumber=xxx
    // this allows for non URL encoded content to be passed through, as we can't assume callers are always able to url encode
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const details = OpenLicenceHelper.tryGetDetails(params);

    // intial load
    useEffect(() => {
        props.setLayout("Redirecting...", true, true);
        if (details) props.getLicenceIdRequest(details.licenceNumber);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // invalid details supplied, just redirect to the not-found page
    if (!details) return <NotFoundPage />;

    // resolved
    if (props.state.isResolved) {
        // doesn't exist
        if (props.state.licenceId === undefined) return <NotFoundPage />;

        // exists
        const url = OpenLicenceHelper.getRedirectUri(details, props.state.licenceId!);
        return <Redirect to={url} />;
    }

    // wait
    return <></>;
}

export default connect((state: any) => ({ state: state.openLicence }), { setLayout, getLicenceIdRequest })(OpenLicenceController);
