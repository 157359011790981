import { takeEvery, call, put } from "redux-saga/effects";
import { createOpggsTitlesClient } from "../../../../api/util";
import * as Client from "../../../../api/Client";
import { ITitleDetailsActions, titleDetailsActions } from "./titleDetailsActions";
import { getTitleMapDetailsResponseAction } from "./titleDetailsActions";
import { simpleCall } from "../../../../api/callHelper";

export default function* watcherSaga() {
    yield takeEvery(titleDetailsActions.GetTitleMapDetailsRequest, workerSaga);
}

function* workerSaga(action: ITitleDetailsActions) {
    // don't show busy state, as it's slow and not the main part of the screen
    try {
        const response: Client.GetPublicTitleDetailsMapResponseDto = yield call(query, action.titleId!);
        yield put(getTitleMapDetailsResponseAction(response));
    } catch {
        // suppress
    }
}

async function query(titleId: string): Promise<Client.GetPublicTitleDetailsMapResponseDto> {
    const client = createOpggsTitlesClient();

    const request = new Client.GetPublicTitleDetailsMapRequestDto({
        titleId: titleId,
    });

    const response = await simpleCall(() => client.getPublicTitleDetailsMap(request));
    return response;
}
