// framework
import { call, put, select } from "redux-saga/effects";
// api
import { createFeedbackClient } from "../../../../api/util";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";
// redux
import * as Actions from "../actions";
import { IFeedbackState } from "../reducers";
// models
import * as Models from "../../models/models";

const getRootViewModel = (state: IFeedbackState) => state.feedback;

export default function* submit() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    try {
        yield put(setBusyAction());
        const response: Client.CreatePublicFeedbackResponseDto = yield call(() => createPublicFeedbackCommand(vm));
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshFeedbackCreateResponse(response)));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function createPublicFeedbackCommand(vm: Models.IRootViewModel): Promise<Client.CreatePublicFeedbackResponseDto> {
    const client = createFeedbackClient();
    const request = new Client.CreatePublicFeedbackRequestDto({
        honorificId: vm.feedback.honorific?.id,
        customerFirstName: vm.feedback.customerFirstName!,
        customerLastName: vm.feedback.customerLastName!,
        customerCompanyName: vm.feedback.customerCompanyName,
        customerContactNumber: vm.feedback.customerContactNumber!,
        customerEmailAddress: vm.feedback.customerEmailAddress!,
        feedbackTypeId: vm.feedback.feedbackType!.id,
        dataFeedbackActivityTypeId: vm.feedback.dataFeedbackActivityType?.id,
        dataFeedbackActivityName: vm.feedback.dataFeedbackActivityName?.name,
        dataMonitoringItemTypeId: vm.feedback.dataMonitoringItemType?.id,
        customerComments: vm.feedback.customerComments!,
    });
    const response = await simpleCall(() => client.createPublicFeedback(request));
    return response;
}
