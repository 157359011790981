// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { setLayout } from "../../../shell/redux/shellActions";
import NotFoundPage from "../../../shell/components/error/NotFoundPage";
// api
import * as Actions from "../redux/openTitleActions";
import { getTitleIdRequest } from "../redux/openTitleActions";
import * as Reducers from "../redux/openTitleReducers";
// other
import * as OpenTitleHelper from "../openTitleHelper";

interface IOpenTitleControllerProps {
    state: Reducers.IOpenTitleState;
    getTitleIdRequest(titleNumber: string): Actions.IOpenTitleActions;
    setLayout(pageTitle: string, showPageHeader: boolean, includePadding: boolean): void;
}

function OpenTitleController(props: IOpenTitleControllerProps) {
    // using query string - /Open/Title?titleNumber=xxx
    // this allows for non URL encoded content to be passed through, as we can't assume callers are always able to url encode
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const details = OpenTitleHelper.tryGetDetails(params);

    // intial load
    useEffect(() => {
        props.setLayout("Redirecting...", true, true);
        if (details) props.getTitleIdRequest(details.titleNumber);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // invalid details supplied, just redirect to the not-found page
    if (!details) return <NotFoundPage />;

    // resolved
    if (props.state.isResolved) {
        // doesn't exist
        if (props.state.titleId === undefined) return <NotFoundPage />;

        // exists
        const url = OpenTitleHelper.getRedirectUri(details, props.state.titleId!);
        return <Redirect to={url} />;
    }

    // wait
    return <></>;
}

export default connect((state: any) => ({ state: state.openTitle }), { setLayout, getTitleIdRequest })(OpenTitleController);
