import * as Client from "../../../../api/Client";

export enum oeiApplicationTrackingDetailsActions {
    GetOeiApplicationTrackingDetailsRequest = "OEI_APPLICATION_TRACKING_DETAILS.REQUEST",
    GetOeiApplicationTrackingDetailsResponse = "OEI_APPLICATION_TRACKING_DETAILS.RESPONSE",
    ClearOeiApplicationTrackingDetails = "OEI_APPLICATION_TRACKING_DETAILS.CLEAR",
}

export interface IOeiApplicationTrackingDetailsActions {
    type: oeiApplicationTrackingDetailsActions;
    applicationId?: string | undefined;
    response?: Client.GetPublicOeiApplicationsTrackingDetailsResponseDto | undefined;
}

export function getOeiApplicationTrackingDetailsRequestAction(applicationId: string): IOeiApplicationTrackingDetailsActions {
    return {
        type: oeiApplicationTrackingDetailsActions.GetOeiApplicationTrackingDetailsRequest,
        applicationId: applicationId,
    };
}

export function getOeiApplicationTrackingDetailsResponseAction(response: Client.GetPublicOeiApplicationsTrackingDetailsResponseDto): IOeiApplicationTrackingDetailsActions {
    return {
        type: oeiApplicationTrackingDetailsActions.GetOeiApplicationTrackingDetailsResponse,
        response: response,
    };
}

export function clearOeiApplicationTrackingDetailsAction(): IOeiApplicationTrackingDetailsActions {
    return {
        type: oeiApplicationTrackingDetailsActions.ClearOeiApplicationTrackingDetails,
    };
}
