// framework
import React from "react";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";
// redux
import * as Actions from "../../redux/actions";
// models
import { IRootViewModel } from "../../models/models";

interface IProps {
    vm: IRootViewModel;
    actions: Actions.IActionFactory;
}

export default function FeedbackCreateSubmittedView(props: IProps): React.ReactElement {
    const { vm, actions } = props;
    const feedback = vm.feedback;

    function onReset() {
        actions.initialiseRequested();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="honorificField" label="Title" value={feedback.honorific?.name} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerFirstNameField" label="First Name" value={feedback.customerFirstName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerLastNameField" label="Last Name" value={feedback.customerLastName} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerCompanyNameField" label="Company" value={feedback.customerCompanyName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerContactNumberField" label="Contact Number" value={feedback.customerContactNumber} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerEmailAddressField" label="Email" value={feedback.customerEmailAddress} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Request Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="feedbackTypeField" label="Type" value={feedback.feedbackType?.name} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {feedback.feedbackType?.isDataFeedbackType && (
                        <>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="dataFeedbackActivityTypeField" label="Activity Type" value={feedback.dataFeedbackActivityType?.name} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="dataFeedbackActivityNameField" label="Activity Name" value={feedback.dataFeedbackActivityName?.name} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="dataMonitoringItemTypeField" label="Data Type" value={feedback.dataMonitoringItemType?.name} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea id="customerCommentsField" label="Comments" value={feedback.customerComments} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={onReset}>
                            New
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
