// framework
import GA4 from "react-ga4";
// common
import * as LogHelper from "../common/LogHelper";

export function initialise(measurementId: string | undefined) {
    if (!measurementId) {
        logError("initialise", "Measurement ID not provided.");
        return;
    }

    try {
        GA4.initialize(measurementId);
    } catch (ex) {
        logError("initialise", ex);
    }
}

export function setUserId(uniqueId: string) {
    try {
        GA4.set({ user_id: uniqueId });
    } catch (ex) {
        logError("setUserId", ex);
    }
}

export function clearUserId() {
    try {
        GA4.set({ user_id: undefined });
    } catch (ex) {
        logError("clearUserId", ex);
    }
}

function logError(method: string, error: any): void {
    LogHelper.logError(`GA4/${method}: ${error}`);
}
