import * as Actions from "./openLicenceActions";

export interface IOpenLicenceState {
    licenceId?: string;
    licenceNumber?: string;
    isResolved: boolean;
}

const initialState: IOpenLicenceState = {
    licenceId: undefined,
    licenceNumber: undefined,
    isResolved: false,
};

export function openLicenceReducer(state: IOpenLicenceState = initialState, action: Actions.IOpenLicenceActions): IOpenLicenceState {
    switch (action.type) {
        case Actions.openLicenceActions.GetLicenceIdRequest:
            return {
                licenceNumber: action.licenceNumber,
                licenceId: undefined,
                isResolved: false,
            };
        case Actions.openLicenceActions.GetLicenceIdResponse:
            return {
                ...state,
                licenceId: action.licenceId,
                isResolved: true,
            };
        default:
            return state;
    }
}
