// framework
import { clone } from "lodash";
// api
import * as Client from "../../../api/Client";

export interface IReferenceDataViewModel {
    honorifics: Client.GetCreatePublicFeedbackReferenceDataResponseHonorificDto[];
    feedbackTypes: Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackTypeDto[];
    dataFeedbackActivityTypes: Client.GetCreatePublicFeedbackReferenceDataResponseDataFeedbackActivityTypeDto[];
    dataFeedbackActivityNames: Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackActivityNameDto[];
    dataMonitoringItemTypes: Client.GetCreatePublicFeedbackReferenceDataResponseDataMonitoringItemTypeDto[];
}

export interface IFeedbackViewModel {
    honorific?: Client.GetCreatePublicFeedbackReferenceDataResponseHonorificDto | undefined;
    customerFirstName?: string | undefined;
    customerLastName?: string | undefined;
    customerCompanyName?: string | undefined;
    customerContactNumber?: string | undefined;
    customerEmailAddress?: string | undefined;
    feedbackType?: Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackTypeDto | undefined;
    dataFeedbackActivityType?: Client.GetCreatePublicFeedbackReferenceDataResponseDataFeedbackActivityTypeDto | undefined;
    dataFeedbackActivityName?: Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackActivityNameDto | undefined;
    dataMonitoringItemType?: Client.GetCreatePublicFeedbackReferenceDataResponseDataMonitoringItemTypeDto | undefined;
    customerComments?: string | undefined;
}

export interface IRootViewModel {
    statusMessages?: Client.StatusMessagesDto;
    isDirty: boolean;
    isSubmitted: boolean;
    referenceData: IReferenceDataViewModel;
    feedback: IFeedbackViewModel;

    refreshReferenceData(response: Client.GetCreatePublicFeedbackReferenceDataResponseDto): IRootViewModel;
    refreshFeedbackDetails(feedback: IFeedbackViewModel): IRootViewModel;
    refreshFeedbackCreateResponse(response: Client.CreatePublicFeedbackResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.isSubmitted = false;
        this.statusMessages = undefined;
        this.isDirty = false;
        this.feedback = {};
        this.referenceData = {
            honorifics: [],
            feedbackTypes: [],
            dataFeedbackActivityTypes: [],
            dataFeedbackActivityNames: [],
            dataMonitoringItemTypes: [],
        };
    }

    public isSubmitted: boolean;
    public statusMessages?: Client.StatusMessagesDto;
    public isDirty: boolean;
    public feedback: IFeedbackViewModel;
    public referenceData: IReferenceDataViewModel;

    refreshReferenceData(response: Client.GetCreatePublicFeedbackReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            honorifics: response.honorifics,
            feedbackTypes: response.feedbackTypes,
            dataFeedbackActivityTypes: response.dataFeedbackActivityTypes,
            dataFeedbackActivityNames: response.dataFeedbackActivityNames,
            dataMonitoringItemTypes: response.dataMonitoringItemTypes,
        };
        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshFeedbackDetails(feedback: IFeedbackViewModel): IRootViewModel {
        const vm = this._clone();
        vm.feedback.honorific = feedback.honorific;
        vm.feedback.customerFirstName = feedback.customerFirstName;
        vm.feedback.customerLastName = feedback.customerLastName;
        vm.feedback.customerCompanyName = feedback.customerCompanyName;
        vm.feedback.customerContactNumber = feedback.customerContactNumber;
        vm.feedback.customerEmailAddress = feedback.customerEmailAddress;
        vm.feedback.feedbackType = feedback.feedbackType;
        vm.feedback.dataFeedbackActivityType = feedback.dataFeedbackActivityType;
        vm.feedback.dataFeedbackActivityName = feedback.dataFeedbackActivityName;
        vm.feedback.dataMonitoringItemType = feedback.dataMonitoringItemType;
        vm.feedback.customerComments = feedback.customerComments;
        vm.isDirty = true;
        return vm;
    }

    refreshFeedbackCreateResponse(response: Client.CreatePublicFeedbackResponseDto) {
        const vm = this._clone();
        if (response.statusMessages?.isSuccess) {
            vm.isSubmitted = true;
            vm.isDirty = false;
        }
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.feedback = clone(this.feedback);
        return vm;
    }
}
