// framework
import React from "react";
import { useState, useEffect } from "react";
// kendo
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

// Note: expand as necessary e.g. at present there is no need to dynamically sort data
interface IProps<T> {
    id: string;
    value: T | undefined;
    data: T[] | undefined;
    keyField?: string | undefined;
    textField?: string | undefined;
    disabled?: boolean | undefined;
    valid?: boolean | undefined;
    className?: string | undefined;
    onChange: (value: T | undefined) => void;
    onBlur?: (() => void) | undefined;
}

export default function KendoDropDownList<T>(props: IProps<T>) {
    // value - must be set to null in order to programatically clear the dropdown
    const [value, setValue] = useState<T | undefined | null>();
    useEffect(() => {
        setValue(props.value ?? null);
    }, [props.value]);

    // data
    const [data, setData] = useState<T[]>([]);
    useEffect(() => {
        setData(props.data ? props.data : []);
    }, [props.data]);

    function onChange(event: DropDownListChangeEvent) {
        const value = event.target.value;
        setValue(value);
        props.onChange(value ?? undefined);
    }

    return (
        <DropDownList
            id={props.id}
            value={value}
            data={data}
            dataItemKey={props.keyField}
            textField={props.textField}
            disabled={props.disabled}
            valid={props.valid}
            className={props.className}
            onChange={onChange}
            onBlur={props.onBlur}
            popupSettings={{ height: 390 }}
        />
    );
}
