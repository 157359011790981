export enum openTitleActions {
    GetTitleIdRequest = "OPEN_TITLE.REQUEST",
    GetTitleIdResponse = "OPEN_TITLE.RESPONSE"
}

export interface IOpenTitleActions {
    type: openTitleActions,
    titleNumber?: string;
    titleId?: string;
}

export function getTitleIdRequest(titleNumber: string): IOpenTitleActions {
    return {
        type: openTitleActions.GetTitleIdRequest,
        titleNumber: titleNumber
    };
}

export function getTitleIdResponse(titleId?: string): IOpenTitleActions {
    return {
        type: openTitleActions.GetTitleIdResponse,
        titleId: titleId
    };
}