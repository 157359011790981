// framework
import { Link } from "react-router-dom";

export default function WellLinkView(props: { wellId: string | undefined; wellName: string | undefined }) {
    if (props.wellId) {
        return (
            <Link to={"/Nopims/Well/" + props.wellId} title="Click to view Well details.">
                {props.wellName}
            </Link>
        );
    }
    return <>{props.wellName}</>;
}
