// framework
import { useState } from "react";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, orderBy, State } from "@progress/kendo-data-query";
import { Chart, ChartSeries, ChartSeriesItem, ChartLegend } from "@progress/kendo-react-charts";
import "hammerjs";
// api
import * as Client from "../../../../api/Client";
// common
import ApplicationLink from "../../../../common/links/OeiApplicationLinkView";
import Help from "../../../../common/help/HelpView";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";

function OeiApplicationTrackingListView(props: {
    isCompleted: boolean;
    applicationTrackingType: Client.OeiApplicationTrackingTypeEnum | undefined;
    applicationTrackingTypes: Client.GetPublicOeiApplicationsTrackingTrackingTypeDto[];
    typeSummaryItems: Client.GetPublicOeiApplicationsTrackingTrackingTypeSummaryItemDto[];
    statusSummaryItems: Client.GetPublicOeiApplicationsTrackingTrackingStatusSummaryItemDto[];
    listItems: Client.GetPublicOeiApplicationsTrackingApplicationListItemDto[];
    onFormSubmit: (isCompleted: boolean, applicationTrackingType: Client.OeiApplicationTrackingTypeEnum | undefined) => void;
}) {
    // grid state
    const initialGridState = {
        sort: [
            { field: "implementedDate", dir: "desc" },
            { field: "lodgedDate", dir: "desc" },
        ],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    // constants
    const inProgressRadioValue = "inProgress";
    const completedRadioValue = "completed";
    const defaultApplicationTrackingType = new Client.GetPublicOeiApplicationsTrackingTrackingTypeDto({ name: "All OEI Applications" });

    // use state
    const [isCompleted, setIsCompleted] = useState(props.isCompleted);
    const [selectedRadioValue, setSelectedRadioValue] = useState(GlobalHelpers.convertBooleanToRadioValue(props.isCompleted, completedRadioValue, inProgressRadioValue));
    const [applicationTrackingType, setApplicationTrackingType] = useState(props.applicationTrackingType);
    const [selectedApplicationTrackingType, setSelectedApplicationTrackingType] = useState(props.applicationTrackingTypes.find((t) => t.id === props.applicationTrackingType));

    // sort application types by name ascending and insert 'All' option (no type selected)
    const sortedApplicationTrackingTypes = orderBy(props.applicationTrackingTypes, [{ field: "name", dir: "asc" }]);
    // sort application type summary items by name ascending
    const sortedTypeSummaryItems = orderBy(props.typeSummaryItems, [{ field: "applicationTrackingTypeName", dir: "asc" }]);
    // sort application status summary items by public status display order
    const sortedStatusSummaryItems = orderBy(props.statusSummaryItems, [{ field: "displayOrder", dir: "asc" }]);

    // local handlers
    function onRadioValueChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRadioValue(e.target.value);

        if (e.target.value === inProgressRadioValue) setIsCompleted(false);
        if (e.target.value === completedRadioValue) setIsCompleted(true);
    }

    function onApplicationTrackingTypeChanged(e: any) {
        setSelectedApplicationTrackingType(e.target.value);
        setApplicationTrackingType(e.target.value?.id);
    }

    function onFormSubmit(e: any) {
        e.preventDefault(); // stops posting
        props.onFormSubmit(isCompleted, applicationTrackingType);
        setGridState(initialGridState);
    }

    function onResetClicked() {
        // reset tracking type
        setSelectedApplicationTrackingType(defaultApplicationTrackingType);
        setApplicationTrackingType(undefined);
        // reset completed
        setSelectedRadioValue(inProgressRadioValue);
        setIsCompleted(false);

        props.onFormSubmit(false, undefined);
        setGridState(initialGridState);
    }

    function onSeriesClick(e: any) {
        let typeClicked = e.dataItem.applicationTrackingType;
        setSelectedApplicationTrackingType(props.applicationTrackingTypes.find((t) => t.id === typeClicked));
        setApplicationTrackingType(typeClicked);
        props.onFormSubmit(isCompleted, typeClicked);
        setGridState(initialGridState);
    }

    function labelContent(e: any): string {
        if (!e || !e.value || e.value === 0) return "";
        return `${e.category}: ${e.value}`;
    }

    function legendLabelContent(e: any): string {
        return `${e.text}: ${e.value}`;
    }

    function getTypeSummaryChartDescription(): string {
        const baseDescription =
            "A pie chart displaying counts of " + (props.isCompleted ? "Applications completed within the last 12 months" : "in progress Applications") + ", grouped by Application Type.";
        if (sortedTypeSummaryItems.length === 0) return baseDescription;

        const typeSummaryResults = sortedTypeSummaryItems.map((i) => `${i.applicationTrackingTypeName}: ${i.total}`).join("; ");
        return baseDescription + " The results are: " + typeSummaryResults + ".";
    }

    function getTypeSummaryChart(legendPosition: "bottom" | "right") {
        return (
            <Chart transitions={false} onSeriesClick={onSeriesClick} style={{ height: 600 }}>
                <ChartLegend position={legendPosition} orientation="vertical" labels={{ content: legendLabelContent }} />
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        data={sortedTypeSummaryItems}
                        categoryField="applicationTrackingTypeName"
                        field="total"
                        colorField="applicationTrackingTypeColour"
                        labels={{ visible: true, content: labelContent }}
                    />
                </ChartSeries>
            </Chart>
        );
    }

    function getStatusSummaryChartDescription(): string {
        const type = props.applicationTrackingTypes.find((t) => t.id === props.applicationTrackingType);
        const baseDescription =
            (props.isCompleted
                ? `A pie chart displaying counts of ${type?.name} Applications completed within the last 12 months`
                : `A bar chart displaying counts of in progress ${type?.name} Applications`) + ", grouped by Application Status.";
        if (sortedStatusSummaryItems.length === 0) return baseDescription;

        const statusSummaryResults = sortedStatusSummaryItems.map((i) => `${i.applicationStatusName}: ${i.total}`).join("; ");
        return baseDescription + " The results are: " + statusSummaryResults + ".";
    }

    function getInProgressStatusSummaryChart() {
        return (
            <Chart transitions={false} style={{ minWidth: 400 }}>
                {/* <ChartLegend position='right' orientation="vertical" /> - there is no legend for this chart */}
                <ChartSeries>
                    <ChartSeriesItem type="bar" data={sortedStatusSummaryItems} categoryField="applicationStatusName" field="total" labels={{ visible: true }} />
                </ChartSeries>
            </Chart>
        );
    }

    function getCompletedStatusSummaryChart(legendPosition: "bottom" | "right") {
        return (
            <Chart transitions={false} style={{ height: 600 }}>
                <ChartLegend position={legendPosition} orientation="vertical" labels={{ content: legendLabelContent }} />
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        data={sortedStatusSummaryItems}
                        categoryField="applicationStatusName"
                        field="total"
                        colorField="applicationStatusColour"
                        labels={{ visible: true, content: labelContent }}
                    />
                </ChartSeries>
            </Chart>
        );
    }

    // view
    return (
        <>
            {/* filters */}
            <form onSubmit={onFormSubmit} className="row row-cols-sm-auto g-3 mb-4 align-items-center">
                <div className="col-12">
                    <label htmlFor="applicationTrackingType" className="col-form-label">
                        Application Type
                    </label>
                </div>
                <div className="col-12">
                    <DropDownList
                        title="Select Application Type (default selection: All)."
                        style={{ width: "20rem" }}
                        id="applicationTrackingType"
                        data={sortedApplicationTrackingTypes}
                        value={selectedApplicationTrackingType}
                        dataItemKey="id"
                        defaultItem={defaultApplicationTrackingType}
                        onChange={onApplicationTrackingTypeChanged}
                        textField="name"
                    />
                </div>
                <div className="col-12">
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="inProgressRadio"
                            name="isCompletedGroup"
                            value={inProgressRadioValue}
                            checked={selectedRadioValue === inProgressRadioValue}
                            onChange={onRadioValueChanged}
                        />
                        <label className="form-check-label" htmlFor="inProgressRadio">
                            In Progress
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="completedRadio"
                            name="isCompletedGroup"
                            value={completedRadioValue}
                            checked={selectedRadioValue === completedRadioValue}
                            onChange={onRadioValueChanged}
                        />
                        <label className="form-check-label" htmlFor="completedRadio">
                            Completed
                        </label>
                    </div>
                </div>
                <div className="col-12">
                    <button id="showButton" className="btn btn-outline-primary me-1" type="submit">
                        Show
                    </button>
                    <button id="resetButton" className="btn btn-outline-secondary" type="button" onClick={onResetClicked}>
                        Reset
                    </button>
                </div>
            </form>

            <h2 id="chartTitle">{props.isCompleted ? "Completed Applications (Last 12 Months)" : "Applications in Progress"}</h2>

            {/* note on responsiveness - pie charts render better when moving the legend, where-as the bar charts render better by setting a minwidth + x-scroll */}

            {/* type summary chart */}
            {!props.applicationTrackingType && (
                <>
                    <Help id="typeSummaryChartDescription" title="What does this chart show?" className="mb-2">
                        {getTypeSummaryChartDescription()}
                    </Help>
                    <div className="d-block d-sm-none" role="img" aria-labelledby="chartTitle" aria-describedby="typeSummaryChartDescription">
                        {getTypeSummaryChart("bottom")}
                    </div>
                    <div className="d-none d-sm-block" role="img" aria-labelledby="chartTitle" aria-describedby="typeSummaryChartDescription">
                        {getTypeSummaryChart("right")}
                    </div>
                </>
            )}

            {/* in progress status summary chart */}
            {props.applicationTrackingType && !props.isCompleted && (
                <>
                    <Help id="inProgressStatusSummaryChartDescription" title="What does this chart show?" className="mb-2">
                        {getStatusSummaryChartDescription()}
                    </Help>
                    <div style={{ overflowX: "auto" }} role="img" aria-labelledby="chartTitle" aria-describedby="inProgressStatusSummaryChartDescription">
                        {getInProgressStatusSummaryChart()}
                    </div>
                    <p className="text-center">Not all Application Statuses are relevant to all Applications.</p>
                </>
            )}

            {/* completed status summary chart */}
            {props.applicationTrackingType && props.isCompleted && (
                <>
                    <Help id="completedStatusSummaryChartDescription" title="What does this chart show?" className="mb-2">
                        {getStatusSummaryChartDescription()}
                    </Help>
                    <div className="d-block d-sm-none" role="img" aria-labelledby="chartTitle" aria-describedby="completedStatusSummaryChartDescription">
                        {getCompletedStatusSummaryChart("bottom")}
                    </div>
                    <div className="d-none d-sm-block" role="img" aria-labelledby="chartTitle" aria-describedby="completedStatusSummaryChartDescription">
                        {getCompletedStatusSummaryChart("right")}
                    </div>
                </>
            )}

            {props.applicationTrackingType === Client.OeiApplicationTrackingTypeEnum.InitialApplicationFeasibilityLicence && (
                <p className="text-center">
                    {props.isCompleted ? <></> : "Details of individual Feasibility Licence Applications are not visible in the Public Portal. "} The number of Feasibility Licence Applications at
                    different application statuses can be viewed in the above chart.
                </p>
            )}

            {/* results grid */}
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.listItems, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="licenceNumber"
                    title="Licence / Name"
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.licenceNumber && props.dataItem.applicationId && (
                                <ApplicationLink applicationId={props.dataItem.applicationId} applicationName={props.dataItem.licenceNumber} />
                            )}
                        </CustomGridCell>
                    )}
                    width={150}
                />
                <GridColumn field="applicationTypeName" title="Application Type" width={260} />
                <GridColumn field="licenceType" title="Licence Type" width={220} />
                <GridColumn field="applicantCompany" title="Applicant" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.listItems)} />
                <GridColumn field="lodgedDate" title="Lodged Date" format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" width={200} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="implementedDate" title="Implemented Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                <GridColumn field="applicationStatusName" title="Application Status" width={240} />
            </Grid>
        </>
    );
}

export default OeiApplicationTrackingListView;
