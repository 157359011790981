import * as Client from "../../../../api/Client";

export enum applicationTrackingDetailsActions {
    GetApplicationTrackingDetailsRequest = "OPGGS_APPLICATION_TRACKING_DETAILS.REQUEST",
    GetApplicationTrackingDetailsResponse = "OPGGS_APPLICATION_TRACKING_DETAILS.RESPONSE",
    ClearApplicationTrackingDetails = "OPGGS_APPLICATION_TRACKING_DETAILS.CLEAR",
}

export interface IApplicationTrackingDetailsActions {
    type: applicationTrackingDetailsActions;
    applicationId?: string | undefined;
    response?: Client.GetPublicOpggsApplicationsTrackingDetailsResponseDto | undefined;
}

export function getOpggsApplicationTrackingDetailsRequestAction(applicationId: string): IApplicationTrackingDetailsActions {
    return {
        type: applicationTrackingDetailsActions.GetApplicationTrackingDetailsRequest,
        applicationId: applicationId,
    };
}

export function getOpggsApplicationTrackingDetailsResponseAction(response: Client.GetPublicOpggsApplicationsTrackingDetailsResponseDto): IApplicationTrackingDetailsActions {
    return {
        type: applicationTrackingDetailsActions.GetApplicationTrackingDetailsResponse,
        response: response,
    };
}

export function clearOpggsApplicationTrackingDetailsAction(): IApplicationTrackingDetailsActions {
    return {
        type: applicationTrackingDetailsActions.ClearApplicationTrackingDetails,
    };
}
