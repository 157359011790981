// framework
import { takeEvery } from "redux-saga/effects";
// redux
import { actions } from "./actions";
import initialise from "./sagas/initialise";
import submit from "./sagas/submit";

export default function* watcherSaga() {
    yield takeEvery(actions.initialiseRequested, initialise);
    yield takeEvery(actions.submitRequested, submit);
}
