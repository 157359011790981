// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// shell
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { getLicenceDetailsRequestAction, clearLicenceDetailsAction } from "../redux/licenceDetailsActions";
// helpers
import * as LicenceDetailsHelper from "../licenceDetailsHelper";
// children views
import LicenceDetailsView from "./views/LicenceDetailsView";
import { ILicenceDetailsState } from "../redux/licenceDetailsReducers";

interface IRouteParameters {
    licenceId: string;
}

function LicenceDetailsController(props: any) {
    const { licenceId } = useParams<IRouteParameters>();

    const { setLayout, getLicenceDetailsRequestAction, clearLicenceDetailsAction } = props;
    const state: ILicenceDetailsState = props.state;

    // Licence Details
    const response = state.response;

    // - initial licence load
    useEffect(() => {
        getLicenceDetailsRequestAction(licenceId);

        return function onUnmount() {
            clearLicenceDetailsAction();
        };
    }, [getLicenceDetailsRequestAction, clearLicenceDetailsAction, licenceId]);

    // - set page header when response received
    useEffect(() => {
        if (!response) {
            setLayout("");
            return;
        }

        setLayout(response.summary.licenceTypeDescription + " " + response.summary.licenceNumber);
    }, [setLayout, response]);

    // automation requests
    const [hasAutomationRun, setHasAutomationRun] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tab = LicenceDetailsHelper.tryGetPreferredTab(params);
    useEffect(() => {
        if (!response) return; // only allow automation to run after the page has initialised
        if (hasAutomationRun) return; // only run automation once

        LicenceDetailsHelper.tryOpenLicenceMemorialDocument(state, params);

        setHasAutomationRun(true);
    }, [response, hasAutomationRun]); // eslint-disable-line react-hooks/exhaustive-deps

    // return
    return <LicenceDetailsView response={response} preferredTab={tab} />;
}

// connect redux
export default connect((state: any) => ({ state: state.licenceDetails }), {
    setLayout,
    getLicenceDetailsRequestAction,
    clearLicenceDetailsAction,
})(LicenceDetailsController);
