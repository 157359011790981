// framework
import { ReactNode } from "react";
// kendo
import { IntlProvider, load } from "@progress/kendo-react-intl";
// CLDR data
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import auNumbers from "cldr-numbers-full/main/en-AU/numbers.json";
import auLocalCurrency from "cldr-numbers-full/main/en-AU/currencies.json";
import auCaGregorian from "cldr-dates-full/main/en-AU/ca-gregorian.json";
import auDateFields from "cldr-dates-full/main/en-AU/dateFields.json";
// other
import * as GlobalHelpers from "../common/GlobalHelpers";

load(likelySubtags, currencyData, weekData, auNumbers, auLocalCurrency, auCaGregorian, auDateFields);

function KendoIntlProvider(props: { children: ReactNode }) {
    return <IntlProvider locale={GlobalHelpers.NoptaLocale}>{props.children}</IntlProvider>;
}

export default KendoIntlProvider;
