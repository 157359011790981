import {
    PublicOpggsApplicationsServiceClient,
    PublicOpggsTitlesServiceClient,
    PublicWellServiceClient,
    PublicFeedbackServiceClient,
    PublicShellServiceClient,
    PublicOeiLicencesSearchServiceClient,
    PublicOeiLicenceDetailsServiceClient,
    PublicOeiLicenceOpenServiceClient,
    PublicOeiApplicationsServiceClient,
} from "./Client";
import { getServicesUri } from "./uriHelper";

export function createOpggsApplicationsClient(): PublicOpggsApplicationsServiceClient {
    const uri: string = getServicesUri();
    return new PublicOpggsApplicationsServiceClient(uri);
}

export function createOpggsTitlesClient(): PublicOpggsTitlesServiceClient {
    const uri: string = getServicesUri();
    return new PublicOpggsTitlesServiceClient(uri);
}

export function createOeiApplicationsClient(): PublicOeiApplicationsServiceClient {
    const uri: string = getServicesUri();
    return new PublicOeiApplicationsServiceClient(uri);
}

export function createOeiLicencesSearchClient(): PublicOeiLicencesSearchServiceClient {
    const uri: string = getServicesUri();
    return new PublicOeiLicencesSearchServiceClient(uri);
}

export function createOeiLicenceDetailsClient(): PublicOeiLicenceDetailsServiceClient {
    const uri: string = getServicesUri();
    return new PublicOeiLicenceDetailsServiceClient(uri);
}

export function createOeiLicenceOpenClient(): PublicOeiLicenceOpenServiceClient {
    const uri: string = getServicesUri();
    return new PublicOeiLicenceOpenServiceClient(uri);
}

export function createWellClient(): PublicWellServiceClient {
    const uri: string = getServicesUri();
    return new PublicWellServiceClient(uri);
}

export function createFeedbackClient(): PublicFeedbackServiceClient {
    const uri: string = getServicesUri();
    return new PublicFeedbackServiceClient(uri);
}

export function createShellClient(): PublicShellServiceClient {
    const uri: string = getServicesUri();
    return new PublicShellServiceClient(uri);
}
