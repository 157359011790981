// framework
import { useState, ReactElement, ReactNode } from "react";
import { Client } from "./client";
import { DownloadIcon } from "@primer/octicons-react";

interface IProps {
    className?: string | undefined;
    disabled?: boolean | undefined;
    title?: string | undefined;
    children?: ReactNode | undefined;
    fileId: number;
}

export function LinkControl(props: IProps): ReactElement {
    // we won't trigger a global busy state, however we'll just show the link as acknowledging the download by disabling it
    const [isBusy, setIsBusy] = useState(false);

    async function onClick(): Promise<void> {
        setIsBusy(true);

        // generate the token and get the URL
        const client = new Client();
        client.initialise(props.fileId);
        const url = client.getUrl();

        // initiate the download
        // - this opens a new browser window, but given it's a file response it will automatically close
        // - if anything goes wrong, the window is left open
        window.open(url);

        setIsBusy(false);
    }

    return (
        <span className={props.className}>
            <button
                title={props.title}
                className={"btn btn-link p-0"}
                disabled={isBusy || props.disabled === true}
                onClick={async (e) => {
                    await onClick();
                }}
            >
                {props.children ?? "Download"}
            </button>
            <DownloadIcon className="ms-1" size={12} verticalAlign="text-top" />
        </span>
    );
}
