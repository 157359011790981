// common
import ExternalLinkView from "../../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function TitleSummaryPriorUsageRightField(props: { label?: string; hasPriorUsageRight: boolean | undefined }) {
    return (
        <>
            {props.hasPriorUsageRight && (
                <>
                    <Forms.Label htmlFor="priorUsageRightsField">Prior Usage Right Title</Forms.Label>
                    <div id="priorUsageRightsField">
                        <ExternalLinkView href="https://parksaustralia.gov.au/marine/activities/approvals/mining/">Prior Usage Right Title</ExternalLinkView>
                    </div>
                </>
            )}
        </>
    );
}

export default TitleSummaryPriorUsageRightField;
