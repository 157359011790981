import { actions, IAction } from "./actions";
import * as Models from "../models/models";

export interface IFeedbackState {
    feedback: Models.IRootViewModel;
}

// reducers
export function feedbackReducer(state: Models.IRootViewModel = new Models.RootViewModel(), action: IAction<any>): Models.IRootViewModel {
    switch (action.type) {
        // lifecycle events
        case actions.initialiseRequested:
            return new Models.RootViewModel();
        case actions.submitRequested:
            return state;
        // user input
        case actions.viewChanged:
            return action.payload;
        // saga
        case actions.sagaCompleted:
            return action.payload;
        // default
        default:
            return state;
    }
}
