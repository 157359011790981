import { useState, useEffect, useRef } from "react";
import { AlertsModel } from "./AlertsModel";
import { StatusMessagesDto } from "../../api/Client";
import AlertsView from "./AlertsView";

interface IProps {
    statusMessages: StatusMessagesDto | undefined;
    scrollWindowToTop?: boolean | undefined;
}

export default function StatusMessagesAlertsView(props: IProps) {
    const scrollWindowToTop = props.scrollWindowToTop ?? true;

    // alerts
    // - note, need to be careful with how dismissal works as any changes to the View Model can cause it to become visible again
    const [alerts, setAlerts] = useState(new AlertsModel(props.statusMessages));

    const alertsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const m = new AlertsModel(props.statusMessages);
        setAlerts(m);

        // If the alert appears under the floating top nav then it focuses but the screen doesn't know it needs to scroll to the top and the alert stays hidden.
        // The scroll to top below has been added as a temporary workaround until we can look at possibly integrating conflict and status alert views with the body layout.
        if (scrollWindowToTop && m.hasAnything()) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            alertsRef.current?.focus();
        }
    }, [props.statusMessages, scrollWindowToTop]);

    return <AlertsView alertsModel={alerts} alertsRef={alertsRef} />;
}
