// framework
import { Link } from "react-router-dom";

export default function LicenceLinkView(props: { licenceId: string | undefined; licenceNumber: string | undefined }) {
    if (props.licenceId) {
        return (
            <Link to={"/Oei/Licence/" + props.licenceId} title="Click to view Licence details.">
                {props.licenceNumber}
            </Link>
        );
    }
    return <>{props.licenceNumber}</>;
}
