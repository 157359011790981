// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// api
import * as Client from "../../../../../api/Client";
// children views
import BlocksView from "./BlocksView";
import LocationsView from "./LocationsView";
// other
import TitleDetailsConfiguration from "../TitleDetailsConfiguration";

function BlocksAndLocationsView(props: {
    configuration: TitleDetailsConfiguration;
    response: Client.GetPublicTitleDetailsResponseDto | undefined;
    mapResponse: Client.GetPublicTitleDetailsMapResponseDto | undefined;
}) {
    const configuration = props.configuration;
    const response = props.response;
    const blocks = response?.blocksAndLocations?.blocks ?? new Array<Client.GetPublicTitleDetailsBlockDto>();
    const locations = response?.blocksAndLocations?.locations ?? new Array<Client.GetPublicTitleDetailsLocationDto>();
    const areaSqKm = props.mapResponse?.areaSqKm;

    return (
        <>
            <div className="mt-2">
                <Tabs defaultActiveKey="blocks">
                    {/* Blocks Tab */}
                    <Tab eventKey="blocks" title="Title Blocks">
                        <BlocksView blocks={blocks} areaSqKm={areaSqKm} showLocations={configuration.IsLocationsDataVisible} />
                    </Tab>

                    {/* Locations Tab */}
                    {configuration.IsLocationsDataVisible && (
                        <Tab eventKey="locations" title="Locations">
                            <LocationsView locations={locations} />
                        </Tab>
                    )}
                </Tabs>
            </div>
        </>
    );
}

export default BlocksAndLocationsView;
