export enum openLicenceActions {
    GetLicenceIdRequest = "OPEN_LICENCE.REQUEST",
    GetLicenceIdResponse = "OPEN_LICENCE.RESPONSE",
}

export interface IOpenLicenceActions {
    type: openLicenceActions;
    licenceNumber?: string;
    licenceId?: string;
}

export function getLicenceIdRequest(licenceNumber: string): IOpenLicenceActions {
    return {
        type: openLicenceActions.GetLicenceIdRequest,
        licenceNumber: licenceNumber,
    };
}

export function getLicenceIdResponse(licenceId?: string): IOpenLicenceActions {
    return {
        type: openLicenceActions.GetLicenceIdResponse,
        licenceId: licenceId,
    };
}
