// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// api
import * as Client from "../../../../api/Client";
// children views
import TitleSummaryView from "./titleSummary/TitleSummaryView";
import TitleholdersView from "./TitleholdersView";
import WorkProgramView from "./workProgram/WorkProgramView";
import MemorialView from "./MemorialView";
import BlocksAndLocationsView from "./blocksAndLocations/BlocksAndLocationsView";
// other
import TitleDetailsConfiguration from "./TitleDetailsConfiguration";

export enum TitleDetailsTabsEnum {
    summary,
    titleHolders,
    workProgram,
    memorial,
    blocksAndLocations,
}

function TitleDetailsView(props: {
    configuration: TitleDetailsConfiguration;
    response: Client.GetPublicTitleDetailsResponseDto | undefined;
    mapResponse: Client.GetPublicTitleDetailsMapResponseDto | undefined;
    preferredTab?: TitleDetailsTabsEnum;
}) {
    const configuration = props.configuration;
    const response = props.response;
    const mapResponse = props.mapResponse;
    const preferredTab = props.preferredTab ?? TitleDetailsTabsEnum.summary;

    return (
        <Tabs defaultActiveKey={preferredTab.toString()} variant="pills" mountOnEnter>
            {/* Summary Tab */}
            <Tab eventKey={TitleDetailsTabsEnum.summary.toString()} title="Title Summary">
                <TitleSummaryView response={response} mapResponse={mapResponse} />
            </Tab>

            {/* Titleholders Tab */}
            <Tab eventKey={TitleDetailsTabsEnum.titleHolders.toString()} title="Titleholders">
                <TitleholdersView response={response} />
            </Tab>

            {/* Work Program Tab */}
            {configuration.IsWorkProgramVisible && (
                <Tab eventKey={TitleDetailsTabsEnum.workProgram.toString()} title="Work Program">
                    <WorkProgramView response={response} />
                </Tab>
            )}

            {/* Memorial Tab */}
            <Tab eventKey={TitleDetailsTabsEnum.memorial.toString()} title="Memorial">
                <MemorialView response={response} />
            </Tab>

            {/* Blocks & Locations Tab */}
            {configuration.IsBlocksAndLocationsTabVisible && (
                <Tab eventKey={TitleDetailsTabsEnum.blocksAndLocations.toString()} title={configuration.IsLocationsDataVisible ? "Blocks & Locations" : "Blocks"}>
                    <BlocksAndLocationsView configuration={configuration} response={response} mapResponse={mapResponse} />
                </Tab>
            )}
        </Tabs>
    );
}

export default TitleDetailsView;
