// api
import * as Client from "../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import TitleSummaryRelatedTitlesField from "./TitleSummaryRelatedTitlesField";
import TitleSummaryPriorUsageRightField from "./TitleSummaryPriorUsageRightField";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function TitleSummaryEpView(props: { summary: Client.GetPublicTitleDetailsSummaryDto | undefined; areaSqKm: number | undefined }) {
    const summary = props.summary;
    const areaSqKm = props.areaSqKm;
    const locations = summary?.locationNames ?? new Array<string>();
    const sortedLocations = [...locations].sort();

    return (
        <Forms.Row>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Status" id="statusField" value={summary?.titleStatus} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Offshore Area" id="offshoreAreaField" value={summary?.offshoreAreaName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Basin" id="basinField" value={summary?.basinName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Number of Renewals" id="numberOfRenewalsField" value={GlobalHelpers.toNoptaIntegerString(summary?.numberOfRenewals)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Initial Grant Date" id="initialGrantDateField" value={GlobalHelpers.toNoptaDateString(summary?.initialGrantDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Original Expiry Date" id="originalExpiryDateField" value={GlobalHelpers.toNoptaDateString(summary?.originalExpiryDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Current Expiry Date" id="currentExpiryDateField" value={GlobalHelpers.toNoptaDateString(summary?.currentExpiryDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Number of Blocks" id="numberOfBlocksField" value={GlobalHelpers.toNoptaIntegerString(summary?.numberOfBlocks)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Last Renewal Date" id="lastRenewalDateField" value={GlobalHelpers.toNoptaDateString(summary?.lastRenewalDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <TitleSummaryRelatedTitlesField label="Related Titles" relatedTitles={summary?.relatedTitles} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Locations" id="LocationsField" value={sortedLocations.join(", ")} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <TitleSummaryPriorUsageRightField hasPriorUsageRight={summary?.hasPriorUsageRight} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Area" id="areaField" value={GlobalHelpers.toNoptaNumberString(areaSqKm, "n2", "km²")} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Designated Frontier Area" id="designatedFrontierAreaField" value={summary?.isDesignatedFrontierArea} />
            </Forms.QuarterColumn>
        </Forms.Row>
    );
}

export default TitleSummaryEpView;
