export enum ValidationVisibilityEnum {
    None = "Validation.None",
    Messages = "Validation.Messages",
    MessagesAndAdorners = "Validation.MessageAndAdorners",
}

export interface IValidationModel {
    visibility: ValidationVisibilityEnum;
    errors: Array<[string, string]>;

    isValid(name?: string): boolean;
    getErrors(name: string): Array<string>;
    addError(name: string, message: string): void;
    clear(): void;

    applyClass(classNames: string, name: string): string;
    applyClassForKendo(classNames: string, name: string): string;
    applyValidity(name: string): boolean;
    applyValidityForKendo(name: string): boolean;
}

export class ValidationModel implements IValidationModel {
    constructor(visibility: ValidationVisibilityEnum) {
        this.visibility = visibility;
        this.errors = new Array<[string, string]>();
    }

    visibility: ValidationVisibilityEnum;
    errors: Array<[string, string]>;

    isValid(name?: string): boolean {
        return name ? this.errors.filter((i) => i[0] === name).length === 0 : this.errors.length === 0;
    }

    getErrors(name: string): Array<string> {
        const errors = this.errors.filter((i) => i[0] === name).map((i) => i[1]);
        return errors;
    }

    addError(name: string, message: string): void {
        this.errors.push([name, message]);
    }

    clear(): void {
        this.errors = new Array<[string, string]>();
    }

    // TODO DO NOT USE WITHOUT THOROUGH TESTING. Not currently used, have shifted responsibility for setting bootstrap styles to BootstrapForms. Consider removing.
    applyClass(classNames: string, name: string): string {
        // only apply the class if adorners are visible
        if (this.visibility !== ValidationVisibilityEnum.MessagesAndAdorners) return classNames;

        const isValid = this.isValid(name);
        return isValid ? classNames : classNames + " is-invalid";
    }

    // TODO DO NOT USE WITHOUT THOROUGH TESTING. Not currently used, correct error styling is more reliably achieved by setting 'valid' on Kendo controls. Consider removing.
    applyClassForKendo(classNames: string, name: string): string {
        // only apply the class if adorners are visible
        if (this.visibility !== ValidationVisibilityEnum.MessagesAndAdorners) return classNames;

        const isValid = this.isValid(name);
        return isValid ? classNames : classNames + " k-invalid";
    }

    applyValidity(name: string): boolean {
        // only apply the class if adorners are visible
        if (this.visibility !== ValidationVisibilityEnum.MessagesAndAdorners) return true;

        return this.isValid(name);
    }

    applyValidityForKendo(name: string): boolean {
        // only apply the class if adorners are visible
        if (this.visibility !== ValidationVisibilityEnum.MessagesAndAdorners) return true;

        return this.isValid(name);
    }
}

export function isValidEmail(email: string) {
    const re = /^[A-Za-z0-9-._]+@[A-Za-z0-9-.]+$/;
    return re.test(email);
}

// any combination of letters, spaces, hyphens and apostrophes provided it contains at least one letter
export function isValidName(name: string) {
    const re = /^[a-zA-Z \-']*[a-zA-Z][a-zA-Z \-']*$/;
    return re.test(name);
}

export function isValidAbn(abn: string | undefined): boolean {
    if (!abn || !abn.trim()) return true;
    const re = /^[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{3}$/;
    return re.test(abn);
}

export function isValidAcnOrArbn(acnOrArbn: string | undefined): boolean {
    if (!acnOrArbn || !acnOrArbn.trim()) return true;
    const re = /^[0-9]{3} [0-9]{3} [0-9]{3}$/;
    return re.test(acnOrArbn);
}

export function isNullOrWhiteSpace(value: string | undefined): boolean {
    return !(value && value.trim());
}
