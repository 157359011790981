// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { IShellActions, setLayout } from "../../../shell/redux/shellActions";
// common
import StatusMessagesAlertsView from "../../../common/alerts/StatusMessagesAlertsView";
// redux
import * as Actions from "../redux/actions";
import { IFeedbackState } from "../redux/reducers";
// models
import * as Models from "../models/models";
// views
import FeedbackCreateView from "./views/FeedbackCreateView";
import FeedbackCreateSubmittedView from "./views/FeedbackCreateSubmittedView";

interface IControllerProps extends Actions.IActionFactory {
    setLayout: (pageTitle: string, showPageHeader?: boolean, includePadding?: boolean) => IShellActions;
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps) {
    const message = "There are unsaved changes. Are you sure you wish to continue?";
    const setLayout: (pageTitle: string, showPageHeader?: boolean, includePadding?: boolean) => IShellActions = props.setLayout;
    const vm: Models.IRootViewModel = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // initial load
    useEffect(() => {
        setLayout("Feedback Form | NOPIMS", false);
        actions.initialiseRequested();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h1>Feedback Form</h1>
            <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
            {vm.isSubmitted && <FeedbackCreateSubmittedView vm={vm} actions={actions} />}
            {!vm.isSubmitted && <FeedbackCreateView vm={vm} actions={actions} />}
            <Prompt when={vm.isDirty} message={message} />
        </>
    );
}

// connect redux
export default connect((state: IFeedbackState) => ({ rootViewModel: state.feedback }), { setLayout, ...Actions.actionFactory })(Controller);
