// kendo
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../api/Client";
// common
import TitleLink from "../../../../../common/links/TitleLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function TitleSummaryRelatedTitlesField(props: { label?: string; relatedTitles: Client.GetPublicTitleDetailsRelatedTitleDto[] | undefined }) {
    const relatedTitles = props.relatedTitles ?? new Array<Client.GetPublicTitleDetailsRelatedTitleDto>();
    const sortedTitles = orderBy(relatedTitles, [{ field: "titleNumber", dir: "asc" }]);

    return (
        <>
            <Forms.Label htmlFor="relatedTitlesField">Related Titles</Forms.Label>
            {sortedTitles.length > 0 && <div id="relatedTitlesField">{sortedTitles.map((t, i) => [i > 0 && ", ", <TitleLink key={t.titleId} titleId={t.titleId} titleNumber={t.titleNumber} />])}</div>}
        </>
    );
}

export default TitleSummaryRelatedTitlesField;
