// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { ITitleSearchActions, searchTitleActions, searchTitleResponseAction } from "./titleSearchActions";
// api
import { createOpggsTitlesClient } from "../../../../api/util";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(searchTitleActions.SearchTitlesRequest, workerSaga);
}

function* workerSaga(action: ITitleSearchActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicTitlesListItemDto[] = yield call(query, action.filter!);
        // post response
        yield put(searchTitleResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(filter: string): Promise<Client.GetPublicTitlesListItemDto[]> {
    const client = createOpggsTitlesClient();

    const request = new Client.GetPublicTitlesListRequestDto({ filterFullTextSearch: filter });

    const response = await simpleCall(() => client.getPublicTitlesList(request));
    return response.titles;
}
