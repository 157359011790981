// kendo
import { orderBy } from "@progress/kendo-data-query";
import { Chart, ChartSeries, ChartSeriesItem, ChartLegend } from "@progress/kendo-react-charts";
import "hammerjs";
// api
import * as Client from "../../../../api/Client";
// common
import TitleLink from "../../../../common/links/TitleLinkView";
import Help from "../../../../common/help/HelpView";
import * as Forms from "../../../../common/forms/BootstrapForms";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";

function OpggsApplicationTrackingDetailsView(props: { response: Client.GetPublicOpggsApplicationsTrackingDetailsResponseDto | undefined }) {
    const response = props.response;
    const approvalTrackingItems = response?.approvalTracking ?? new Array<Client.GetPublicApplicationsTrackingDetailsApprovalTrackingDto>();
    // sort approval tracking items by public status display order
    const sortedApprovalTrackingItems = orderBy(approvalTrackingItems, [{ field: "displayOrder", dir: "asc" }]);

    function getStatusChartDescription(): string {
        const baseDescription = "A bar chart displaying counts of Weekdays at each Status for the Application, grouped by Application Status.";
        if (sortedApprovalTrackingItems.length === 0) return baseDescription;
        const statusResults = sortedApprovalTrackingItems.map((i) => `${i.applicationStatusName}: ${i.weekdaysAtStatus}`).join("; ");
        return baseDescription + " The results are: " + statusResults + ".";
    }

    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            {response?.titleId ? (
                                <>
                                    <Forms.Label htmlFor="titleLinkField">Title Number</Forms.Label>
                                    <div id="titleLinkField">
                                        <TitleLink titleId={response?.titleId} titleNumber={response?.titleNumber} />
                                    </div>
                                </>
                            ) : (
                                <Forms.ReadonlyField label="Title Number" id="titleNumberField" value={response?.titleNumber} />
                            )}
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Application Type" id="applicationTypeField" value={response?.applicationTypeName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Title Type" id="titleTypeField" value={response?.titleType} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Applicant" id="applicantField" value={response?.applicantCompany} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Lodged Date" id="lodgedDateField" value={GlobalHelpers.toNoptaDateString(response?.lodgedDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Implemented Date" id="implementedDateField" value={GlobalHelpers.toNoptaDateString(response?.implementedDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Application Status" id="applicationStatusField" value={response?.applicationStatusName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Offshore Area" id="offshoreAreaField" value={response?.offshoreAreaName} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 id="statusChartTitle" className="card-title">
                        Application Progress
                    </h2>
                    {/* approval tracking chart */}
                    <Help id="statusChartDescription" title="What does this chart show?" className="mb-2">
                        {getStatusChartDescription()}
                    </Help>
                    <div style={{ overflowX: "auto" }} role="img" aria-labelledby="statusChartTitle" aria-describedby="statusChartDescription">
                        <Chart transitions={false} style={{ minWidth: 400 }}>
                            <ChartLegend position="bottom" orientation="vertical" />
                            <ChartSeries>
                                <ChartSeriesItem
                                    name="Weekdays at each Status (may include public holidays)"
                                    type="bar"
                                    data={sortedApprovalTrackingItems}
                                    categoryField="applicationStatusName"
                                    field="weekdaysAtStatus"
                                    labels={{ visible: true }}
                                />
                            </ChartSeries>
                        </Chart>
                    </div>
                    <p className="text-center">Not all Application Statuses are relevant to all Applications.</p>
                </div>
            </div>
        </>
    );
}

export default OpggsApplicationTrackingDetailsView;
