// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { searchTitleRequestAction } from "../redux/titleSearchActions";
// children
import TitleSearchListView from "./TitleSearchListView";
import { ITitleSearchState } from "../redux/titleSearchReducers";
import * as Client from "../../../../api/Client";

function TitleSearchController(props: any) {
    const { setLayout, searchTitleRequestAction } = props;

    const state: ITitleSearchState = props.state;
    const filter = state.filter ?? "";
    const titles = state.titles ?? new Array<Client.GetPublicTitlesListItemDto>();

    // initial load
    useEffect(() => {
        setLayout("Title Search");
        searchTitleRequestAction(filter);
    }, [setLayout, searchTitleRequestAction, filter]);

    // change
    function searchTextChanged(value: string) {
        searchTitleRequestAction(value);
    }

    // return
    return <TitleSearchListView titles={titles} searchText={filter} onSearchTextChanged={searchTextChanged} />;
}

// connect redux
export default connect((state: any) => ({ state: state.titleSearch }), { setLayout, searchTitleRequestAction })(TitleSearchController);
