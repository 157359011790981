import React, { useEffect, useState } from "react";
import { QuestionIcon } from "@primer/octicons-react";
import { Collapse } from "react-bootstrap";
import { uniqueId } from "lodash";

import styles from "./HelpView.module.css";

function HelpView(props: { id?: string; className?: string; title: string; children: React.ReactNode }) {
    const [show, setShow] = useState(false);
    const [id] = useState(props.id ? props.id : uniqueId("helpView_"));

    useEffect(() => {
        setShow(false);
    }, [props.children]);

    return (
        <div className={props.className}>
            <button type="button" className="btn btn-info" onClick={() => setShow(!show)} aria-expanded={show} aria-controls={id}>
                <QuestionIcon className="me-2" size="small" />
                <span>{props.title}</span>
            </button>
            <Collapse in={show}>
                <div id={id} className={styles.helpView}>
                    {props.children}
                </div>
            </Collapse>
        </div>
    );
}

export default HelpView;
