import * as Client from "../../../../api/Client";

export enum searchTitleActions {
    // -- TitleSearch
    SearchTitlesRequest = "OPGGS_TITLE_SEARCH.SEARCH_TITLES_REQUEST",
    SearchTitlesResponse = "OPGGS_TITLE_SEARCH.SEARCH_TITLES_RESPONSE",
}

export interface ITitleSearchActions {
    type: searchTitleActions;
    filter?: string | undefined;
    titles?: Client.GetPublicTitlesListItemDto[] | undefined;
}

export function searchTitleRequestAction(filter: string): ITitleSearchActions {
    return {
        type: searchTitleActions.SearchTitlesRequest,
        filter: filter,
    };
}

export function searchTitleResponseAction(titles: Client.GetPublicTitlesListItemDto[]): ITitleSearchActions {
    return {
        type: searchTitleActions.SearchTitlesResponse,
        titles: titles,
    };
}
