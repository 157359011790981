import { getWellActions, IGetWellActions } from "./actions";
import * as Client from "../../../api/Client";

// initial state
export interface IGetWellState {
    filterName?: string | null;
    filterTitleId?: string | null;
    filterBasinId?: number | null;
    filterJurisdictionId?: number | null;
    filterTitleholderId?: number | null;
    filterFromSpudDate?: Date | null;
    filterToSpudDate?: Date | null;

    wells?: Client.GetPublicWellListItemDto[] | null;
    titles?: Client.PublicWellTitleDto[] | null;
    basins?: Client.PublicWellBasinDto[] | null;
    states?: Client.PublicWellStateTerritoryDto[] | null;
    titleholders?: Client.PublicWellTitleholderDto[] | null;

    exportResponse?: Client.GetPublicWellListExportResponseDto | undefined;
}

const initialState: IGetWellState = {
    filterName: "",
    filterTitleId: "",
    filterBasinId: null,
    filterJurisdictionId: null,
    filterTitleholderId: null,
    filterFromSpudDate: null,
    filterToSpudDate: null,
    wells: [],
    titles: [],
    basins: [],
    states: [],
    titleholders: [],
    exportResponse: undefined,
};

// reducers
export function wellReducer(state: IGetWellState = initialState, action: IGetWellActions): IGetWellState {
    switch (action.type) {
        case getWellActions.GetWellRequest:
            return {
                ...state,
                filterName: action.filterName,
                filterTitleId: action.filterTitleId,
                filterBasinId: action.filterBasinId,
                filterJurisdictionId: action.filterJurisdictionId,
                filterTitleholderId: action.filterTitleholderId,
                filterFromSpudDate: action.filterFromSpudDate,
                filterToSpudDate: action.filterToSpudDate,
                wells: [],
            };

        case getWellActions.GetWellResponse:
            return {
                ...state,
                wells: action.items,
            };

        case getWellActions.GetReferenceDataRequest:
            return {
                ...state,
            };

        case getWellActions.GetReferenceDataResponse:
            return {
                ...state,
                titles: action.titles,
                basins: action.basins,
                states: action.states,
                titleholders: action.titleholders,
            };

        case getWellActions.GetWellExportRequest:
            return {
                ...state,
                filterName: action.filterName,
                filterTitleId: action.filterTitleId,
                filterBasinId: action.filterBasinId,
                filterJurisdictionId: action.filterJurisdictionId,
                filterTitleholderId: action.filterTitleholderId,
                filterFromSpudDate: action.filterFromSpudDate,
                filterToSpudDate: action.filterToSpudDate,
                exportResponse: undefined,
            };

        case getWellActions.GetWellExportResponse:
            return {
                ...state,
                exportResponse: action.exportResponse,
            };

        case getWellActions.ClearWellExport:
            return {
                ...state,
                exportResponse: undefined,
            };

        default:
            return state;
    }
}
