import { applicationTrackingActions, IApplicationTrackingActions } from "./opggsApplicationTrackingActions";
import * as Client from "../../../../api/Client";

// initial state
export interface IApplicationTrackingState {
    isCompleted?: boolean | undefined;
    applicationTrackingType?: Client.OpggsApplicationTrackingTypeEnum | undefined;
    response?: Client.GetPublicOpggsApplicationsTrackingResponseDto | undefined;
}

const initialState: IApplicationTrackingState = {
    isCompleted: false,
    applicationTrackingType: undefined,
    response: undefined,
};

// reducers
export function opggsApplicationTrackingReducer(state: IApplicationTrackingState = initialState, action: IApplicationTrackingActions): IApplicationTrackingState {
    switch (action.type) {
        case applicationTrackingActions.GetApplicationTrackingRequest:
            return {
                ...state,
                isCompleted: action.isCompleted,
                applicationTrackingType: action.applicationTrackingType,
                response: undefined,
            };

        case applicationTrackingActions.GetApplicationTrackingResponse:
            return {
                ...state,
                response: action.response,
            };

        default:
            return state;
    }
}
