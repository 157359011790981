// kendo
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../api/Client";
// common
import LicenceLink from "../../../../../common/links/LicenceLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function LicenceSummaryRelatedLicencesField(props: { label: string; relatedLicences: Client.GetPublicLicenceDetailsRelatedLicenceDto[] | undefined }) {
    const relatedLicences = props.relatedLicences ?? new Array<Client.GetPublicLicenceDetailsRelatedLicenceDto>();
    const sortedLicences = orderBy(relatedLicences, [{ field: "licenceNumber", dir: "asc" }]);

    return (
        <>
            <Forms.Label htmlFor="relatedLicencesField">Related Licences</Forms.Label>
            {sortedLicences.length === 0 && <div>No Related Licences found.</div>}
            {sortedLicences.length !== 0 && (
                <div id="relatedLicencesField">
                    {sortedLicences.map((l: Client.GetPublicLicenceDetailsRelatedLicenceDto, i: number) => (
                        <>
                            <LicenceLink key={l.licenceId} licenceId={l.licenceId} licenceNumber={l.licenceNumber} />
                            <span>{i === sortedLicences.length - 1 ? <></> : ", "}</span>
                        </>
                    ))}
                </div>
            )}
        </>
    );
}

export default LicenceSummaryRelatedLicencesField;
