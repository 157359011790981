import { licenceDetailsActions, ILicenceDetailsActions } from "./licenceDetailsActions";
import * as Client from "../../../../api/Client";

// initial state
export interface ILicenceDetailsState {
    // details
    response?: Client.GetPublicLicenceDetailsResponseDto | undefined;
}

const initialState: ILicenceDetailsState = {
    // details
    response: undefined,
};

// reducers
export function licenceDetailsReducer(state: ILicenceDetailsState = initialState, action: ILicenceDetailsActions): ILicenceDetailsState {
    switch (action.type) {
        // Licence Details
        case licenceDetailsActions.GetLicenceDetailsRequest:
            return {
                ...state,
                response: undefined,
            };

        case licenceDetailsActions.GetLicenceDetailsResponse:
            return {
                ...state,
                response: action.detailsResponse,
            };

        case licenceDetailsActions.ClearLicenceDetails:
            return {
                ...state,
                response: undefined,
            };

        // Default
        default:
            return state;
    }
}
