import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { simpleCall } from "../../api/callHelper";
import { ApiException } from "../../api/Client";
import { setLayout } from "../../shell/redux/shellActions";

function TestErrorPage(props: any) {
    const { setLayout } = props;

    const [showError, setShowError] = useState(false);

    // initial load
    useEffect(() => {
        setLayout("Test Error Page");
    }, [setLayout]);

    function someEventHandler() {
        throw new Error("something bad happened in an event handler");
    }

    function triggerReactComponentError() {
        setShowError(true);
    }

    if (showError) {
        throw new Error("something bad happened in a react component");
    }

    async function makeTheCall(ex: ApiException) {
        const p = new Promise(function (resolve, reject) {
            reject(ex);
        });
        try {
            await simpleCall(() => p);
        } catch (ex: any) {
            console.error(ex.message);
        }
    }

    return (
        <div>
            <div className="card mb-2">
                <div className="card-body">
                    <p className="mb-2">Errors in an Event Handler will only log to the console. You will need to handle these in your event handler as required.</p>
                    <button className="btn btn-outline-primary" onClick={someEventHandler}>
                        Trigger Event Handler Error
                    </button>
                </div>
            </div>

            <div className="card mb-2">
                <div className="card-body">
                    <p className="mb-2">An invalid link will navigate you to the 'Not Found' page. Once triggered, you can click Back to recover the page.</p>
                    <p>
                        <a href="/this-is-a-very-bad-link">Navigate to an arbitrary Missing Page</a>
                    </p>
                </div>
            </div>

            <div className="card mb-2">
                <div className="card-body">
                    <p className="mb-2">An error that occurs during the render of a React Component will trigger the standard error page. Once triggered, you can click Refresh to recover the page.</p>
                    <button className="btn btn-outline-primary" onClick={triggerReactComponentError}>
                        Trigger React Component Error
                    </button>
                </div>
            </div>

            <div className="card mb-2">
                <div className="card-body">
                    <div className="mb-2">
                        <p className="mb-2">API Errors - Toast Notifications</p>
                        <button className="btn btn-outline-primary me-2" onClick={async () => await makeTheCall({} as ApiException)}>
                            Service Unavailable
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={async () => await makeTheCall(new ApiException("", 500, "", {}, null))}>
                            Internal Server Error - 500
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={async () => await makeTheCall(new ApiException("", 400, "", {}, null))}>
                            Bad Request - 400
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={async () => await makeTheCall(new ApiException("", 404, "", {}, null))}>
                            Not Found - 404
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={async () => await makeTheCall(new ApiException("", 403, "", {}, null))}>
                            Unauthorised - 403
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={async () => await makeTheCall(new ApiException("", 409, "", {}, null))}>
                            Conflict - 409
                        </button>
                    </div>

                    <div className="mb-2">
                        <p className="mb-2">API Errors - Standard Error Pages</p>
                        <button className="btn btn-outline-primary me-2" onClick={() => window.location.assign("/ServiceUnavailable")}>
                            Service Unavailable
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={() => window.location.assign("/Unauthorised")}>
                            Unauthorised - 403
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={() => window.location.assign("/NotFound")}>
                            Not Found - 404
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={() => window.location.assign("/Conflict")}>
                            Conflict - 409
                        </button>
                        <button className="btn btn-outline-primary me-2" onClick={() => window.location.assign("/InternalServerError")}>
                            Internal Server Error - 500
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: any) => ({}), { setLayout })(TestErrorPage);
