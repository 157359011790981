import * as Client from "../../../../api/Client";

export enum searchLicenceActions {
    // -- LicenceSearch
    SearchLicencesRequest = "LICENCE_SEARCH.SEARCH_LICENCES_REQUEST",
    SearchLicencesResponse = "LICENCE_SEARCH.SEARCH_LICENCES_RESPONSE",
}

export interface ILicenceSearchActions {
    type: searchLicenceActions;
    filter?: string | undefined;
    licences?: Client.GetPublicLicencesListItemDto[] | undefined;
}

export function searchLicenceRequestAction(filter: string): ILicenceSearchActions {
    return {
        type: searchLicenceActions.SearchLicencesRequest,
        filter: filter,
    };
}

export function searchLicenceResponseAction(licences: Client.GetPublicLicencesListItemDto[]): ILicenceSearchActions {
    return {
        type: searchLicenceActions.SearchLicencesResponse,
        licences: licences,
    };
}
