// api
import * as Client from "../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

function ModernWorkProgramView(props: { workProgram: Client.GetPublicTitleDetailsWorkProgramDto | undefined }) {
    const workProgram = props.workProgram;
    const years = workProgram?.years ?? new Array<Client.GetPublicTitleDetailsWorkProgramYearDto>();
    const sortedYears = [...years].sort(function (y1, y2) {
        // sort by yearOrder ascending
        return y1.yearOrder - y2.yearOrder;
    });

    return (
        <>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Year</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col" className="text-end">
                                Quantity
                            </th>
                            <th scope="col">Activity Description</th>
                            <th scope="col" className="text-end">
                                Indicative Expenditure (AUD)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedYears.map((y) => {
                            let activities = y.activities ?? new Array<Client.GetPublicTitleDetailsWorkProgramYearActivityDto>();
                            let rowSpan = activities.length;

                            const sortedActivities = [...activities].sort(function (a1, a2) {
                                // sort by activityOrder ascending
                                return a1.activityOrder - a2.activityOrder;
                            });

                            return sortedActivities.map((a, i) => {
                                let rowKey = `${y.yearOrder}-${a.activityOrder}`;

                                return i === 0 ? (
                                    <tr key={rowKey}>
                                        <td rowSpan={rowSpan}>{y.years}</td>
                                        <td rowSpan={rowSpan}>{GlobalHelpers.toNoptaDateString(y.startDate)}</td>
                                        <td rowSpan={rowSpan}>{GlobalHelpers.toNoptaDateString(y.endDate)}</td>
                                        <td className="text-end">{a.quantity}</td>
                                        <td style={{ maxWidth: 700, whiteSpace: "pre-line", wordWrap: "break-word" }}>{a.description}</td>
                                        {y.value ? (
                                            <td className="text-end" rowSpan={rowSpan}>
                                                {GlobalHelpers.toNoptaNumberString(y.value, "c0")}
                                            </td>
                                        ) : (
                                            <td className="text-end">{a.value && a.value > 0 ? GlobalHelpers.toNoptaNumberString(a.value, "c0") : null}</td>
                                        )}
                                    </tr>
                                ) : (
                                    <tr key={rowKey}>
                                        <td className="text-end">{a.quantity}</td>
                                        <td style={{ maxWidth: 700, whiteSpace: "pre-line", wordWrap: "break-word" }}>{a.description}</td>
                                        {!y.value && <td className="text-end">{a.value && a.value > 0 ? GlobalHelpers.toNoptaNumberString(a.value, "c0") : null}</td>}
                                    </tr>
                                );
                            });
                        })}
                    </tbody>
                    <tfoot className="table-light">
                        {workProgram?.showPrimaryAndSecondaryTerms && (
                            <>
                                <tr>
                                    <th className="fw-normal" colSpan={5}>
                                        Primary Term
                                    </th>
                                    <th className="fw-normal text-end">{GlobalHelpers.toNoptaNumberString(workProgram.primaryTermTotal, "c0")}</th>
                                </tr>
                                <tr>
                                    <th className="fw-normal" colSpan={5}>
                                        Secondary Term
                                    </th>
                                    <th className="fw-normal text-end">{GlobalHelpers.toNoptaNumberString(workProgram.secondaryTermTotal, "c0")}</th>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th colSpan={5}>Total</th>
                            <th className="text-end">{GlobalHelpers.toNoptaNumberString(workProgram?.grandTotal, "c0")}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
}

export default ModernWorkProgramView;
