import { useState } from "react";
import Alert from "react-bootstrap/alert";
import { AlertIcon, CheckIcon, InfoIcon } from "@primer/octicons-react";

export enum dismissableAlertType {
    success,
    warning,
    information,
    error,
}

function DismissableAlertControl(props: { message: string; alertType: dismissableAlertType; canDismiss?: boolean }) {
    // notes:
    // - state is stored directly in the control, allowing for non-global state
    // - as this can be dismissed, only one message per alert is supported
    // - once dismissed, there is no way to get it back without resetting the component's state
    //   as such, this is only suitable for one-shot alerts

    const [show, setShow] = useState(true);

    if (!show) return <></>;

    const canDismiss = props.canDismiss ?? true;

    switch (props.alertType) {
        case dismissableAlertType.error:
            return (
                <Alert variant="danger" className="m-2" dismissible={canDismiss} onClose={() => setShow(false)}>
                    <AlertIcon size="small" className="me-2" />
                    <span>{props.message}</span>
                </Alert>
            );

        case dismissableAlertType.information:
            return (
                <Alert variant="info" className="m-2" dismissible={canDismiss} onClose={() => setShow(false)}>
                    <InfoIcon size="small" className="me-2" />
                    <span>{props.message}</span>
                </Alert>
            );

        case dismissableAlertType.success:
            return (
                <Alert variant="success" className="m-2" dismissible={canDismiss} onClose={() => setShow(false)}>
                    <CheckIcon size="small" className="me-2" />
                    <span>{props.message}</span>
                </Alert>
            );

        case dismissableAlertType.warning:
            return (
                <Alert variant="warning" className="m-2" dismissible={canDismiss} onClose={() => setShow(false)}>
                    <AlertIcon size="small" className="me-2" />
                    <span>{props.message}</span>
                </Alert>
            );

        default:
            throw new Error("Unknown");
    }
}

export default DismissableAlertControl;
