// lifted and adapted from https://github.com/Azure-Samples/application-insights-react-demo/blob/master/src/TelemetryService.js
// initialises the Application Insights instance
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let reactPlugin: ReactPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

// intialiser
// - history object will no longer be available in react-router v6, use enableAutoRouteTracking as per https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
// - as noted in a github issue this will mean losing pageview duration data, but I don't believe this is used: https://github.com/microsoft/applicationinsights-react-js/issues/13#issuecomment-1201875312
function createTelemetryService() {
    const initialize = (connectionString: string, browserHistory: any) => {
        if (!browserHistory) {
            throw new Error("Could not initialize Telemetry Service");
        }
        if (!connectionString) {
            throw new Error("Connection string not provided in ./src/telemetry-provider.jsx");
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
}

// for integration with TelemtryProvider
export const ai = createTelemetryService();

//
// helper functions
//

export function logError(message: string) {
    if (appInsights !== null) appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error });
}

export function logTrace(message: string) {
    if (appInsights !== null) appInsights.trackTrace({ message });
}
