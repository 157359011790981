// bootstrap
import { Alert } from "react-bootstrap";
// icons
import { AlertIcon, CheckIcon, InfoIcon } from "@primer/octicons-react";

export function renderError(messages: Array<string>, onClose?: () => void): React.ReactNode {
    return render(messages, "danger", <AlertIcon className="d-inline me-1" />, onClose);
}
export function renderSuccess(messages: Array<string>, onClose?: () => void): React.ReactNode {
    return render(messages, "success", <CheckIcon className="d-inline me-1" />, onClose);
}
export function renderWarning(messages: Array<string>, onClose?: () => void): React.ReactNode {
    return render(messages, "warning", <AlertIcon className="d-inline me-1" />, onClose);
}
export function renderInfo(messages: Array<string>, onClose?: () => void): React.ReactNode {
    return render(messages, "info", <InfoIcon className="d-inline me-1" />, onClose);
}

export function render(messages: Array<string>, className: string, icon: React.ReactNode, onClose?: () => void): React.ReactNode {
    if (messages.length === 0) return <></>;

    const elements = messages.map((message, index) => {
        const key = `${className}/${index}`;
        return (
            <div key={key}>
                {icon}
                {message}
            </div>
        );
    });

    return (
        <Alert variant={className} className="mb-1" {...(onClose ? { dismissible: true, onClose: onClose } : {})}>
            {elements}
        </Alert>
    );
}
