// framework
import React, { useCallback, useEffect, useMemo, useState } from "react";
// kendo
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";

interface IProps<T> {
    id: string;
    value: T | undefined;
    data: T[] | undefined;
    keyField?: string | undefined;
    textField?: string | undefined;
    recordsToShow?: number | undefined;
    disabled?: boolean | undefined;
    valid?: boolean | undefined;
    className?: string | undefined;
    filterable?: boolean | undefined;
    suggest?: boolean | undefined;
    allowCustom?: boolean | undefined;
    onChange: (value: T | undefined) => void;
    onBlur?: (() => void) | undefined;
}

// NOTE: While this has been implemented in TERI it is not currently consumed here, although it will be ported to and consumed in PEPI...
export default function KendoLargeComboBox<T>(props: IProps<T>) {
    const recordsToShow = props.recordsToShow ?? 100;

    const hintText = "Enter details to filter...";
    const hintMessageItem = useMemo((): T => (props.keyField && props.textField ? ({ [props.keyField]: null, [props.textField]: hintText } as T) : (hintText as T)), [props.keyField, props.textField]);

    const noRecordsFoundText = "No records found";
    const noRecordsFoundMessageItem = useMemo(
        () => (props.keyField && props.textField ? ({ [props.keyField]: null, [props.textField]: `${noRecordsFoundText}` } as T) : (`${noRecordsFoundText}` as T)),
        [props.keyField, props.textField]
    );

    const displayingFirstText = "Displaying first";
    const getDisplayingFirstMessageItem = useCallback(
        (filteredDataLength: number) => {
            return props.keyField && props.textField
                ? ({ [props.keyField]: null, [props.textField]: `${displayingFirstText} ${recordsToShow} of ${filteredDataLength} records` } as T)
                : (`${displayingFirstText} ${recordsToShow} of ${filteredDataLength} records` as T);
        },
        [props.keyField, props.textField, recordsToShow]
    );

    const getFilterTextForValue = useCallback(
        (value: T | undefined) => {
            return value ? (props.textField ? (value as any)[props.textField] : value) : null;
        },
        [props.textField]
    );

    const getSanitisedValue = useCallback(
        (value: T | undefined): T | undefined => {
            const filterText = getFilterTextForValue(value);
            // NOTE: If custom items are allowed, then we need to check it isn't one of the custom message items we've inserted...
            if (props.allowCustom && filterText !== hintText && filterText !== noRecordsFoundText && !filterText?.startsWith(displayingFirstText)) {
                return value;
            } else {
                return props.data?.find((v) => v === value);
            }
        },
        [getFilterTextForValue, props.allowCustom, props.data]
    );

    const [filterText, setFilterText] = React.useState<string | null>(null);
    // value - must be set to null in order to programatically clear the combobox
    const [value, setValue] = useState<T | undefined | null>(null);
    const [data, setData] = useState<T[]>([hintMessageItem]);

    useEffect(() => {
        const value = getSanitisedValue(props.value);
        setFilterText(getFilterTextForValue(value));
        setValue(value ?? null);
        setData(value ? [value] : [hintMessageItem]);
    }, [getFilterTextForValue, getSanitisedValue, hintMessageItem, props.textField, props.value]);

    const filterData = (filter: FilterDescriptor): T[] => {
        if (!filter.value || filter.value.length === 0) return [hintMessageItem];

        const data = props.data?.slice();
        const filteredData = filterBy(data ?? [], filter);

        if (filteredData.length === 0) return [noRecordsFoundMessageItem];
        if (filteredData.length <= recordsToShow) return filteredData;

        return filteredData?.slice(0, recordsToShow).concat([getDisplayingFirstMessageItem(filteredData.length)]);
    };

    function onFilterChange(event: ComboBoxFilterChangeEvent) {
        setFilterText(event.filter.value);
        setData(filterData(event.filter));
    }

    function onChange(event: ComboBoxChangeEvent) {
        const value = getSanitisedValue(event.target.value);
        setFilterText(getFilterTextForValue(value));
        setValue(value ?? null);
        props.onChange(value ?? undefined);
    }

    function onBlur() {
        if (!value) {
            setFilterText(null);
            setData([hintMessageItem]);
        }
        props.onBlur?.();
    }

    return (
        <ComboBox
            id={props.id}
            value={value}
            data={data}
            dataItemKey={props.keyField}
            textField={props.textField}
            disabled={props.disabled}
            valid={props.valid}
            className={props.className}
            filterable={props.filterable}
            filter={filterText}
            suggest={props.suggest}
            allowCustom={props.allowCustom}
            onFilterChange={onFilterChange}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="Enter details to filter..."
            popupSettings={{ height: 390 }}
        />
    );
}
