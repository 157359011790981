// api
import * as Client from "../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import TitleSummaryPriorUsageRightField from "./TitleSummaryPriorUsageRightField";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function TitleSummaryPlView(props: { summary: Client.GetPublicTitleDetailsSummaryDto | undefined }) {
    const summary = props.summary;

    return (
        <Forms.Row>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Status" id="statusField" value={summary?.titleStatus} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Offshore Area" id="offshoreAreaField" value={summary?.offshoreAreaName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Number of Renewals" id="numberOfRenewalsField" value={GlobalHelpers.toNoptaIntegerString(summary?.numberOfRenewals)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Initial Grant Date" id="initialGrantDateField" value={GlobalHelpers.toNoptaDateString(summary?.initialGrantDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(summary?.currentExpiryDate, "Indefinite")} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Last Renewal Date" id="lastRenewalDateField" value={GlobalHelpers.toNoptaDateString(summary?.lastRenewalDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Pipeline Name" id="pipelineNameField" value={summary?.pipelineName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Pipeline Substance" id="pipelineSubstanceField" value={summary?.substanceName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Start Point" id="startPointField" value={summary?.startPointName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="End Point" id="endPointField" value={summary?.endPointName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Length" id="lengthField" value={GlobalHelpers.toNoptaNumberString(summary?.pipelineLength, "n", "km")} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <TitleSummaryPriorUsageRightField hasPriorUsageRight={summary?.hasPriorUsageRight} />
            </Forms.QuarterColumn>
        </Forms.Row>
    );
}

export default TitleSummaryPlView;
