// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { IApplicationTrackingActions, applicationTrackingActions, getOpggsApplicationTrackingResponseAction } from "./opggsApplicationTrackingActions";
// api
import { createOpggsApplicationsClient } from "../../../../api/util";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(applicationTrackingActions.GetApplicationTrackingRequest, workerSaga);
}

function* workerSaga(action: IApplicationTrackingActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicOpggsApplicationsTrackingResponseDto = yield call(query, action.isCompleted, action.applicationTrackingType);
        // post response
        yield put(getOpggsApplicationTrackingResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(isCompleted: boolean | undefined, applicationTrackingType: Client.OpggsApplicationTrackingTypeEnum | undefined): Promise<Client.GetPublicOpggsApplicationsTrackingResponseDto> {
    const client = createOpggsApplicationsClient();

    const request = new Client.GetPublicOpggsApplicationsTrackingRequestDto({
        isCompleted: isCompleted ?? false,
        applicationTrackingType: applicationTrackingType,
    });

    const response = await simpleCall(() => client.getPublicOpggsApplicationsTracking(request));
    return response;
}
