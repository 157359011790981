import { TitleTypeEnum } from "../../../../api/Client";

export default class TitleDetailsConfiguration {
    public constructor(titleType: TitleTypeEnum | undefined) {
        if (!titleType) {
            this.IsWorkProgramVisible = false;
            this.IsBlocksAndLocationsTabVisible = false;
            this.IsLocationsDataVisible = false;
            return;
        }

        switch (titleType) {
            case TitleTypeEnum.AccessAuthority:
                this.IsWorkProgramVisible = false;
                this.IsBlocksAndLocationsTabVisible = true;
                this.IsLocationsDataVisible = false;
                return;
            case TitleTypeEnum.ExplorationPermit:
                this.IsWorkProgramVisible = true;
                this.IsBlocksAndLocationsTabVisible = true;
                this.IsLocationsDataVisible = true;
                return;
            case TitleTypeEnum.InfrastructureLicence:
                this.IsWorkProgramVisible = false;
                this.IsBlocksAndLocationsTabVisible = false;
                this.IsLocationsDataVisible = false;
                return;
            case TitleTypeEnum.PipelineLicence:
                this.IsWorkProgramVisible = false;
                this.IsBlocksAndLocationsTabVisible = false;
                this.IsLocationsDataVisible = false;
                return;
            case TitleTypeEnum.ProductionLicence:
                this.IsWorkProgramVisible = false;
                this.IsBlocksAndLocationsTabVisible = true;
                this.IsLocationsDataVisible = false;
                return;
            case TitleTypeEnum.RetentionLease:
                this.IsWorkProgramVisible = true;
                this.IsBlocksAndLocationsTabVisible = true;
                this.IsLocationsDataVisible = false;
                return;
            case TitleTypeEnum.SpecialProspectingAuthority:
                this.IsWorkProgramVisible = false;
                this.IsBlocksAndLocationsTabVisible = true;
                this.IsLocationsDataVisible = false;
                return;
            case TitleTypeEnum.GreenhouseGasAssessmentPermit:
                this.IsWorkProgramVisible = true;
                this.IsBlocksAndLocationsTabVisible = true;
                this.IsLocationsDataVisible = false;
                return;
            default:
                throw new Error(`Unsupported Title Type '${titleType}'.`);
        }
    }

    public IsWorkProgramVisible: boolean;
    public IsBlocksAndLocationsTabVisible: boolean;
    public IsLocationsDataVisible: boolean;
}
