// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../shell/redux/shellActions";
import ExternalLinkView from "../../../common/externalLink/ExternalLinkView";
import EmailLinkView from "../../../common/externalLink/EmailLinkView";

function Controller(props: any) {
    const { setLayout } = props;

    // initial load
    useEffect(() => {
        setLayout("Home | NOPIMS", false);
    }, [setLayout]);

    // return
    return (
        <>
            <div className="container">
                <div className="text-center">
                    <h1 className="fw-bold">Welcome to NOPIMS</h1>
                    <h3 className="fw-bold">National Offshore Petroleum Information Management System</h3>
                    <p>This version of NOPIMS will continue to be enhanced over time, this site is only available in a UAT environment and will not be publicly accessible.</p>
                </div>

                <h2 className="text-center fw-bold">About NOPIMS</h2>
                <p>
                    The National Offshore Petroleum Information Management System (NOPIMS) has been developed as an online data discovery and delivery system for all Australian offshore petroleum
                    wells and survey information, acquired primarily in Commonwealth waters and submitted under legislation, currently the Offshore Petroleum and Greenhouse Gas Storage Act 2006
                    (OPGGS).
                </p>
                <p>
                    Please visit{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au/reporting-and-data-submissions/data-submissions.html" className="card-link">
                        https://www.nopta.gov.au/reporting-and-data-submissions/data-submissions.html
                    </ExternalLinkView>{" "}
                    to learn more about data submission requirements and to access links to legislation, regulations and guidance material.
                </p>

                <h2 className="text-center fw-bold">Contact Details</h2>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-3 mx-auto">
                            <span className="fw-bold text-center">General Enquiries</span>
                            <p>
                                Telephone:{" "}
                                <a href="tel:+61864245300" className="telno">
                                    +61 8 6424 5300
                                </a>
                                <br />
                                Email: <EmailLinkView emailAddress="info@nopta.gov.au" />
                            </p>
                        </div>
                        <div className="col-md-3 mx-auto">
                            <span className="fw-bold">NEATS Technical and Data Queries</span>
                            <p>
                                Email: <EmailLinkView emailAddress="neats.admin@nopta.gov.au" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

// connect redux
export default connect((state: any) => ({}), { setLayout })(Controller);
