import { toast } from "react-toastify";

export function showExceptionNotification(title: string = "Unexpected Error", message: string = "Please try the operation again. If the issue persists, please contact support."): void {
    // when an exception occurs, this takes precedence over all existing notifications
    toast.dismiss();

    toast.error(
        <div>
            <strong>{title}</strong>
            <br />
            <span>{message}</span>
        </div>,
        { autoClose: false }
    );
}

export function showDevelopmentUnexpectedExceptionNotification(): void {
    toast.error(
        <div>
            <strong>¯\_(ツ)_/¯</strong>
            <br />
            <span>Check the console window.</span>
        </div>,
        { autoClose: false }
    );
}

export function showSuccessNotification(title: string, message: string): void {
    toast.success(
        <div>
            <strong>{title}</strong>
            <br />
            <span>{message}</span>
        </div>
    );
}

export function showValidationErrorNotification(title: string = "Action Required", message: string = "Please review validation errors."): void {
    toast.error(
        <div>
            <strong>{title}</strong>
            <br />
            <span>{message}</span>
        </div>
    );
}
