import { searchTitleActions, ITitleSearchActions } from "./titleSearchActions";
import * as Client from "../../../../api/Client";

// initial state
export interface ITitleSearchState {
    filter?: string | undefined;
    titles?: Client.GetPublicTitlesListItemDto[] | undefined;
}

const initialState: ITitleSearchState = {
    filter: "",
    titles: [],
};

// reducers
export function titleSearchReducer(state: ITitleSearchState = initialState, action: ITitleSearchActions): ITitleSearchState {
    switch (action.type) {
        case searchTitleActions.SearchTitlesRequest:
            return {
                ...state,
                filter: action.filter,
                titles: [],
            };

        case searchTitleActions.SearchTitlesResponse:
            return {
                ...state,
                titles: action.titles,
            };

        default:
            return state;
    }
}
