function EnvironmentFootnote() {
    const environment: string = process.env.REACT_APP_CONFIGURATION_ENVIRONMENT!;
    const version: string = process.env.REACT_APP_CONFIGURATION_VERSION!;

    return (
        <span>
            {environment}, Version: {version}
        </span>
    );
}

export default EnvironmentFootnote;
