import * as Client from "../../../../api/Client";

export enum licenceDetailsActions {
    GetLicenceDetailsRequest = "LICENCE_DETAILS.REQUEST",
    GetLicenceDetailsResponse = "LICENCE_DETAILS.RESPONSE",
    ClearLicenceDetails = "LICENCE_DETAILS.CLEAR",
    ClearLicenceApplicationDocument = "LICENCE_APPLICATION_DOCUMENT.CLEAR",
}

export interface ILicenceDetailsActions {
    type: licenceDetailsActions;
    licenceId?: string | undefined;
    applicationId?: string | undefined;
    detailsResponse?: Client.GetPublicLicenceDetailsResponseDto | undefined;
}

// Licence Details
export function getLicenceDetailsRequestAction(licenceId: string): ILicenceDetailsActions {
    return {
        type: licenceDetailsActions.GetLicenceDetailsRequest,
        licenceId: licenceId,
    };
}

export function getLicenceDetailsResponseAction(response: Client.GetPublicLicenceDetailsResponseDto): ILicenceDetailsActions {
    return {
        type: licenceDetailsActions.GetLicenceDetailsResponse,
        detailsResponse: response,
    };
}

export function clearLicenceDetailsAction(): ILicenceDetailsActions {
    return {
        type: licenceDetailsActions.ClearLicenceDetails,
    };
}
