// framework
import * as Validation from "../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    if (!vm.feedback.customerFirstName) {
        v.addError("CreateFeedback.CustomerFirstName", "First Name is required.");
    }

    if (!vm.feedback.customerLastName) {
        v.addError("CreateFeedback.CustomerLastName", "Last Name is required.");
    }

    if (!vm.feedback.customerContactNumber) {
        v.addError("CreateFeedback.CustomerContactNumber", "Phone is required.");
    }

    if (!vm.feedback.customerEmailAddress) {
        v.addError("CreateFeedback.CustomerEmailAddress", "Email is required.");
    } else if (!Validation.isValidEmail(vm.feedback.customerEmailAddress!)) {
        v.addError("CreateFeedback.CustomerEmailAddress", "Email is not valid.");
    }

    if (!vm.feedback.feedbackType) {
        v.addError("CreateFeedback.FeedbackType", "Type is required.");
    }

    if (vm.feedback.feedbackType?.isDataFeedbackType && !vm.feedback.dataFeedbackActivityType) {
        v.addError("CreateFeedback.DataFeedbackActivityType", "Activity Type is required.");
    }

    if (vm.feedback.dataFeedbackActivityType && !vm.feedback.dataFeedbackActivityName) {
        v.addError("CreateFeedback.DataFeedbackActivityName", "Activity Name is required.");
    }

    if (vm.feedback.dataFeedbackActivityType && !vm.feedback.dataMonitoringItemType) {
        v.addError("CreateFeedback.DataMonitoringItemType", "Data Type is required.");
    }

    if (!vm.feedback.customerComments) {
        v.addError("CreateFeedback.CustomerComments", "Comments are required.");
    }

    return v;
}
