import { titleDetailsActions, ITitleDetailsActions } from "./titleDetailsActions";
import * as Client from "../../../../api/Client";

// initial state
export interface ITitleDetailsState {
    // details
    response?: Client.GetPublicTitleDetailsResponseDto | undefined;
    // map
    mapResponse?: Client.GetPublicTitleDetailsMapResponseDto | undefined;
}

const initialState: ITitleDetailsState = {
    // details
    response: undefined,
    // map
    mapResponse: undefined,
};

// reducers
export function titleDetailsReducer(state: ITitleDetailsState = initialState, action: ITitleDetailsActions): ITitleDetailsState {
    switch (action.type) {
        // Title Details
        case titleDetailsActions.GetTitleDetailsRequest:
            return {
                ...state,
                response: undefined,
            };

        case titleDetailsActions.GetTitleDetailsResponse:
            return {
                ...state,
                response: action.detailsResponse,
            };

        case titleDetailsActions.ClearTitleDetails:
            return {
                ...state,
                response: undefined,
            };

        // Title Map
        case titleDetailsActions.GetTitleMapDetailsRequest:
            return {
                ...state,
                mapResponse: undefined,
            };

        case titleDetailsActions.GetTitleMapDetailsResponse:
            return {
                ...state,
                mapResponse: action.mapResponse,
            };

        case titleDetailsActions.ClearTitleMapDetails:
            return {
                ...state,
                mapResponse: undefined,
            };

        // Default
        default:
            return state;
    }
}
