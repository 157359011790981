// api
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as Client from "../../../../api/Client";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";

function TitleholdersView(props: { response: Client.GetPublicTitleDetailsResponseDto | undefined }) {
    const titleholders = props.response?.titleholders ?? new Array<Client.GetPublicTitleDetailsTitleholderDto>();
    const sortedTitleholders = [...titleholders].sort(function (t1, t2) {
        // sort by operator
        if (t1.isOperator > t2.isOperator) return -1;
        if (t1.isOperator < t2.isOperator) return 1;
        // then by percentage ownership descending
        if (t1.percentageOwnership > t2.percentageOwnership) return -1;
        if (t1.percentageOwnership < t2.percentageOwnership) return 1;
        // then by company name
        if (t1.companyName && (!t2.companyName || t1.companyName < t2.companyName)) return -1;
        if (t2.companyName && (!t1.companyName || t2.companyName < t1.companyName)) return 1;

        return 0;
    });

    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    <h2 className="card-title">Titleholders</h2>
                    {sortedTitleholders && (
                        <>
                            <Grid resizable navigatable data={sortedTitleholders}>
                                <GridColumn
                                    field="companyName"
                                    title="Company Name"
                                    width={800}
                                    cell={(props) => (
                                        <CustomGridCell gridCellProps={props}>
                                            {props.dataItem.companyName}
                                            {props.dataItem.isOperator && "*"}
                                        </CustomGridCell>
                                    )}
                                />
                                <GridColumn field="acnOrArbn" title="ACN / ARBN" width={300} />
                            </Grid>
                            <div className="mt-2 text-muted">* Contact Titleholder</div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default TitleholdersView;
