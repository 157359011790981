// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// common
import LicenceLink from "../../../../common/links/LicenceLinkView";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";

function LicenceSearchListView(props: { searchText: string; licences: Client.GetPublicLicencesListItemDto[]; onSearchTextChanged: (value: string) => void }) {
    const initialGridState = {
        sort: [{ field: "comesIntoForceDate", dir: "asc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    const [lastSearchText, setLastSearchText] = useState(props.searchText);
    const [searchText, setSearchText] = useState(props.searchText);

    if (lastSearchText !== props.searchText) {
        // keep the local search text in sync with what's passed in, it can be changed external to this page
        // if the filter that is passed in differs from the local state, reset the local state
        setLastSearchText(props.searchText);

        // only change the search text control once!  if you try to change it all the time the user cannot type!
        setSearchText(props.searchText);
    }

    // local handlers
    function onSearchTextChanged(e: any) {
        setSearchText(e.target.value);
    }

    function onSearchSubmit(e: any) {
        e.preventDefault(); // stops posting
        props.onSearchTextChanged(searchText);
        setGridState(initialGridState);
    }

    function onResetSearchTextClicked() {
        setSearchText("");
        props.onSearchTextChanged("");
        setGridState(initialGridState);
    }

    // view
    return (
        <>
            {/* search box */}
            <form onSubmit={onSearchSubmit} className="d-flex align-items-center mb-3">
                <label htmlFor="searchText" className="visually-hidden">
                    Search
                </label>
                <input id="searchText" className="form-control me-2" type="text" placeholder="Search" style={{ width: "22rem" }} value={searchText} onChange={onSearchTextChanged} />
                <button id="searchTextButton" className="btn btn-outline-primary me-1" type="submit">
                    Search
                </button>
                <button id="resetSearchTextButton" className="btn btn-outline-secondary" type="button" onClick={onResetSearchTextClicked}>
                    Reset
                </button>
            </form>

            {/* results grid */}
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.licences, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="licenceNumber"
                    title="Licence"
                    width={160}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.licences)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <LicenceLink licenceId={props.dataItem.licenceId} licenceNumber={props.dataItem.licenceNumber} />
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="licenceType" title="Licence Type" width={220} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.licences)} />
                <GridColumn field="licenceStatus" title="Status" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.licences)} />
                <GridColumn field="licenceHolder" title="Licence Holder" width={500} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.licences)} />
                <GridColumn field="grantDate" title="Grant" format={GlobalHelpers.NoptaDateFormatForKendo} width={140} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="comesIntoForceDate" title="Comes into Force" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="endDate" title="End" format={GlobalHelpers.NoptaDateFormatForKendo} width={130} filter="date" columnMenu={createGridColumnFilterMenu} />
            </Grid>
        </>
    );
}

export default LicenceSearchListView;
