// kendo
import { saveAs } from "@progress/kendo-file-saver";
// api
import { SecurePortalDocumentDto } from "../api/Client";

export function download(fileName: string, mimeType: string, content: string): void {
    const dataUri = "data:" + mimeType + ";base64," + content;
    saveAs(dataUri, fileName);
}

export function tryDownloadDocument(document: SecurePortalDocumentDto | undefined): void {
    if (!document) return;
    download(document.fileName, document.mimeType, document.content);
}
