import * as Client from "../../api/Client";

export interface IAlertsModel {
    information: Array<string>;
    warning: Array<string>;
    success: Array<string>;
    error: Array<string>;

    addInformation(message: string): void;
    addWarning(message: string): void;
    addError(message: string): void;
    addSuccess(message: string): void;
    addStatusMessages(dto: Client.StatusMessagesDto): void;

    clear(): void;

    hasAnything(): boolean;
}

export class AlertsModel implements IAlertsModel {
    constructor(dto?: Client.StatusMessagesDto) {
        this.information = new Array<string>();
        this.warning = new Array<string>();
        this.success = new Array<string>();
        this.error = new Array<string>();

        if (dto) this.addStatusMessages(dto!);
    }

    information: Array<string>;
    warning: Array<string>;
    success: Array<string>;
    error: Array<string>;

    addInformation(message: string): void {
        this.information.push(message);
    }

    addWarning(message: string): void {
        this.warning.push(message);
    }

    addError(message: string): void {
        this.error.push(message);
    }

    addSuccess(message: string): void {
        this.success.push(message);
    }

    addStatusMessages(dto: Client.StatusMessagesDto): void {
        if (dto.error) dto.error!.forEach(e => this.addError(e));
        if (dto.success) dto.success!.forEach(e => this.addSuccess(e));
        if (dto.warning) dto.warning!.forEach(e => this.addWarning(e));
        if (dto.information) dto.information!.forEach(e => this.addInformation(e));
    }

    clear(): void {
        this.information = new Array<string>();
        this.warning = new Array<string>();
        this.success = new Array<string>();
        this.error = new Array<string>();
    }

    hasAnything(): boolean {
        return this.information.length > 0 || this.warning.length > 0 || this.success.length > 0 || this.error.length > 0;
    }
}