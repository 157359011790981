// framework
import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// other
import { LinkIcon } from "@primer/octicons-react";
import SimpleAlert from "../simpleAlerts/SimpleAlertView";

export default function CopyLinkView(props: { href: string }) {
    const [showDialog, setShowDialog] = useState(false);
    const [showCopied, setShowCopied] = useState(false);

    useEffect(() => {
        setShowCopied(false);
    }, [showDialog]);

    const linkTextRef = React.createRef<HTMLInputElement>();

    function copyLinkDialog(href: string) {
        return (
            <Dialog title="Copy Link" onClose={() => setShowDialog(false)}>
                {showCopied && <SimpleAlert message="Link text has been copied to the clipboard." alertType="success" dismissable={false} />}
                <span aria-label="Link text">
                    <input ref={linkTextRef} className="form-control" type="text" value={href} readOnly style={{ width: "500px" }}></input>
                </span>
                <DialogActionsBar>
                    <span className="mx-3 my-2">
                        <button className="btn btn-outline-primary me-2" onClick={copyLinkTextToClipboard}>
                            Copy Link to Clipboard
                        </button>
                        <button className="btn btn-outline-primary" onClick={() => setShowDialog(false)}>
                            Close
                        </button>
                    </span>
                </DialogActionsBar>
            </Dialog>
        );
    }

    function copyLinkTextToClipboard() {
        const textBox = linkTextRef.current!;
        textBox.select();
        textBox.setSelectionRange(0, textBox.value.length);
        document.execCommand("copy");

        setShowCopied(true);
    }

    return (
        <>
            <button className="btn btn-outline-secondary" title="Get the download document link." onClick={() => setShowDialog(true)}>
                <LinkIcon size="small" aria-label="Get the download document link." />
            </button>
            {showDialog && copyLinkDialog(props.href)}
        </>
    );
}
