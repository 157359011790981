import Bowser from "bowser";
import DismissableAlertControl, { dismissableAlertType } from "./DismissableAlertControl";

// https://www.npmjs.com/package/bowser

// see https://dev.azure.com/nopta/Custom/_wiki/wikis/Custom.wiki/1040/Browser-Compatibility

function SupportedBrowserBanner() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isSupportedBrowser = browser.satisfies({
        chrome: ">=72",
        opera: ">=58",
        safari: ">=13",
        edge: ">=80",
        firefox: ">=65",
    });

    if (isSupportedBrowser) {
        return <></>;
    }

    return (
        <DismissableAlertControl
            message="You are using an unsupported browser. To ensure the best security and user experience, please update your web browser. NOPTA recommends using the latest versions of Chrome, Edge, Firefox, Opera and Safari."
            alertType={dismissableAlertType.warning}
        />
    );
}

export default SupportedBrowserBanner;
