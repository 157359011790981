import { applicationTrackingDetailsActions, IApplicationTrackingDetailsActions } from "./opggsApplicationTrackingDetailsActions";
import * as Client from "../../../../api/Client";

// initial state
export interface IApplicationTrackingDetailsState {
    response?: Client.GetPublicOpggsApplicationsTrackingDetailsResponseDto | undefined;
}

const initialState: IApplicationTrackingDetailsState = {
    response: undefined,
};

// reducers
export function opggsApplicationTrackingDetailsReducer(state: IApplicationTrackingDetailsState = initialState, action: IApplicationTrackingDetailsActions): IApplicationTrackingDetailsState {
    switch (action.type) {
        case applicationTrackingDetailsActions.GetApplicationTrackingDetailsRequest:
            return {
                ...state,
                response: undefined,
            };

        case applicationTrackingDetailsActions.GetApplicationTrackingDetailsResponse:
            return {
                ...state,
                response: action.response,
            };

        case applicationTrackingDetailsActions.ClearApplicationTrackingDetails:
            return {
                ...state,
                response: undefined,
            };

        default:
            return state;
    }
}
