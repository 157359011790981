// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// shell
import { setLayout } from "../../../shell/redux/shellActions";
// api
import { clearWellDetailsAction, getWellDetailsRequestAction } from "../redux/actions";
// children views
import { IGetWellDetailsState } from "../redux/reducers";
import WellDetailsView from "./WellDetailsView";
import * as Client from "../../../api/Client";

interface IRouteParameters {
    wellId: string;
}

function Controller(props: any) {
    const { wellId } = useParams<IRouteParameters>();
    const { setLayout, getWellDetailsRequestAction, clearWellDetailsAction } = props;
    const state: IGetWellDetailsState = props.state;

    // - initial load
    useEffect(() => {
        getWellDetailsRequestAction(wellId);

        return function onUnmount() {
            clearWellDetailsAction();
        };
    }, [getWellDetailsRequestAction, clearWellDetailsAction, wellId]);

    // - set page header when response received
    useEffect(() => {
        if (!state.response) {
            setLayout("");
            return;
        }

        setLayout("Well  - " + state.response.name);
    }, [setLayout, state.response]);

    const response = state.response ?? new Client.GetPublicWellDetailsResponseDto();

    // return
    return <WellDetailsView response={response} />;
}

// connect redux
export default connect((state: any) => ({ state: state.wellDetails }), {
    setLayout,
    getWellDetailsRequestAction,
    clearWellDetailsAction,
})(Controller);
