import { getWellDetailsActions, IGetWellDetailsActions } from "./actions";
import * as Client from "../../../api/Client";

// initial state
export interface IGetWellDetailsState {
    response?: Client.GetPublicWellDetailsResponseDto | undefined;
}

const initialState: IGetWellDetailsState = {
    response: undefined,
};

// reducers
export function wellDetailsReducer(state: IGetWellDetailsState = initialState, action: IGetWellDetailsActions): IGetWellDetailsState {
    switch (action.type) {
        case getWellDetailsActions.GetWellDetailsRequest:
            return {
                ...state,
                response: undefined,
            };

        case getWellDetailsActions.GetWellDetailsResponse:
            return {
                ...state,
                response: action.response,
            };

        case getWellDetailsActions.ClearWellDetails:
            return {
                ...state,
                response: undefined,
            };

        default:
            return state;
    }
}
