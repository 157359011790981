import { useSelector } from "react-redux";
import * as Client from "../../api/Client";

export function useFeatureSwitch(featureSwitch: Client.NeatsFeatureSwitchEnum): boolean | undefined {
    const configuration = useSelector((state: any) => state.shellConfiguration);
    const featureSwitches = configuration?.response?.featureSwitches;

    // enabled features will come back, so if it's in here, it's enabled
    return featureSwitches ? featureSwitches.some((f: Client.NeatsFeatureSwitchEnum) => f === featureSwitch) : undefined;
}
