import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { LockIcon } from "@primer/octicons-react";

// configured timeout
const sessionTimeoutMinutes = Number(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES);
const sessionTimeout = sessionTimeoutMinutes * 60 * 1000;

/*
    PEPI Sessions
    - avoids stale data
    - forces regeneration of file store authorisations

    https://dev.azure.com/nopta/Custom/_wiki/wikis/Custom.wiki/1609/Sessions
*/

export default function SessionController(): JSX.Element {
    const [showSessionTimeoutOverlay, setShowSessionTimeoutOverlay] = useState(false);

    function onIdle(): void {
        if (showSessionTimeoutOverlay) return;
        setShowSessionTimeoutOverlay(true);
    }
    useIdleTimer({ onIdle, timeout: sessionTimeout });

    return <>{showSessionTimeoutOverlay && <SessionTimeoutOverlay />}</>;
}

function SessionTimeoutOverlay(): JSX.Element {
    return (
        <Dialog title="Session Timeout" closeIcon={false}>
            <div className="mb-1">
                <span className="me-2">
                    <LockIcon size={24} />
                </span>
                <strong>Your session has timed out.</strong>
            </div>
            <div>Please reload to continue using the system.</div>
            <DialogActionsBar>
                <button className="btn btn-outline-primary" onClick={() => window.location.reload()}>
                    Reload
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
