// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// common
import TitleLink from "../../../../common/links/TitleLinkView";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";

function TitleSearchListView(props: { searchText: string; titles: Client.GetPublicTitlesListItemDto[]; onSearchTextChanged: (value: string) => void }) {
    const initialGridState = {
        sort: [
            { field: "lastRenewalDate", dir: "desc" },
            { field: "initialGrantDate", dir: "desc" },
        ],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    const [lastSearchText, setLastSearchText] = useState(props.searchText);
    const [searchText, setSearchText] = useState(props.searchText);

    if (lastSearchText !== props.searchText) {
        // keep the local search text in sync with what's passed in, it can be changed external to this page
        // if the filter that is passed in differs from the local state, reset the local state
        setLastSearchText(props.searchText);

        // only change the search text control once!  if you try to change it all the time the user cannot type!
        setSearchText(props.searchText);
    }

    // local handlers
    function onSearchTextChanged(e: any) {
        setSearchText(e.target.value);
    }

    function onSearchSubmit(e: any) {
        e.preventDefault(); // stops posting
        props.onSearchTextChanged(searchText);
        setGridState(initialGridState);
    }

    function onResetSearchTextClicked() {
        setSearchText("");
        props.onSearchTextChanged("");
        setGridState(initialGridState);
    }

    // view
    return (
        <>
            {/* search box */}
            <form onSubmit={onSearchSubmit} className="d-flex align-items-center mb-3">
                <label htmlFor="searchText" className="visually-hidden">
                    Search
                </label>
                <input id="searchText" className="form-control me-2" type="text" placeholder="Search" style={{ width: "22rem" }} value={searchText} onChange={onSearchTextChanged} />
                <button id="searchTextButton" className="btn btn-outline-primary me-1" type="submit">
                    Search
                </button>
                <button id="resetSearchTextButton" className="btn btn-outline-secondary" type="button" onClick={onResetSearchTextClicked}>
                    Reset
                </button>
            </form>

            {/* results grid */}
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.titles, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="titleNumber"
                    title="Title"
                    width={160}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.titles)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <TitleLink titleId={props.dataItem.titleId} titleNumber={props.dataItem.titleNumber} />
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="titleType" title="Title Type" width={220} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.titles)} />
                <GridColumn field="titleStatus" title="Status" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.titles)} />
                <GridColumn field="titleholders" title="Titleholders" width={500} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="currentExpiryDate" title="Expiry" format={GlobalHelpers.NoptaDateFormatForKendo} width={160} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="lastRenewalDate" title="Renewal" format={GlobalHelpers.NoptaDateFormatForKendo} width={170} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="initialGrantDate" title="Grant" format={GlobalHelpers.NoptaDateFormatForKendo} width={160} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="offshoreAreaName" title="Offshore Area" width={285} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.titles)} />
                <GridColumn field="basinName" title="Basin" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.titles)} />
            </Grid>
        </>
    );
}

export default TitleSearchListView;
