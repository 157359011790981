// framework
import { NavLink } from "react-router-dom";
// other
import EnvironmentFootnote from "./EnvironmentFootnote";
import ExternalLinkView from "../../common/externalLink/ExternalLinkView";

function Footer() {
    return (
        <div className="container-fluid p-4">
            <hr />
            <div className="d-flex flex-wrap align-items-center">
                <NavLink className="me-4" to="/">
                    Home
                </NavLink>
                <NavLink className="me-4" to="/Accessibility">
                    Accessibility
                </NavLink>
                <ExternalLinkView href="https://www.nopta.gov.au/copyright.html" className="me-4">
                    Copyright
                </ExternalLinkView>
                <NavLink className="me-4" to="/Disclaimer">
                    Disclaimer
                </NavLink>
                <ExternalLinkView href="https://www.nopta.gov.au/privacy.html" className="me-4">
                    Privacy
                </ExternalLinkView>
                <div className="ms-auto">
                    <EnvironmentFootnote />
                </div>
            </div>
        </div>
    );
}

export default Footer;
