// api
import * as Client from "../../../../../api/Client";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";

export default function LicenceHolderView(props: { vm: Client.GetPublicLicenceDetailsResponseDto | undefined }) {
    const vm = props.vm;
    const licenceHolder = vm?.licenceHolder;

    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    <h2 className="card-title">Licence Holder</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Licence Holder" id="licenceHolderField" value={licenceHolder?.licenceHolder} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="ACN / ARBN" id="licenceHolderField" value={licenceHolder?.acnOrArbn} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
        </>
    );
}
