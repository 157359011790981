// framework
import React from "react";
import { useState, useEffect } from "react";
// kendo
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

// Note: expand as necessary e.g. at present there is no need to externalise the current filter or dynamically sort data
interface IProps<T> {
    id: string;
    value: T | undefined;
    data: T[] | undefined;
    keyField?: string | undefined;
    textField?: string | undefined;
    disabled?: boolean | undefined;
    valid?: boolean | undefined;
    className?: string | undefined;
    filterable?: boolean | undefined;
    suggest?: boolean | undefined;
    allowCustom?: boolean | undefined;
    onChange: (value: T | undefined) => void;
    onBlur?: (() => void) | undefined;
}

export default function KendoComboBox<T>(props: IProps<T>) {
    // value - must be set to null in order to programatically clear the combobox
    const [value, setValue] = useState<T | undefined | null>();
    useEffect(() => {
        setValue(props.value ?? null);
    }, [props.value]);

    // data
    const [data, setData] = useState<T[]>([]);
    useEffect(() => {
        setData(props.data ? props.data : []);
    }, [props.data]);

    // filter
    function onFilterChange(event: ComboBoxFilterChangeEvent) {
        setData(props.data ? filterBy(props.data.slice(), event.filter) : []);
    }

    function onChange(event: ComboBoxChangeEvent) {
        const value = event.target.value;
        setValue(value);
        props.onChange(value ?? undefined);
    }

    return (
        <ComboBox
            id={props.id}
            value={value}
            data={data}
            dataItemKey={props.keyField}
            textField={props.textField}
            disabled={props.disabled}
            valid={props.valid}
            className={props.className}
            filterable={props.filterable}
            suggest={props.suggest}
            allowCustom={props.allowCustom}
            onFilterChange={onFilterChange}
            onChange={onChange}
            onBlur={props.onBlur}
            popupSettings={{ height: 390 }}
        />
    );
}
