// kendo
import { GridColumnMenuFilter, GridColumnMenuProps } from "@progress/kendo-react-grid";

function GridColumnFilterMenu(props: { gridColumnMenuProps: GridColumnMenuProps }) {
    return (
        <div>
            <GridColumnMenuFilter {...props.gridColumnMenuProps} expanded={true} />
        </div>
    );
}

export function createGridColumnFilterMenu(gridColumnMenuProps: GridColumnMenuProps) {
    return <GridColumnFilterMenu gridColumnMenuProps={gridColumnMenuProps} />;
}
