// framework
import { call, put, select } from "redux-saga/effects";
// api
import { createFeedbackClient } from "../../../../api/util";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";
// redux
import * as Actions from "../actions";
import { IFeedbackState } from "../reducers";
// models
import * as Models from "../../models/models";

const getRootViewModel = (state: IFeedbackState) => state.feedback;

export default function* initialise() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    try {
        yield put(setBusyAction());
        const response: Client.GetCreatePublicFeedbackReferenceDataResponseDto = yield call(getCreatePublicFeedbackReferenceDataQuery);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(response)));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function getCreatePublicFeedbackReferenceDataQuery(): Promise<Client.GetCreatePublicFeedbackReferenceDataResponseDto> {
    const client = createFeedbackClient();
    const request = new Client.GetCreatePublicFeedbackReferenceDataRequestDto({});
    const response = await simpleCall(() => client.getCreatePublicFeedbackReferenceData(request));
    return response;
}
