import { LicenceDetailsTabsEnum } from "./components/views/LicenceDetailsView";
import { ILicenceDetailsState } from "./redux/licenceDetailsReducers";
import { Client } from "../../../common/secureFileDownload/client";
import * as uriHelper from "../../../api/uriHelper";

enum AutomationEnum {
    downloadMemorialDocument,
}

export function tryGetPreferredTab(params: URLSearchParams): LicenceDetailsTabsEnum {
    const action = params.get("action");
    if (!action) return LicenceDetailsTabsEnum.summary;

    if (action === AutomationEnum[AutomationEnum.downloadMemorialDocument]) return LicenceDetailsTabsEnum.memorial;

    throw new Error("Unable to resolve action!");
}

export function getOpenLicenceRedirectUri(licenceId: string) {
    return `/Oei/Licence/${licenceId}`;
}

export function getDownloadMemorialDocumentUri(licenceId: string, applicationId: string) {
    return `/Oei/Licence/${licenceId}?action=${AutomationEnum[AutomationEnum.downloadMemorialDocument]}&applicationId=${applicationId}`;
}

export function tryOpenLicenceMemorialDocument(vm: ILicenceDetailsState, params: URLSearchParams): void {
    // determine if there is a relevant automation action
    const action = params.get("action");
    if (!action) return;
    if (action !== AutomationEnum[AutomationEnum.downloadMemorialDocument]) return;

    // get the application id from the action
    // if it is malformed then just return
    const applicationId = params.get("applicationId");
    if (!applicationId) return;

    // get the URL and open
    // - note, need to use window.location.href instead of window.open
    //   window.open gets blocked by the browser
    //   window.location.href would normally navigate the page but it seems to do what we want
    const url = getLicenceMemorialDocumentUrl(vm, applicationId!);
    window.location.href = url;
}

function getLicenceMemorialDocumentUrl(vm: ILicenceDetailsState, applicationId: string): string {
    // licence hasn't been loaded - we shouldn't be here, it's a coding error
    if (!vm.response) throw new Error("Licence has not been loaded.");

    // if we can't find it then return the not found page
    // - this isn't the best experience, but it's only going to happen if a link is broken
    const notFoundUrl = uriHelper.getServicesUri() + "/NotFound";

    // try to find the application
    const applications = vm.response!.memorial?.filter((a) => a.applicationId === applicationId);
    if (!applications) return notFoundUrl;
    if (applications.length === 0) return notFoundUrl;
    // The application will be there twice if it has a correction to the register. The document is the same, take the first one
    const application = applications[0];

    // try to find the file
    const fileId = application.fileId;
    if (!fileId) return notFoundUrl;

    // return the file url
    var client = new Client();
    client.initialise(fileId!);
    return client.getUrl();
}
