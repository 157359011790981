// lifted from https://github.com/Azure-Samples/application-insights-react-demo/blob/master/src/telemetry-provider.jsx

// framework
import { Component, Fragment } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
// redux
import { withHistory } from "../redux/withHistory";
// telemetry
import { ai } from "./TelemetryService";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    state = {
        initialized: false,
    };

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        const connectionString = this.props.connectionString;
        if (!Boolean(initialized) && Boolean(connectionString) && Boolean(history)) {
            ai.initialize(connectionString, history);
            this.setState({ initialized: true });
        }

        if (this.props.after !== undefined) this.props.after();
    }

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

export default withHistory(withAITracking(ai.reactPlugin, TelemetryProvider));
