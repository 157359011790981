import { oeiApplicationTrackingDetailsActions, IOeiApplicationTrackingDetailsActions } from "./oeiApplicationTrackingDetailsActions";
import * as Client from "../../../../api/Client";

// initial state
export interface IOeiApplicationTrackingDetailsState {
    response?: Client.GetPublicOeiApplicationsTrackingDetailsResponseDto | undefined;
}

const initialState: IOeiApplicationTrackingDetailsState = {
    response: undefined,
};

// reducers
export function oeiApplicationTrackingDetailsReducer(state: IOeiApplicationTrackingDetailsState = initialState, action: IOeiApplicationTrackingDetailsActions): IOeiApplicationTrackingDetailsState {
    switch (action.type) {
        case oeiApplicationTrackingDetailsActions.GetOeiApplicationTrackingDetailsRequest:
            return {
                ...state,
                response: undefined,
            };

        case oeiApplicationTrackingDetailsActions.GetOeiApplicationTrackingDetailsResponse:
            return {
                ...state,
                response: action.response,
            };

        case oeiApplicationTrackingDetailsActions.ClearOeiApplicationTrackingDetails:
            return {
                ...state,
                response: undefined,
            };

        default:
            return state;
    }
}
