import * as Client from "../../../../api/Client";

export enum oeiApplicationTrackingActions {
    GetOeiApplicationTrackingRequest = "OEI_APPLICATION_TRACKING.REQUEST",
    GetOeiApplicationTrackingResponse = "OEI_APPLICATION_TRACKING.RESPONSE",
}

export interface IOeiApplicationTrackingActions {
    type: oeiApplicationTrackingActions;
    isCompleted?: boolean | undefined;
    applicationTrackingType?: Client.OeiApplicationTrackingTypeEnum | undefined;
    response?: Client.GetPublicOeiApplicationsTrackingResponseDto | undefined;
}

export function getOeiApplicationTrackingRequestAction(isCompleted: boolean, applicationTrackingType: Client.OeiApplicationTrackingTypeEnum | undefined): IOeiApplicationTrackingActions {
    return {
        type: oeiApplicationTrackingActions.GetOeiApplicationTrackingRequest,
        isCompleted: isCompleted,
        applicationTrackingType: applicationTrackingType,
    };
}

export function getOeiApplicationTrackingResponseAction(response: Client.GetPublicOeiApplicationsTrackingResponseDto): IOeiApplicationTrackingActions {
    return {
        type: oeiApplicationTrackingActions.GetOeiApplicationTrackingResponse,
        response: response,
    };
}
