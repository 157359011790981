// api
import * as Client from "../../../../../api/Client";
// children views
import ModernWorkProgramView from "./ModernWorkProgramView";

function WorkProgramView(props: { response: Client.GetPublicTitleDetailsResponseDto | undefined }) {
    const title2SubType = props.response?.title2SubType;
    const modern = props.response?.workProgram;

    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    <h2 className="card-title">Work Program</h2>
                    {modern ? (
                        <ModernWorkProgramView workProgram={modern} />
                    ) : title2SubType === Client.Title2SubTypeEnum.CashBid ? (
                        props.response?.title2SubTypeTypeName + " Title - this Title does not have a Work Program."
                    ) : (
                        "This Title does not have a Work Program."
                    )}
                </div>
            </div>
        </>
    );
}

export default WorkProgramView;
