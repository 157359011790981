// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// api
import * as Client from "../../../../../api/Client";
// children views
import LicenceSummaryView from "./LicenceSummaryView";
import LicenceHolderView from "./LicenceHolderView";
import MemorialView from "./MemorialView";

export enum LicenceDetailsTabsEnum {
    summary,
    licenceHolder,
    memorial,
}

function LicenceDetailsView(props: { response: Client.GetPublicLicenceDetailsResponseDto | undefined; preferredTab?: LicenceDetailsTabsEnum }) {
    const response = props.response;
    const preferredTab = props.preferredTab ?? LicenceDetailsTabsEnum.summary;

    return (
        <Tabs defaultActiveKey={preferredTab.toString()} variant="pills" mountOnEnter>
            {/* Summary Tab */}
            <Tab eventKey={LicenceDetailsTabsEnum.summary.toString()} title="Licence Summary">
                <LicenceSummaryView vm={response} />
            </Tab>

            {/* Licenceholders Tab */}
            <Tab eventKey={LicenceDetailsTabsEnum.licenceHolder.toString()} title="Licence Holder">
                <LicenceHolderView vm={response} />
            </Tab>

            {/* Memorial Tab */}
            <Tab eventKey={LicenceDetailsTabsEnum.memorial.toString()} title="Register">
                <MemorialView response={response} />
            </Tab>
        </Tabs>
    );
}

export default LicenceDetailsView;
