import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../redux/shellActions";

function Disclaimer(props: any) {
    const { setLayout } = props;
    // initial load
    useEffect(() => {
        setLayout("Disclaimer");
    }, [setLayout]);

    return (
        <>
            <strong>Disclaimer General</strong>

            <p>
                By entering the site, you will be assuming all risks associated with the use of this site, including risks to your computer, software or data by any virus which might be transmitted,
                downloaded or activated via this or an external website and/or its contents.
            </p>

            <p>
                The National Offshore Petroleum Titles Administrator and Offshore Infrastructure Registrar have no direct control over the content of any linked websites, or the changes that may occur
                to the content on those websites. Links to external websites are provided in good faith, but it is the responsibility of the user to make their own decisions about the accuracy,
                currency, reliability and correctness of information contained in linked external websites. Links to external websites do not constitute an endorsement or a recommendation of any
                material on those websites or of any third party products or services offered by, from or through those websites. Users of links provided by this website are responsible for being
                aware of which organisation is hosting the website they visit.
            </p>

            <p>We welcome links to this site. However, we do not permit the display of pages from this site within frames on another site.</p>

            <p>The Commonwealth of Australia, represented by the Department of Industry, Science and Resources, hereby excludes all liability to the extent permissible by law.</p>

            <strong>
                <i>Offshore Petroleum and Greenhouse Gas Storage Act 2006 (Cth)</i>
            </strong>

            <p>
                For users seeking information relating to title matters under the <i>Offshore Petroleum and Greenhouse Gas Storage Act 2006</i>, while we make every effort to ensure that material on
                this NEATS website is accurate and up to date, such material in no way constitutes the provision of professional advice. The Commonwealth does not guarantee, and accepts no legal
                liability whatsoever arising from or connected to, the accuracy, reliability, currency or completeness of any material contained on this website or any linked site. Users should refer
                to the official (paper) Register kept under section 469 of the <i>Offshore Petroleum and Greenhouse Gas Storage Act 2006</i> (Cth) and seek appropriate independent professional advice
                prior to relying on, or entering into any commitment based on material published here, which material is published online for ease of reference purposes only.
            </p>
            <p>
                By entering the site, you will be deemed to have released and discharged the Commonwealth of Australia, acting through the National Offshore Petroleum Titles Administrator and the
                ​Department of Industry, Science and Resources, from all liability in respect of any loss suffered as a result of relying on the information on this site.
            </p>

            <strong>
                <i>Offshore Electricity Infrastructure Act 2021 (Cth)</i>
            </strong>

            <p>
                Under section 162 of the <i>Offshore Electricity Infrastructure Act 2021</i> (Cth) <strong>(the OEI Act)</strong> the Offshore Infrastructure Registrar must keep a Register of Offshore
                Infrastructure Licences and make the Register available for inspection on the internet. This Register is contained within the NEATS website and includes matters required or authorised
                to be entered in the Register under Chapter 5, Part 1 Division 3 of the OEI Act.
            </p>
        </>
    );
}

// connect redux
export default connect((state: any) => ({}), { setLayout })(Disclaimer);
