// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { IGetWellActions, getWellActions, getWellResponseAction, getReferenceDataResponseAction, getWellExportResponseAction } from "./actions";
// api
import { createWellClient } from "../../../api/util";
import { simpleCall } from "../../../api/callHelper";
import * as Client from "../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(getWellActions.GetWellRequest, workerWellSaga);
    yield takeEvery(getWellActions.GetReferenceDataRequest, workerReferenceDataSaga);
    yield takeEvery(getWellActions.GetWellExportRequest, workerExportSaga);
}

function* workerWellSaga(action: IGetWellActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicWellListItemDto[] = yield call(wellQuery, action!);
        // post response
        yield put(getWellResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function wellQuery(action: IGetWellActions): Promise<Client.GetPublicWellListItemDto[]> {
    const client = createWellClient();

    const request = new Client.GetPublicWellListRequestDto({
        filterName: action.filterName,
        filterTitleId: action.filterTitleId?.length === 0 ? undefined : action.filterTitleId,
        filterJurisdictionId: action.filterJurisdictionId === 0 ? undefined : action.filterJurisdictionId,
        filterBasinId: action.filterBasinId === 0 ? undefined : action.filterBasinId,
        filterTitleholderId: action.filterTitleholderId === 0 ? undefined : action.filterTitleholderId,
        filterFromSpudDate: action.filterFromSpudDate,
        filterToSpudDate: action.filterToSpudDate,
    });

    const response = await simpleCall(() => client.getPublicWellList(request));

    return response.items!;
}

function* workerReferenceDataSaga(action: IGetWellActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicWellReferenceDataResponseDto = yield call(relatedDataQuery);

        // post response
        yield put(getReferenceDataResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function relatedDataQuery(): Promise<Client.GetPublicWellReferenceDataResponseDto> {
    const client = createWellClient();

    const request = new Client.GetPublicWellReferenceDataRequestDto({});

    const response = await simpleCall(() => client.getPublicWellReferenceDataList(request));
    return response;
}

function* workerExportSaga(action: IGetWellActions) {
    try {
        yield put(setBusyAction());
        const response: Client.GetPublicWellListExportResponseDto = yield call(exportQuery, action!);

        yield put(getWellExportResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function exportQuery(action: IGetWellActions): Promise<Client.GetPublicWellListExportResponseDto> {
    const client = createWellClient();

    const request = new Client.GetPublicWellListExportRequestDto({
        filterName: action.filterName,
        filterTitleId: action.filterTitleId?.length === 0 ? undefined : action.filterTitleId,
        filterJurisdictionId: action.filterJurisdictionId === 0 ? undefined : action.filterJurisdictionId,
        filterBasinId: action.filterBasinId === 0 ? undefined : action.filterBasinId,
        filterTitleholderId: action.filterTitleholderId === 0 ? undefined : action.filterTitleholderId,
        filterFromSpudDate: action.filterFromSpudDate,
        filterToSpudDate: action.filterToSpudDate,
    });

    const response = await simpleCall(() => client.getPublicWellExportList(request));

    return response;
}
