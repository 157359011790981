import * as TelemetryService from "../infrastructure/TelemetryService";
import * as toastHelper from "./toastHelper";

// provides AppInsights + Console logging

export function logError(message: any) {
    console.error(message);
    TelemetryService.logError(message);

    const environment = process.env.REACT_APP_CONFIGURATION_ENVIRONMENT as string;
    if (environment === "Development") {
        toastHelper.showDevelopmentUnexpectedExceptionNotification();
    }
}

export function logTrace(message: any) {
    console.debug(message);
    TelemetryService.logTrace(message);
}
