// framework
import { useEffect, useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { filterBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../api/Client";
// other
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import { orderBy } from "lodash";
import { Collapse } from "react-bootstrap";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import WellLinkView from "../../../common/links/WellLinkView";

type WellListViewProps = {
    filters: WellListViewFilters;
    reference: WellListViewReferenceData;
    wells: Client.GetPublicWellListItemDto[];
    onSearchChanged: (nameValue: string, titleValue: string, titleholderId?: number, filterBasinId?: number, filterStateTerritoryId?: number, spudDateFromValue?: Date, spudDateToValue?: Date) => void;
    onWellsExport: (nameValue: string, titleValue: string, titleholderId: number, filterBasinId: number, filterStateTerritoryId: number, spudDateFromValue: Date, spudDateToValue: Date) => void;
};

export type WellListViewFilters = {
    name: string;
    titleId: string | null;
    basinId?: number | null;
    filterJurisdictionId?: number | null;
    titleholderId?: number | null;
    fromSpudDate?: Date | null;
    toSpudDate?: Date | null;
};

export type WellListViewReferenceData = {
    titles: Client.PublicWellTitleDto[];
    basins: Client.PublicWellBasinDto[];
    states: Client.PublicWellStateTerritoryDto[];
    titleholders: Client.PublicWellTitleholderDto[];
};

function WellListView(props: WellListViewProps) {
    const initialGridState = {
        sort: [{ field: "kickOffDate", dir: "desc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    const [show, setShow] = useState(false);
    const [searchWell, setSearchWell] = useState(props.filters.name);
    const [searchTitle, setSearchTitle] = useState(props.filters.titleId);
    const [searchBasin, setSearchBasin] = useState(props.filters.basinId);
    const [searchState, setSearchStateTerritory] = useState(props.filters.filterJurisdictionId);
    const [searchTitleholder, setSearchTitleholder] = useState(props.filters.titleholderId);
    const [searchSpudDateFrom, setSearchSpudDateFrom] = useState(props.filters.fromSpudDate);
    const [searchSpudDateTo, setSearchSpudDateTo] = useState(props.filters.toSpudDate);

    const defaultSelectedState = new Client.PublicWellStateTerritoryDto({ name: "All", jurisdictionId: 0 });
    const sortedStates = orderBy(props.reference.states.concat(defaultSelectedState).slice(), [(state) => state.name!.toLowerCase()], ["asc"]);

    const [sortedTitles, setSortedTitles] = useState(orderBy(props.reference.titles, [(title) => title.name!.toLowerCase()], ["asc"]));
    const [sortedBasins, setSortedBasins] = useState(orderBy(props.reference.basins, [(basin) => basin.name!.toLowerCase()], ["asc"]));
    const [sortedTitleholders, setSortedTitleholders] = useState(orderBy(props.reference.titleholders, [(titleholder) => titleholder.name!.toLowerCase()], ["asc"]));

    const [selectedTitle, setSelectedTitle] = useState<Client.PublicWellTitleDto | null>(props.reference.titles.find((t) => t.titleId === props.filters.titleId, null)!);
    const [selectedState, setSelectedState] = useState(props.reference.states.find((s) => s.jurisdictionId === props.filters.filterJurisdictionId, defaultSelectedState));
    const [selectedBasin, setSelectedBasin] = useState<Client.PublicWellBasinDto | null>(props.reference.basins.find((t) => t.basinId === props.filters.basinId, null)!);
    const [selectedTitleholder, setSelectedTitleholder] = useState<Client.PublicWellTitleholderDto | null>(
        props.reference.titleholders.find((th) => th.titleholderId === props.filters.titleholderId, null)!
    );

    // local handlers
    function onSearchWellChanged(e: any) {
        setSearchWell(e.target.value);
    }

    function onSearchTitleChanged(e: ComboBoxChangeEvent) {
        setSelectedTitle(e.target.value);
        setSearchTitle(e.target.value ? e.target.value!.titleId : 0);
    }

    function onFilterTitleChanged(event: ComboBoxFilterChangeEvent) {
        const filteredSortedTitles = orderBy(props.reference.titles, ["name"], ["asc"]);
        setSortedTitles(filterBy(filteredSortedTitles, event.filter));
    }

    function onSearchStateChanged(e: DropDownListChangeEvent) {
        setSelectedState(e.target.value);
        setSearchStateTerritory(e.target.value ? e.target.value!.jurisdictionId : 0);
    }

    function onSearchBasinChanged(e: ComboBoxChangeEvent) {
        setSelectedBasin(e.target.value);
        setSearchBasin(e.target.value ? e.target.value!.basinId : null);
    }

    function onFilterBasinChanged(e: ComboBoxFilterChangeEvent) {
        const filteredSortedBasins = orderBy(props.reference.basins, ["name"], ["asc"]);
        setSortedBasins(filterBy(filteredSortedBasins, e.filter));
    }

    function onSearchTitleholderChanged(e: ComboBoxChangeEvent) {
        setSelectedTitleholder(e.target.value);
        setSearchTitleholder(e.target.value ? e.target.value!.titleholderId : 0);
    }

    function onFilterTitleholderChanged(e: ComboBoxFilterChangeEvent) {
        const filteredSortedTitleholders = orderBy(props.reference.titleholders, ["name"], ["asc"]);
        setSortedTitleholders(filterBy(filteredSortedTitleholders, e.filter));
    }

    function onSearchSpudDateFromChanged(e: DatePickerChangeEvent) {
        setSearchSpudDateFrom(e.value ?? undefined);
    }

    function onSearchSpudDateToChanged(e: DatePickerChangeEvent) {
        setSearchSpudDateTo(e.value ?? undefined);
    }

    function onSearchSubmit(e: any) {
        e.preventDefault(); // stops posting

        props.onSearchChanged(searchWell!, searchTitle!, searchBasin!, searchState!, searchTitleholder!, searchSpudDateFrom!, searchSpudDateTo!);
        setGridState(initialGridState);
    }

    function onAdvancedClicked() {
        setShow(!show);

        // Reset all advanced search filters
        if (show) {
            props.onSearchChanged("", "", undefined, undefined, undefined, undefined, undefined);
            clearAllAdvancedSearchFields();
        }
    }

    function onResetSearchClicked() {
        props.onSearchChanged("", "", undefined, undefined, undefined, undefined, undefined);
        setGridState(initialGridState);
        setSearchWell("");
        clearAllAdvancedSearchFields();
    }

    function clearAllAdvancedSearchFields() {
        setSelectedTitle(null);
        setSearchTitle("");
        setSearchStateTerritory(0);
        setSelectedState(defaultSelectedState);
        setSearchBasin(null);
        setSelectedBasin(null);
        setSearchTitleholder(null);
        setSelectedTitleholder(null);
        setSearchSpudDateFrom(null);
        setSearchSpudDateTo(null);
    }

    const excelExport = () => {
        props.onWellsExport(searchWell!, searchTitle!, searchBasin!, searchState!, searchTitleholder!, searchSpudDateFrom!, searchSpudDateTo!);
    };

    useEffect(() => {
        setSortedTitles(orderBy(props.reference.titles, [(title) => title.name!.toLowerCase()], ["asc"]));
        setSortedTitleholders(orderBy(props.reference.titleholders, [(titleholder) => titleholder.name!.toLowerCase()], ["asc"]));
        setSortedBasins(orderBy(props.reference.basins, [(basin) => basin.name!.toLowerCase()], ["asc"]));
    }, [setSortedTitles, setSortedTitleholders, setSortedBasins, props.reference]);

    // view
    return (
        <>
            {/* search box */}
            <form onSubmit={onSearchSubmit} className="form">
                <div className="row">
                    <div className="my-2">
                        <label htmlFor="searchText">Well/Borehole Name</label>
                        <input id="searchText" className="form-control" type="text" maxLength={100} value={searchWell} onChange={onSearchWellChanged} />
                    </div>
                </div>

                <Collapse in={show}>
                    <div className="container-fluid border rounded p-3">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <label htmlFor="title">Title</label>
                                <ComboBox
                                    id="title"
                                    data={sortedTitles}
                                    value={selectedTitle}
                                    dataItemKey="titleId"
                                    onChange={onSearchTitleChanged}
                                    textField="name"
                                    filterable={true}
                                    onFilterChange={onFilterTitleChanged}
                                    suggest={true}
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <label htmlFor="state">State</label>
                                <DropDownList
                                    title="State"
                                    id="state"
                                    data={sortedStates}
                                    defaultValue={defaultSelectedState}
                                    value={selectedState}
                                    dataItemKey="jurisdictionId"
                                    onChange={onSearchStateChanged}
                                    textField="name"
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <label htmlFor="basin">Basin</label>
                                <ComboBox
                                    id="basin"
                                    data={sortedBasins}
                                    value={selectedBasin}
                                    dataItemKey="basinId"
                                    onChange={onSearchBasinChanged}
                                    textField="name"
                                    filterable={true}
                                    onFilterChange={onFilterBasinChanged}
                                    suggest={true}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <label htmlFor="titleholder">Titleholder</label>
                                <ComboBox
                                    id="titleholder"
                                    data={sortedTitleholders}
                                    value={selectedTitleholder}
                                    dataItemKey="titleholderId"
                                    onChange={onSearchTitleholderChanged}
                                    textField="name"
                                    filterable={true}
                                    onFilterChange={onFilterTitleholderChanged}
                                    suggest={true}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <label htmlFor="spudDateFrom">Spud Date From</label>
                                <DatePicker id="spudDateFrom" value={searchSpudDateFrom} onChange={onSearchSpudDateFromChanged} max={searchSpudDateTo ?? undefined} />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <label htmlFor="spudDateTo">Spud Date To</label>
                                <DatePicker id="spudDateTo" value={searchSpudDateTo} onChange={onSearchSpudDateToChanged} min={searchSpudDateFrom ?? undefined} />
                            </div>
                        </div>
                    </div>
                </Collapse>

                <div className="row">
                    <div className="my-2">
                        <button id="search" className="btn btn-outline-primary me-1" type="submit">
                            Search
                        </button>
                        <button id="advanced" className="btn btn-outline-secondary me-1" type="button" onClick={onAdvancedClicked}>
                            Advanced
                        </button>
                        <button id="reset" className="btn btn-outline-secondary" type="button" onClick={onResetSearchClicked}>
                            Reset
                        </button>
                    </div>
                </div>
            </form>

            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.wells, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="wellName"
                    title="Well"
                    width={180}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <WellLinkView wellId={props.dataItem.id} wellName={props.dataItem.wellName} />
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)}
                />
                <GridColumn field="uwi" title="UWI" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="boreholeName" title="Borehole" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="state" title="State" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="jurisdiction" title="Jurisdiction" width={170} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="basin" title="Basin" width={140} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="legislation" title="Legislation" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="titleNumber" title="Title" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="titleholderCompanyName" title="Titleholder" width={220} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="boreholePurpose" title="Borehole Purpose" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="rigContractor" title="Drilling Company" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="rig" title="Rig" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
                <GridColumn field="kickOffDate" title="Kick Off Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="rigReleaseDate" title="Rig Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="wellStatus" title="Well Status" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.wells)} />
            </Grid>

            <div className="container-fluid p-2 form">
                <div className="row">
                    <div className="col-auto">
                        <button title="Export Excel" className="m-1 k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={excelExport}>
                            Export to Excel
                        </button>
                    </div>
                    <div className="col m-auto p-2">
                        <label>This table displays details for the top 1,000 records per search. The export will download details for all records for that search.</label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WellListView;
