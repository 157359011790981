// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { getOpggsApplicationTrackingRequestAction } from "../redux/opggsApplicationTrackingActions";
// children
import OpggsApplicationTrackingListView from "./OpggsApplicationTrackingListView";
import { IApplicationTrackingState } from "../redux/opggsApplicationTrackingReducers";
import * as Client from "../../../../api/Client";

function OpggsApplicationTrackingController(props: any) {
    const { setLayout, getOpggsApplicationTrackingRequestAction } = props;

    const state: IApplicationTrackingState = props.state;
    const isCompleted = state.isCompleted ?? false;
    const applicationTrackingType = state.applicationTrackingType;
    // Application Tracking
    const response = state.response;
    const applicationTrackingTypes = response?.applicationTrackingTypes ?? new Array<Client.GetPublicApplicationsTrackingTrackingTypeDto>();
    const typeSummaryItems = response?.applicationTrackingTypeSummary ?? new Array<Client.GetPublicApplicationsTrackingTrackingTypeSummaryItemDto>();
    const statusSummaryItems = response?.applicationTrackingStatusSummary ?? new Array<Client.GetPublicApplicationsTrackingTrackingStatusSummaryItemDto>();
    const listItems = response?.applicationList ?? new Array<Client.GetPublicApplicationsTrackingApplicationListItemDto>();

    // initial load
    useEffect(() => {
        setLayout("OPGGS Application Tracking");
        getOpggsApplicationTrackingRequestAction(isCompleted, applicationTrackingType);
    }, [setLayout, getOpggsApplicationTrackingRequestAction, isCompleted, applicationTrackingType]);

    // change
    function onFormSubmit(isCompleted: boolean, applicationTrackingType: Client.OpggsApplicationTrackingTypeEnum | undefined) {
        getOpggsApplicationTrackingRequestAction(isCompleted, applicationTrackingType);
    }

    // return
    return (
        <OpggsApplicationTrackingListView
            isCompleted={isCompleted}
            applicationTrackingType={applicationTrackingType}
            applicationTrackingTypes={applicationTrackingTypes}
            typeSummaryItems={typeSummaryItems}
            statusSummaryItems={statusSummaryItems}
            listItems={listItems}
            onFormSubmit={onFormSubmit}
        />
    );
}

// connect redux
export default connect((state: any) => ({ state: state.applicationTracking }), { setLayout, getOpggsApplicationTrackingRequestAction })(OpggsApplicationTrackingController);
