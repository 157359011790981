// framework
import React, { useState, useEffect } from "react";
// models
import { IAlertsModel } from "./AlertsModel";
import * as AlertsHelper from "./AlertsHelper";

/*
    Limitations:
    - currently renders content as plain text only.
    - the most likely future requirement will be to show hyperlinks, as anchor tags.  if this is the case we need to build a simple parser.
      we must not render HTML directly from the service as this is dangerous.
*/

export default function AlertsView(props: { alertsModel: IAlertsModel; alertsRef?: React.RefObject<HTMLDivElement> | undefined }) {
    const m = props.alertsModel;

    const [showErrors, setShowErrors] = useState(true);
    const [showSuccess, setShowSuccess] = useState(true);
    const [showWarning, setShowWarning] = useState(true);
    const [showInformation, setShowInformation] = useState(true);

    // show the alerts again if the arrays change
    useEffect(() => {
        setShowErrors(true);
    }, [m.error]);
    useEffect(() => {
        setShowSuccess(true);
    }, [m.success]);
    useEffect(() => {
        setShowWarning(true);
    }, [m.warning]);
    useEffect(() => {
        setShowInformation(true);
    }, [m.information]);

    return (
        <div className="mb-2" ref={props.alertsRef} tabIndex={-1}>
            {showErrors && AlertsHelper.renderError(m.error, () => setShowErrors(false))}
            {showSuccess && AlertsHelper.renderSuccess(m.success, () => setShowSuccess(false))}
            {showWarning && AlertsHelper.renderWarning(m.warning, () => setShowWarning(false))}
            {showInformation && AlertsHelper.renderInfo(m.information, () => setShowInformation(false))}
        </div>
    );
}
