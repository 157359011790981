// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function BlocksView(props: { blocks: Client.GetPublicTitleDetailsBlockDto[]; areaSqKm: number | undefined; showLocations: boolean }) {
    const blocks = props.blocks;
    const areaSqKm = props.areaSqKm;
    const showLocationName = props.showLocations;

    const [gridState, setGridState] = useState({
        sort: [
            { field: "mapSheetCode", dir: "asc" },
            { field: "blockNumber", dir: "asc" },
        ],
    } as State);

    return (
        <div className="card my-2">
            <div className="card-body">
                <Forms.Row>
                    <Forms.QuarterColumn>
                        <Forms.ReadonlyField label="Number of Blocks" id="numberOfBlocksField" value={GlobalHelpers.toNoptaIntegerString(blocks.length)} />
                    </Forms.QuarterColumn>
                    <Forms.QuarterColumn>
                        <Forms.ReadonlyField label="Area of Blocks" id="areaOfBlocksField" value={GlobalHelpers.toNoptaNumberString(areaSqKm, "n2", "km²")} />
                    </Forms.QuarterColumn>
                </Forms.Row>
                {/* blocks grid */}
                <Grid
                    sortable
                    resizable
                    navigatable
                    className="mt-4"
                    data={process(blocks, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn field="mapSheetCode" title="Map Sheet Code" width={200} />
                    <GridColumn field="mapSheetName" title="Map Sheet Name" width={300} />
                    <GridColumn field="blockNumber" title="Block Number" width={200} />
                    <GridColumn field="isPartBlock" title="Part Block" width={125} />
                    {showLocationName && <GridColumn field="locationName" title="Location Name" width={300} />}
                </Grid>
            </div>
        </div>
    );
}

export default BlocksView;
