// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// shell
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { clearOeiApplicationTrackingDetailsAction, getOeiApplicationTrackingDetailsRequestAction } from "../redux/oeiApplicationTrackingDetailsActions";
// children views
import OeiApplicationTrackingDetailsView from "./OeiApplicationTrackingDetailsView";
import { IOeiApplicationTrackingDetailsState } from "../redux/oeiApplicationTrackingDetailsReducers";

interface IRouteParameters {
    applicationId: string;
}

function OeiApplicationTrackingDetailsController(props: any) {
    const { applicationId } = useParams<IRouteParameters>();
    const { setLayout, getOeiApplicationTrackingDetailsRequestAction, clearOeiApplicationTrackingDetailsAction } = props;
    const state: IOeiApplicationTrackingDetailsState = props.state;

    // Application Tracking Details
    const response = state.response;

    // - initial load
    useEffect(() => {
        getOeiApplicationTrackingDetailsRequestAction(applicationId);

        return function onUnmount() {
            clearOeiApplicationTrackingDetailsAction();
        };
    }, [getOeiApplicationTrackingDetailsRequestAction, clearOeiApplicationTrackingDetailsAction, applicationId]);

    // - set page header when response received
    useEffect(() => {
        if (!response) {
            setLayout("");
            return;
        }

        setLayout(response.licenceNumber + " - " + response.applicationTypeName);
    }, [setLayout, response]);

    // return
    return <OeiApplicationTrackingDetailsView response={response} />;
}

// connect redux
export default connect((state: any) => ({ state: state.oeiApplicationTrackingDetails }), { setLayout, getOeiApplicationTrackingDetailsRequestAction, clearOeiApplicationTrackingDetailsAction })(
    OeiApplicationTrackingDetailsController
);
