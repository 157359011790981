import { oeiApplicationTrackingActions, IOeiApplicationTrackingActions } from "./oeiApplicationTrackingActions";
import * as Client from "../../../../api/Client";

// initial state
export interface IOeiApplicationTrackingState {
    isCompleted?: boolean | undefined;
    applicationTrackingType?: Client.OeiApplicationTrackingTypeEnum | undefined;
    response?: Client.GetPublicOeiApplicationsTrackingResponseDto | undefined;
}

const initialState: IOeiApplicationTrackingState = {
    isCompleted: false,
    applicationTrackingType: undefined,
    response: undefined,
};

// reducers
export function oeiApplicationTrackingReducer(state: IOeiApplicationTrackingState = initialState, action: IOeiApplicationTrackingActions): IOeiApplicationTrackingState {
    switch (action.type) {
        case oeiApplicationTrackingActions.GetOeiApplicationTrackingRequest:
            return {
                ...state,
                isCompleted: action.isCompleted,
                applicationTrackingType: action.applicationTrackingType,
                response: undefined,
            };

        case oeiApplicationTrackingActions.GetOeiApplicationTrackingResponse:
            return {
                ...state,
                response: action.response,
            };

        default:
            return state;
    }
}
