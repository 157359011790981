// framework
import { Link } from "react-router-dom";

export default function OpggsApplicationLinkView(props: { applicationId: string; applicationName: string | undefined }) {
    return (
        <Link to={"/Opggs/ApplicationTracking/" + props.applicationId} title="Click to view Application details.">
            {props.applicationName}
        </Link>
    );
}
