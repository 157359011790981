import * as Client from "../../../../api/Client";

export enum titleDetailsActions {
    GetTitleDetailsRequest = "OPGGS_TITLE_DETAILS.REQUEST",
    GetTitleDetailsResponse = "OPGGS_TITLE_DETAILS.RESPONSE",
    ClearTitleDetails = "OPGGS_TITLE_DETAILS.CLEAR",
    GetTitleMapDetailsRequest = "OPGGS_TITLE_MAP_DETAILS.REQUEST",
    GetTitleMapDetailsResponse = "OPGGS_TITLE_MAP_DETAILS.RESPONSE",
    ClearTitleMapDetails = "OPGGS_TITLE_MAP_DETAILS.CLEAR",
    ClearTitleApplicationDocument = "OPGGS_TITLE_APPLICATION_DOCUMENT.CLEAR",
}

export interface ITitleDetailsActions {
    type: titleDetailsActions;
    titleId?: string | undefined;
    applicationId?: string | undefined;
    detailsResponse?: Client.GetPublicTitleDetailsResponseDto | undefined;
    mapResponse?: Client.GetPublicTitleDetailsMapResponseDto | undefined;
}

// Title Details
export function getTitleDetailsRequestAction(titleId: string): ITitleDetailsActions {
    return {
        type: titleDetailsActions.GetTitleDetailsRequest,
        titleId: titleId,
    };
}

export function getTitleDetailsResponseAction(response: Client.GetPublicTitleDetailsResponseDto): ITitleDetailsActions {
    return {
        type: titleDetailsActions.GetTitleDetailsResponse,
        detailsResponse: response,
    };
}

export function clearTitleDetailsAction(): ITitleDetailsActions {
    return {
        type: titleDetailsActions.ClearTitleDetails,
    };
}

// Title Map
export function getTitleMapDetailsRequestAction(titleId: string): ITitleDetailsActions {
    return {
        type: titleDetailsActions.GetTitleMapDetailsRequest,
        titleId: titleId,
    };
}

export function getTitleMapDetailsResponseAction(response: Client.GetPublicTitleDetailsMapResponseDto): ITitleDetailsActions {
    return {
        type: titleDetailsActions.GetTitleMapDetailsResponse,
        mapResponse: response,
    };
}

export function clearTitleMapDetailsAction(): ITitleDetailsActions {
    return {
        type: titleDetailsActions.ClearTitleMapDetails,
    };
}
