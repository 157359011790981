// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { searchLicenceRequestAction } from "../redux/licenceSearchActions";
// children
import LicenceSearchListView from "./LicenceSearchListView";
import { ILicenceSearchState } from "../redux/licenceSearchReducers";
import * as Client from "../../../../api/Client";

function LicenceSearchController(props: any) {
    const { setLayout, searchLicenceRequestAction } = props;

    const state: ILicenceSearchState = props.state;
    const filter = state.filter ?? "";
    const licences = state.licences ?? new Array<Client.GetPublicLicencesListItemDto>();

    // initial load
    useEffect(() => {
        setLayout("Licence Search");
        searchLicenceRequestAction(filter);
    }, [setLayout, searchLicenceRequestAction, filter]);

    // change
    function searchTextChanged(value: string) {
        searchLicenceRequestAction(value);
    }

    return <LicenceSearchListView licences={licences} searchText={filter} onSearchTextChanged={searchTextChanged} />;
}

// connect redux
export default connect((state: any) => ({ state: state.licenceSearch }), { setLayout, searchLicenceRequestAction })(LicenceSearchController);
