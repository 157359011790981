import * as Client from "../../../../api/Client";

export enum applicationTrackingActions {
    GetApplicationTrackingRequest = "OPGGS_APPLICATION_TRACKING.REQUEST",
    GetApplicationTrackingResponse = "OPGGS_APPLICATION_TRACKING.RESPONSE",
}

export interface IApplicationTrackingActions {
    type: applicationTrackingActions;
    isCompleted?: boolean | undefined;
    applicationTrackingType?: Client.OpggsApplicationTrackingTypeEnum | undefined;
    response?: Client.GetPublicOpggsApplicationsTrackingResponseDto | undefined;
}

export function getOpggsApplicationTrackingRequestAction(isCompleted: boolean, applicationTrackingType: Client.OpggsApplicationTrackingTypeEnum | undefined): IApplicationTrackingActions {
    return {
        type: applicationTrackingActions.GetApplicationTrackingRequest,
        isCompleted: isCompleted,
        applicationTrackingType: applicationTrackingType,
    };
}

export function getOpggsApplicationTrackingResponseAction(response: Client.GetPublicOpggsApplicationsTrackingResponseDto): IApplicationTrackingActions {
    return {
        type: applicationTrackingActions.GetApplicationTrackingResponse,
        response: response,
    };
}
