// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../shell/redux/shellActions";
// api
import { clearWellExportResponseAction, getReferenceDataRequestAction, getWellExportRequestAction, getWellRequestAction } from "../redux/actions";
import * as Client from "../../../api/Client";
// children
import WellListView, { WellListViewFilters, WellListViewReferenceData } from "./WellListView";
import { IGetWellState } from "../redux/reducers";
// helpers
import * as documentHelper from "../../../common/documentHelper";

function Controller(props: any) {
    const { setLayout, getReferenceDataRequestAction, getWellRequestAction, getWellExportRequestAction, clearWellExportResponseAction } = props;

    const state: IGetWellState = props.state;
    const filterName = state.filterName ?? "";
    const filterTitleId = state.filterTitleId ?? "";
    const filterBasinId = state.filterBasinId;
    const filterJurisdictionId = state.filterJurisdictionId;
    const filterTitleholderId = state.filterTitleholderId;
    const filterFromSpudDate = state.filterFromSpudDate;
    const filterToSpudDate = state.filterToSpudDate;

    const wells = state.wells ?? new Array<Client.GetPublicWellListItemDto>();
    const titles = state.titles ?? new Array<Client.PublicWellTitleDto>();
    const basins = state.basins ?? new Array<Client.PublicWellBasinDto>();
    const states = state.states ?? Array<Client.PublicWellStateTerritoryDto>();
    const titleholders = state.titleholders ?? Array<Client.PublicWellTitleholderDto>();

    // initial load
    useEffect(() => {
        setLayout("Wells and Boreholes | NOPIMS", false);
        getReferenceDataRequestAction();
        getWellRequestAction(filterName, filterTitleId, filterBasinId, filterJurisdictionId, filterTitleholderId, filterFromSpudDate, filterToSpudDate);
    }, [setLayout, getReferenceDataRequestAction, getWellRequestAction, filterName, filterTitleId, filterBasinId, filterJurisdictionId, filterTitleholderId, filterFromSpudDate, filterToSpudDate]);

    const exportResponse = state.exportResponse;

    function getExportWells(nameValue: string, titleValue: string, filterBasinId: number, filterJurisdictionId: number, titleholderId: number, spudDateFromValue: Date, spudDateToValue: Date) {
        getWellExportRequestAction(nameValue, titleValue, filterBasinId, filterJurisdictionId, titleholderId, spudDateFromValue, spudDateToValue);
    }

    // - download when response received
    useEffect(() => {
        if (exportResponse) {
            documentHelper.download(exportResponse.fileName!, exportResponse.mimeType!, exportResponse.content!);
            // clear content after download
            return function () {
                clearWellExportResponseAction();
            };
        }
    }, [exportResponse, clearWellExportResponseAction]);

    // change
    function searchChanged(nameValue: string, titleValue: string, filterBasinId?: number, filterJurisdictionId?: number, titleholderId?: number, spudDateFromValue?: Date, spudDateToValue?: Date) {
        getWellRequestAction(nameValue, titleValue, filterBasinId, filterJurisdictionId, titleholderId, spudDateFromValue, spudDateToValue);
    }

    const filters: WellListViewFilters = {
        name: filterName,
        titleId: filterTitleId,
        basinId: filterBasinId,
        filterJurisdictionId: filterJurisdictionId,
        titleholderId: filterTitleholderId,
        toSpudDate: filterToSpudDate,
        fromSpudDate: filterFromSpudDate,
    };

    const reference: WellListViewReferenceData = {
        titles: titles,
        basins: basins,
        states: states,
        titleholders: titleholders,
    };

    return (
        <>
            <h1>Wells and Boreholes</h1>
            <WellListView wells={wells} filters={filters} reference={reference} onSearchChanged={searchChanged} onWellsExport={getExportWells} />
        </>
    );
}

// connect redux
export default connect((state: any) => ({ state: state.well }), { setLayout, getReferenceDataRequestAction, getWellRequestAction, getWellExportRequestAction, clearWellExportResponseAction })(
    Controller
);
