// api
import * as Client from "../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as Forms from "../../../../../common/forms/BootstrapForms";

function TitleSummaryIlView(props: { summary: Client.GetPublicTitleDetailsSummaryDto | undefined; areaSqKm: number | undefined }) {
    const summary = props.summary;
    const areaSqKm = props.areaSqKm;

    return (
        <Forms.Row>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Status" id="statusField" value={summary?.titleStatus} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Offshore Area" id="offshoreAreaField" value={summary?.offshoreAreaName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Basin" id="basinField" value={summary?.basinName} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Initial Grant Date" id="initialGrantDateField" value={GlobalHelpers.toNoptaDateString(summary?.initialGrantDate)} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(summary?.currentExpiryDate, "Indefinite")} />
            </Forms.QuarterColumn>
            <Forms.QuarterColumn>
                <Forms.ReadonlyField label="Area" id="areaField" value={GlobalHelpers.toNoptaNumberString(areaSqKm, "n2", "km²")} />
            </Forms.QuarterColumn>
        </Forms.Row>
    );
}

export default TitleSummaryIlView;
