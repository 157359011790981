import { SyntheticEvent } from "react";
import cloneDeep from "lodash/cloneDeep";

// kendo
import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridColumnMenuCheckboxFilter, GridColumnMenuProps } from "@progress/kendo-react-grid";

// ** IMPORTANT **
// This code has been replicated in PEPI, SEPI and TERI and any change should be made across all three copies

// NOTE: The following is an adaptation of a proposed solution provided by Telerik
// Support ticket:
// https://www.telerik.com/account/support-center/view-ticket/1642255
// Proposed solution:
// https://stackblitz.com/edit/react-sjotkk-e4feqh?file=app%2fcolumnMenu.jsx

const blanksText = "(Blanks)";

function isNullOrEmpty(item: any | null | undefined) {
    if (item === null || item === undefined) {
        return true;
    }

    if (typeof item === "string" && item.trim() === "") {
        return true;
    }

    return false;
}

function getPropertyFromObject(object: any, property: string): any {
    if (typeof object === "undefined") {
        return false;
    }

    let _index = property.indexOf(".");
    if (_index > -1) {
        return getPropertyFromObject(object[property.substring(0, _index)], property.substring(_index + 1));
    }

    return object[property];
}

function GridColumnCheckboxFilterMenu(props: { gridColumnMenuProps: GridColumnMenuProps; data: any[] }) {
    const filteredData = props.data.filter((item) => !isNullOrEmpty(getPropertyFromObject(item, props.gridColumnMenuProps.column.field!)));
    const sortedData = orderBy(filteredData, [{ field: props.gridColumnMenuProps.column.field!, dir: "asc" }]);
    const data = sortedData.map((item) => {
        return { ...item };
    });

    if (data.length !== props.data.length) {
        // NullOrEmpty items exists so add blank item to filter
        let blankItem: any = {};
        blankItem[props.gridColumnMenuProps.column.field!] = blanksText;
        data.unshift(blankItem);
    }

    function convertFilter(filter: CompositeFilterDescriptor | undefined): CompositeFilterDescriptor | undefined {
        if (filter && filter.filters) {
            filter.filters.forEach((filterItem: FilterDescriptor | CompositeFilterDescriptor) => {
                if (isCompositeFilterDescriptor(filterItem)) {
                    const compositeFilterItem = filterItem as CompositeFilterDescriptor;
                    compositeFilterItem.filters.forEach((innerFilterItem: FilterDescriptor | CompositeFilterDescriptor) => {
                        const simpleInnerFilterItem = innerFilterItem as FilterDescriptor;
                        simpleInnerFilterItem.value = isNullOrEmpty(simpleInnerFilterItem.value) ? blanksText : simpleInnerFilterItem.value;
                    });
                }
            });
        }
        return filter;
    }

    function onFilterChange(filter: CompositeFilterDescriptor | null, syntheticEvent: SyntheticEvent<any, Event>) {
        function convertBackFilter(filter: CompositeFilterDescriptor | null): CompositeFilterDescriptor | null {
            if (filter && filter.filters) {
                filter.filters.forEach((filterItem: FilterDescriptor | CompositeFilterDescriptor) => {
                    if (isCompositeFilterDescriptor(filterItem)) {
                        const compositeFilterItem = filterItem as CompositeFilterDescriptor;
                        compositeFilterItem.filters.forEach((innerFilterItem: FilterDescriptor | CompositeFilterDescriptor) => {
                            const simpleInnerFilterItem = innerFilterItem as FilterDescriptor;
                            simpleInnerFilterItem.value = simpleInnerFilterItem.value === blanksText ? undefined : simpleInnerFilterItem.value;
                        });
                    }
                });
            }
            return filter;
        }
        props.gridColumnMenuProps.onFilterChange!(convertBackFilter(filter), syntheticEvent);
    }

    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props.gridColumnMenuProps}
                data={data}
                expanded={true}
                filter={convertFilter(cloneDeep(props.gridColumnMenuProps.filter))}
                onFilterChange={onFilterChange}
                searchBoxFilterOperator="contains"
            />
        </div>
    );
}

export function createGridColumnCheckboxFilterMenu(gridColumnMenuProps: GridColumnMenuProps, data: any[]) {
    return <GridColumnCheckboxFilterMenu gridColumnMenuProps={gridColumnMenuProps} data={data} />;
}
