function EnvironmentBadge() {
    const environment: string = process.env.REACT_APP_CONFIGURATION_ENVIRONMENT!;
    const version: string = process.env.REACT_APP_CONFIGURATION_VERSION!;
    const showBadge: boolean = process.env.REACT_APP_CONFIGURATION_SHOW_ENVIRONMENT_BADGE! === "true";

    if (!showBadge) return <></>;

    return (
        <div className="badge bg-danger align-self-center me-md-3" style={{ fontSize: 18 }}>
            <span>{environment}</span>
            <br />
            <span>Version: {version}</span>
        </div>
    );
}

export default EnvironmentBadge;
