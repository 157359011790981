import * as TitleDetailsHelper from "../../opggs/title/titleDetails/titleDetailsHelper";

// *** Get Details
enum OpenTitleActions {
    openTitle,
    downloadMemorialDocument,
}

interface IOpenTitleDetails {
    action: OpenTitleActions;
    titleNumber: string;
}

interface IDownloadMemorialDocumentDetails extends IOpenTitleDetails {
    applicationId: string;
}

// *** Generate URLs
export function generateOpenTitleUrl(titleNumber: string): string {
    const hostName = window.location.origin;
    return `${hostName}/Open/Title?titleNumber=${titleNumber}&action=${OpenTitleActions[OpenTitleActions.openTitle]}`;
}

export function generateDownloadMemorialDocumentUrl(titleNumber: string, applicationId: string): string {
    const hostName = window.location.origin;
    return `${hostName}/Open/Title?titleNumber=${titleNumber}&action=${OpenTitleActions[OpenTitleActions.downloadMemorialDocument]}&applicationId=${applicationId}`;
}

export function tryGetDetails(params: URLSearchParams): IOpenTitleDetails | null {
    const action = resolveAction(params);

    try {
        switch (action) {
            case null:
                return null;
            case OpenTitleActions.openTitle:
                return getOpenTitleActionDetails(params);
            case OpenTitleActions.downloadMemorialDocument:
                return getDownloadMemorialDocumentActionDetails(params);
        }
    } catch (e) {
        return null;
    }
}

function resolveAction(params: URLSearchParams): OpenTitleActions | null {
    const action = params.get("action");

    if (!action) return OpenTitleActions.openTitle; // default to Open Title

    if (stringCaseInsensitiveEquals(action!, OpenTitleActions[OpenTitleActions.openTitle])) return OpenTitleActions.openTitle;
    if (stringCaseInsensitiveEquals(action!, OpenTitleActions[OpenTitleActions.downloadMemorialDocument])) return OpenTitleActions.downloadMemorialDocument;

    return null;
}

function stringCaseInsensitiveEquals(a: string, b: string) {
    return a.localeCompare(b, undefined, { sensitivity: "base" }) === 0;
}

function getOpenTitleActionDetails(params: URLSearchParams): IOpenTitleDetails {
    const titleNumber = params.get("titleNumber");

    if (!titleNumber) throw new Error("Unable to resolve Title Number!");

    return {
        action: OpenTitleActions.openTitle,
        titleNumber: titleNumber,
    };
}

function getDownloadMemorialDocumentActionDetails(params: URLSearchParams): IDownloadMemorialDocumentDetails {
    const titleNumber = params.get("titleNumber");
    const applicationId = params.get("applicationId");

    if (!titleNumber) throw new Error("Unable to resolve Title Number!");
    if (!applicationId) throw new Error("Unable to resolve Application ID!");

    return {
        action: OpenTitleActions.downloadMemorialDocument,
        titleNumber: titleNumber,
        applicationId: applicationId,
    };
}

// *** Redirect URI

export function getRedirectUri(details: IOpenTitleDetails, titleId: string): string {
    switch (details.action) {
        case OpenTitleActions.openTitle:
            return TitleDetailsHelper.getOpenTitleRedirectUri(titleId);
        case OpenTitleActions.downloadMemorialDocument:
            const d = details as IDownloadMemorialDocumentDetails;
            return TitleDetailsHelper.getDownloadMemorialDocumentUri(titleId, d.applicationId);
        default:
            throw new Error("Unsupported action!");
    }
}
