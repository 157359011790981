// framework
import { ReactNode } from "react";
// other
import { LinkExternalIcon } from "@primer/octicons-react";

export default function ExternalLinkView(props: {
    href: string | undefined;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    children: ReactNode;
    title?: string | undefined;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
    return (
        <a
            href={props.href}
            className={props.className}
            style={props.style}
            target="_blank"
            rel="noopener noreferrer"
            title={props.title ?? "Open the associated link in a new browser tab or window."}
            onClick={props.onClick}
            onContextMenu={props.onContextMenu}
            onMouseDown={props.onMouseDown}
        >
            {props.children}
            <LinkExternalIcon className="ms-1" size={12} verticalAlign="text-top" />
        </a>
    );
}
