// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as OpenLicenceHelper from "../../../../../open/licence/openLicenceHelper";
import CopyLink from "../../../../../common/links/CopyLinkView";
import { DashIcon } from "@primer/octicons-react";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import * as SecureFileDownloadLinkControl from "../../../../../common/secureFileDownload/SecureFileDownloadLinkControl";

function MemorialView(props: { response: Client.GetPublicLicenceDetailsResponseDto | undefined }) {
    const response = props.response;
    const licenceNumber = response?.summary?.licenceNumber;
    const applications = response?.memorial ?? new Array<Client.GetPublicLicenceDetailsRegisterDto>(); // applications are sorted in the service

    const [gridState, setGridState] = useState({
        skip: 0,
        take: 10,
    } as State);

    function documentDownload(fileId: number | undefined, applicationId: string) {
        if (!fileId) {
            return <DashIcon size="small" />;
        }

        // licenceNumber will have a value when there is a file associated
        const uri = OpenLicenceHelper.generateDownloadMemorialDocumentUrl(licenceNumber!, applicationId);

        return (
            <span>
                <SecureFileDownloadLinkControl.LinkControl className="me-1" fileId={fileId} title="Click to download the document." />
                <CopyLink href={uri} />
            </span>
        );
    }

    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    <h2 className="card-title">Register</h2>
                    <Grid
                        pageable
                        resizable
                        navigatable
                        data={process(applications, gridState)}
                        {...gridState}
                        onDataStateChange={(e) => {
                            setGridState(e.dataState);
                        }}
                    >
                        <GridColumn field="applicationTypeName" title="Application Type" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
                        <GridColumn field="executedDate" title="Executed Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                        <GridColumn field="submittedDate" title="Submitted Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                        <GridColumn field="decision" title="Decision" width={200} />
                        <GridColumn field="implementedDate" title="Implemented Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                        <GridColumn field="registeredDate" title="Registered Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                        <GridColumn
                            field="fileId"
                            title="Document"
                            width={200}
                            cell={(props) => <CustomGridCell gridCellProps={props}>{documentDownload(props.dataItem.fileId, props.dataItem.applicationId)}</CustomGridCell>}
                        />
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default MemorialView;
