// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { ILicenceDetailsActions, licenceDetailsActions, getLicenceDetailsResponseAction } from "./licenceDetailsActions";
// api
import { createOeiLicenceDetailsClient } from "../../../../api/util";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(licenceDetailsActions.GetLicenceDetailsRequest, workerSaga);
}

function* workerSaga(action: ILicenceDetailsActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicLicenceDetailsResponseDto = yield call(query, action.licenceId!);
        // post response
        yield put(getLicenceDetailsResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(licenceId: string): Promise<Client.GetPublicLicenceDetailsResponseDto> {
    const client = createOeiLicenceDetailsClient();

    const request = new Client.GetPublicLicenceDetailsRequestDto({ licenceId: licenceId });

    const response = await simpleCall(() => client.getPublicLicenceDetails(request));
    return response;
}
