// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { ILicenceSearchActions, searchLicenceActions, searchLicenceResponseAction } from "./licenceSearchActions";
// api
import { createOeiLicencesSearchClient } from "../../../../api/util";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(searchLicenceActions.SearchLicencesRequest, workerSaga);
}

function* workerSaga(action: ILicenceSearchActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicLicencesListItemDto[] = yield call(query, action.filter!);
        // post response
        yield put(searchLicenceResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(filter: string): Promise<Client.GetPublicLicencesListItemDto[]> {
    const client = createOeiLicencesSearchClient();

    const request = new Client.GetPublicLicencesListRequestDto({ filterFullTextSearch: filter });

    const response = await simpleCall(() => client.getPublicLicencesList(request));
    return response.licences;
}
