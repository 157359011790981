// api
import * as Client from "../../../../api/Client";

function TitleMapView(props: { response: Client.GetPublicTitleDetailsMapResponseDto | undefined }) {
    const renderMapNotReady = (): any => {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    };

    const renderNoMap = (): any => {
        return <span>There is no map available for this Title.</span>;
    };

    const renderMapsOffline = (): any => {
        return <span>Maps are temporarily unavailable.</span>;
    };

    const renderMap = (r: Client.GetPublicTitleDetailsMapResponseDto): any => {
        let url = process.env.REACT_APP_MAP_TITLE;

        // https://doc.arcgis.com/en/arcgis-online/reference/embed-map-parameters.htm
        // note - using the Marker + Level methodology, instead of the Extent methodology
        //      - the extent isn't buffered, so it doesn't look great.
        //      - the zoom methodology actually seems OK
        url = url + "&marker=" + r.pinLatitude + ";" + r.pinLongitude + "&level=" + r.zoomLevel;
        // - this is the extent methodology, for reference.  extent and level are mutually exclusive.
        //+ "&extent=" + props.response.extentMinLatitude + "," + props.response.extentMinLongitude + "," + props.response.extentMaxLatitude + "," + props.response.extentMaxLongitude;

        return (
            <iframe
                title="An interactive map presenting the geographical offshore location of this Title in Australian territorial waters. The map is presented in an iframe with data sourced from NOPTA's ArcGIS online mapping service."
                src={url}
                style={{ height: "50vh", width: "100%", borderWidth: 1, borderStyle: "solid", borderColor: "lightgray" }}
            ></iframe>
        );
    };

    if (!props.response) {
        return renderMapNotReady();
    } else if (!props.response.isOnline) {
        return renderMapsOffline();
    } else if (!props.response.isFeatureAvailable) {
        return renderNoMap();
    } else {
        return renderMap(props.response);
    }
}

// connect redux
export default TitleMapView;
