// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// shell
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { getTitleDetailsRequestAction, getTitleMapDetailsRequestAction, clearTitleDetailsAction, clearTitleMapDetailsAction } from "../redux/titleDetailsActions";
// helpers
import * as TitleDetailsHelper from "../titleDetailsHelper";
// children views
import TitleDetailsView from "./TitleDetailsView";
import TitleDetailsConfiguration from "./TitleDetailsConfiguration";
import { ITitleDetailsState } from "../redux/titleDetailsReducers";

interface IRouteParameters {
    titleId: string;
}

function TitleDetailsController(props: any) {
    const { titleId } = useParams<IRouteParameters>();

    const { setLayout, getTitleDetailsRequestAction, clearTitleDetailsAction, getTitleMapDetailsRequestAction, clearTitleMapDetailsAction } = props;
    const state: ITitleDetailsState = props.state;

    // Title Details
    const response = state.response;

    // - initial title load
    useEffect(() => {
        getTitleDetailsRequestAction(titleId);

        return function onUnmount() {
            clearTitleDetailsAction();
        };
    }, [getTitleDetailsRequestAction, clearTitleDetailsAction, titleId]);

    // - set page header when response received
    useEffect(() => {
        if (!response) {
            setLayout("");
            return;
        }

        setLayout(response.titleTypeName + " " + response.titleNumber);
    }, [setLayout, response]);

    // Configuration
    var configuration = new TitleDetailsConfiguration(response?.titleType);

    // Title Map Details
    const mapResponse = state.mapResponse;

    // - initial map load
    useEffect(() => {
        getTitleMapDetailsRequestAction(titleId);

        return function onUnmount() {
            clearTitleMapDetailsAction();
        };
    }, [getTitleMapDetailsRequestAction, clearTitleMapDetailsAction, titleId]);

    // automation requests
    const [hasAutomationRun, setHasAutomationRun] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tab = TitleDetailsHelper.tryGetPreferredTab(params);
    useEffect(() => {
        if (!response) return; // only allow automation to run after the page has initialised
        if (hasAutomationRun) return; // only run automation once

        TitleDetailsHelper.tryOpenTitleMemorialDocument(state, params);

        setHasAutomationRun(true);
    }, [response, hasAutomationRun]); // eslint-disable-line react-hooks/exhaustive-deps

    // return
    return <TitleDetailsView response={response} mapResponse={mapResponse} configuration={configuration} preferredTab={tab} />;
}

// connect redux
export default connect((state: any) => ({ state: state.titleDetails }), {
    setLayout,
    getTitleDetailsRequestAction,
    clearTitleDetailsAction,
    getTitleMapDetailsRequestAction,
    clearTitleMapDetailsAction,
})(TitleDetailsController);
