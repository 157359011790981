import * as Client from "../../../api/Client";

export enum getWellDetailsActions {
    GetWellDetailsRequest = "NOPIMS.GET_WELL_DETAILS_REQUEST",
    GetWellDetailsResponse = "NOPIMS.GET_WELL_DETAILS_RESPONSE",
    ClearWellDetails = "NOPIMS.GET_WELL_DETAILS_CLEAR",
}

export interface IGetWellDetailsActions {
    type: getWellDetailsActions;
    wellId?: number | undefined;
    response?: Client.GetPublicWellDetailsResponseDto | undefined;
}

export function getWellDetailsRequestAction(wellId: number): IGetWellDetailsActions {
    return {
        type: getWellDetailsActions.GetWellDetailsRequest,
        wellId: wellId,
    };
}

export function getWellDetailsResponseAction(response: Client.GetPublicWellDetailsResponseDto): IGetWellDetailsActions {
    return {
        type: getWellDetailsActions.GetWellDetailsResponse,
        response: response,
    };
}

export function clearWellDetailsAction(): IGetWellDetailsActions {
    return {
        type: getWellDetailsActions.ClearWellDetails,
    };
}
