import { FallbackProps } from "react-error-boundary";
import { BugIcon } from "@primer/octicons-react";

function ErrorControl(props: FallbackProps) {
    // using an anchor tag, as this forces a complete reset of state

    return (
        <div className="container mt-5">
            <div className="p-5 mb-4 bg-light rounded-3">
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2" style={{ minWidth: 100 }}>
                            <BugIcon size={96} className="me-4" aria-label="error" />
                        </div>
                        <div className="col-10">
                            <h1 className="d-inline-block">An unexpected error has occurred on the page.</h1>
                            <p className="mt-4">If the problem persists, please contact support for assistance.</p>
                            <p>
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.reload();
                                    }}
                                >
                                    Reload the Page
                                </a>{" "}
                                | <a href="/">Return Home</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorControl;
