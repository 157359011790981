// framework
import { Link } from "react-router-dom";

export default function TitleLinkView(props: { titleId: string | undefined; titleNumber: string | undefined }) {
    if (props.titleId) {
        return (
            <Link to={"/Opggs/Title/" + props.titleId} title="Click to view Title details.">
                {props.titleNumber}
            </Link>
        );
    }
    return <>{props.titleNumber}</>;
}
