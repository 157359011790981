// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// shell
import { setLayout } from "../../shell/redux/shellActions";
// other
import heroImageLeft from "./home-banner-left.jpg";
import heroImageRight from "./home-banner-right.jpg";
import noptaImage from "./NOPTA_logo.png";
import oriImage from "./AG_OIR_inline_rev.png";
import ExternalLinkView from "../../common/externalLink/ExternalLinkView";
import EmailLinkView from "../../common/externalLink/EmailLinkView";

function HomePage(props: any) {
    const { setLayout } = props;

    // initial load
    useEffect(() => {
        setLayout("Home", false, false);
    }, [setLayout]);

    const noptaLogoImageAlt: string = "Australian Government, National Offshore Petroleum Titles Administrator logo";
    const oirLogoImageAlt: string = "Australian Government, Offshore Infrastructure Registrar logo";

    return (
        <>
            <div style={{ position: "relative", display: "block", width: "100%", height: "auto" }} className="mb-4">
                {/* Hero Image */}
                <a href="/Opggs/Title" title="Click to view OPGGS Title Search.">
                    <img className="border-1 border-end border-warning" alt="" src={heroImageLeft} style={{ width: "50%" }} />
                </a>
                <a href="/Oei/Licence/Search" title="Click to view OEI Licence Search.">
                    <img className="border-1 border-start border-warning" alt="" src={heroImageRight} style={{ width: "50%" }} />
                </a>

                {/* Logos */}
                {/* the logo image needs to be readable and prominent... but not introduce a horizontal scrollbar at 350px */}
                <div style={{ position: "absolute", left: "0px", top: "0px" }}>
                    <img className="ms-1 mt-1 d-block d-sm-none d-md-none d-lg-none d-xl-none float-start" src={noptaImage} alt={noptaLogoImageAlt} style={{ width: "150px" }} />
                    <img className="ms-2 mt-2 d-none d-sm-inline d-md-none d-lg-none d-xl-none float-start" src={noptaImage} alt={noptaLogoImageAlt} style={{ width: "265px" }} />
                    <img className="ms-3 mt-3 d-none d-sm-none d-md-inline d-lg-none d-xl-none float-start" src={noptaImage} alt={noptaLogoImageAlt} style={{ width: "352px" }} />
                    <img className="ms-4 mt-4 d-none d-sm-none d-md-none d-lg-inline d-xl-inline float-start" src={noptaImage} alt={noptaLogoImageAlt} style={{ width: "420px" }} />
                </div>
                <div style={{ position: "absolute", left: "50%", top: "0px" }}>
                    <img className="ms-1 mt-1 me-1 d-block d-sm-none d-md-none d-lg-none d-xl-none float-start" src={oriImage} alt={oirLogoImageAlt} style={{ width: "109px" }} />
                    <img className="ms-2 mt-2 d-none d-sm-inline d-md-none d-lg-none d-xl-none float-start" src={oriImage} alt={oirLogoImageAlt} style={{ width: "192px" }} />
                    <img className="ms-3 mt-3 d-none d-sm-none d-md-inline d-lg-none d-xl-none float-none" src={oriImage} alt={oirLogoImageAlt} style={{ width: "255px" }} />
                    <img className="ms-4 mt-4 d-none d-sm-none d-md-none d-lg-inline d-xl-inline float-start" src={oriImage} alt={oirLogoImageAlt} style={{ width: "304px" }} />
                </div>
            </div>

            <div className="container">
                <h1 className="fw-bold">Welcome to the National Electronic Approvals Tracking System</h1>
                <p>
                    The National Electronic Approvals Tracking System (NEATS) provides access to publicly available information concerning offshore petroleum and greenhouse gas storage titles under
                    the <i>Offshore Petroleum and Greenhouse Gas Storage Act 2006</i> (OPGGS Act) and offshore electricity infrastructure licences under the{" "}
                    <i>Offshore Electricity Infrastructure Act 2021</i> (OEI Act).
                </p>
                <p>The key features of NEATS include:</p>
                <ul>
                    <li>The ability to search for titles and licences using a range of search criteria,</li>
                    <li>Individual title and licence pages, with key details relating to the title or licence, and a chronological order of memorials,</li>
                    <li>Application tracking in relation to current applications, and search capability in relation to completed applications over the past year,</li>
                    <li>An interactive map of all offshore titles and licences, and</li>
                    <li>Contains the Register of Offshore Infrastructure Licences under the OEI Act.</li>
                </ul>
                <p>Titleholders and licence holders also have access to a Secure Portal for a consolidated view of the titles and/or licences in which they participate.</p>
                <p>NEATS will continue to be enhanced over time.</p>
                <p>
                    Please visit{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au" className="card-link">
                        www.nopta.gov.au
                    </ExternalLinkView>{" "}
                    to learn more about NOPTA, or{" "}
                    <ExternalLinkView href="https://www.offshoreregistrar.gov.au/" className="card-link">
                        www.offshoreregistrar.gov.au
                    </ExternalLinkView>{" "}
                    to learn more about the Offshore Infrastructure Registrar, and to access links to legislation, regulations, guidance material and{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au/application-processes/greenhouse-gas/ghg-register-dosf.html" className="card-link">
                        Register of Identified Greenhouse Gas Storage Formations.
                    </ExternalLinkView>
                </p>

                <h2>Contact Details</h2>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <span className="fw-bold ">OPGGS Titles Enquiries</span>
                            <p>
                                Telephone:{" "}
                                <a href="tel:+61864245317" className="telno">
                                    +61 8 6424 5317
                                </a>
                                <br />
                                Email: <EmailLinkView emailAddress="titles@nopta.gov.au" />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <span className="fw-bold ">GHG Titles Enquiries</span>
                            <p>
                                Telephone:{" "}
                                <a href="tel:+61864245317" className="telno">
                                    +61 8 6424 5317
                                </a>
                                <br />
                                Email: <EmailLinkView emailAddress="ghg@nopta.gov.au" />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <span className="fw-bold ">OEI Licence Enquiries</span>
                            <p>
                                Telephone:{" "}
                                <a href="tel:+61864245306" className="telno">
                                    +61 8 6424 5306
                                </a>
                                <br />
                                Email: <EmailLinkView emailAddress="offshoreelectricity@nopta.gov.au" />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <span className="fw-bold">NEATS Technical and Data Queries</span>
                            <p>
                                Email: <EmailLinkView emailAddress="neats.admin@nopta.gov.au" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

// connect redux
export default connect((state: any) => ({}), { setLayout })(HomePage);
