import { getServicesUri } from "../../api/uriHelper";

export interface IClient {
    initialise(id: number): void;
    getUrl(): string;
}

export class Client implements IClient {
    private _id: number | undefined;

    public initialise(id: number) {
        this._assertNotInitialised();
        this._id = id;
    }

    public getUrl(): string {
        this._assertInitialised();

        // this returns the unauthenticated URL which uses the token
        // the ids are returned to help avoid caching and provide better traceability in logs
        // they are also cross-checked server-side

        const query = this._getQueryString();
        return getServicesUri() + "/file/public/download/content?" + query;
    }

    private _getQueryString() {
        return "id=" + this._id;
    }

    private _assertInitialised() {
        if (!this._id) throw new Error("Not yet intialised.");
    }

    private _assertNotInitialised() {
        if (this._id) throw new Error("Already intialised.");
    }
}
