export function getServicesUri(): string {
    // detects and uses the services URI based on where the webpage is running from

    const intranetUri: string = process.env.REACT_APP_NEATS_PORTAL_SERVICES_INTRANET_URI ?? "";
    const internetUri: string = process.env.REACT_APP_NEATS_PORTAL_SERVICES_INTERNET_URI ?? "";
    const internetHostnameSuffix: string = process.env.REACT_APP_NEATS_PORTAL_SERVICES_INTERNET_HOSTNAME_SUFFIX ?? "";

    const hostname = window.location.hostname;

    if (internetHostnameSuffix === "") {
        return intranetUri;
    }

    if (hostname.endsWith(internetHostnameSuffix)) {
        return internetUri;
    }

    return intranetUri;
}
