import * as Forms from "../../../common/forms/BootstrapForms";
import * as Client from "../../../api/Client";
import * as GlobalHelpers from "../../../common/GlobalHelpers";

function WellDetailsView(props: { response: Client.IGetPublicWellDetailsResponseDto }) {
    const boreholes = props.response.boreholes ?? [];

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="cardTitle">Well Details</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="uwiField" label="UWI" value={props.response.uwi} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="titleField" label="Title" value={props.response.titleNumber} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="titleholderField" label="Titleholder" value={props.response.titleholderCompanyName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="basinField" label="Basin" value={props.response.basin} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="subBasinField" label="Sub Basin" value={props.response.subBasin} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="stateField" label="State" value={props.response.state} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="wellStatusField" label="Well Status" value={props.response.wellStatus} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="legislationField" label="Legislation" value={props.response.legislationType} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="jurisdictionField" label="Jurisdiction" value={props.response.jurisdiction} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="spudDateField" label="Spud Date" value={GlobalHelpers.toNoptaDateString(props.response.spudDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="isOffshoreField" label="Is Offshore" value={props.response.isOffshore ? "Yes" : "No"} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>

            {/* Button to change into a group of buttons in the future and switch display, no other views are currently available. */}
            <div className="btn-group-toggle mb-2" data-toggle="buttons">
                <label className="btn btn-primary">Boreholes</label>
            </div>

            <BoreholeDetailsView boreholes={boreholes} />
        </>
    );
}

function BoreholeDetailsView(props: { boreholes: Client.GetPublicWellBoreholeDto[] }) {
    const boreholes = props.boreholes;
    return (
        <>
            {boreholes.map((bh) => (
                <div key={bh.name} className="card mb-2">
                    <div className="card-body">
                        <h2 className="cardTitle">{"Borehole - " + bh.name}</h2>
                        <Forms.Row>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="ubhiField" label="UBHI" value={bh.ubhi} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="aliasesField" label="Aliases" value={bh.aliases} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id={bh.name + "titleholderField"} label="Titleholder" value={bh.titleholderCompanyName} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="drillingCompanyField" label="Drilling Company" value={bh.drillingCompany} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="rigField" label="Rig" value={bh.rig} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="kickOffDateField" label="Kick Off Date" value={GlobalHelpers.toNoptaDateString(bh.kickOffDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="rigReleaseDateField" label="Rig Release Date" value={GlobalHelpers.toNoptaDateString(bh.rigReleaseDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="tdDateField" label="Total Depth Date" value={GlobalHelpers.toNoptaDateString(bh.totalDepthDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="basicRelevantDateField" label="Basic Relevant Date" value={GlobalHelpers.toNoptaDateString(bh.basicRelevantDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="basicInterpDateField" label="Interp Relevant Date" value={GlobalHelpers.toNoptaDateString(bh.interpretativeRelevantDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="drillersTDField" label="Drillers Total Depth (m)" value={bh.drillersTotalDepth} />
                            </Forms.QuarterColumn>
                        </Forms.Row>
                    </div>
                </div>
            ))}
        </>
    );
}

export default WellDetailsView;
