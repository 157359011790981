// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { getWellDetailsActions, getWellDetailsResponseAction, IGetWellDetailsActions } from "./actions";
// api
import { createWellClient } from "../../../api/util";
import { simpleCall } from "../../../api/callHelper";
import * as Client from "../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(getWellDetailsActions.GetWellDetailsRequest, workerSaga);
}

function* workerSaga(action: IGetWellDetailsActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicWellDetailsResponseDto = yield call(query, action!);
        // post response
        yield put(getWellDetailsResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(action: IGetWellDetailsActions): Promise<Client.GetPublicWellDetailsResponseDto> {
    const client = createWellClient();

    const request = new Client.GetPublicWellDetailsRequestDto({
        wellId: action.wellId!,
    });

    const response = await simpleCall(() => client.getPublicWellDetails(request));

    return response;
}
