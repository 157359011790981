import { searchLicenceActions, ILicenceSearchActions } from "./licenceSearchActions";
import * as Client from "../../../../api/Client";

// initial state
export interface ILicenceSearchState {
    filter?: string | undefined;
    licences?: Client.GetPublicLicencesListItemDto[] | undefined;
}

const initialState: ILicenceSearchState = {
    filter: "",
    licences: [],
};

// reducers
export function licenceSearchReducer(state: ILicenceSearchState = initialState, action: ILicenceSearchActions): ILicenceSearchState {
    switch (action.type) {
        case searchLicenceActions.SearchLicencesRequest:
            return {
                ...state,
                filter: action.filter,
                licences: [],
            };

        case searchLicenceActions.SearchLicencesResponse:
            return {
                ...state,
                licences: action.licences,
            };

        default:
            return state;
    }
}
