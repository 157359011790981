// framework
import React, { useEffect, useState } from "react";
// kendo
import KendoComboBox from "../../../../common/kendo/KendoComboBox";
import KendoDropDownList from "../../../../common/kendo/KendoDropDownList";
import KendoLargeComboBox from "../../../../common/kendo/KendoLargeComboBox";
// api
import * as Client from "../../../../api/Client";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
// redux
import * as Actions from "../../redux/actions";
// models
import { IFeedbackViewModel, IRootViewModel } from "../../models/models";
import validator from "../../models/validator";

interface IProps {
    vm: IRootViewModel;
    actions: Actions.IActionFactory;
}

export default function FeedbackCreateView(props: IProps): React.ReactElement {
    const { vm, actions } = props;

    const [state, setState] = useState<IFeedbackViewModel>(vm.feedback);
    const honorifics = vm.referenceData.honorifics;
    const feedbackTypes = vm.referenceData.feedbackTypes;
    const dataFeedbackActivityTypes = vm.referenceData.dataFeedbackActivityTypes;
    const dataFeedbackActivityNames = vm.referenceData.dataFeedbackActivityNames;
    const dataMonitoringItemTypes = vm.referenceData.dataMonitoringItemTypes;

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    useEffect(() => {
        setState(vm.feedback);
    }, [vm.feedback]);

    function updateViewModel() {
        actions.viewChanged(vm.refreshFeedbackDetails({ ...state }));
    }

    function updateStateAndViewModel(state: IFeedbackViewModel) {
        setState(state);
        actions.viewChanged(vm.refreshFeedbackDetails({ ...state }));
    }

    // cascading reference data
    const [filteredDataFeedbackActivityNames, setFilteredDataFeedbackActivityNames] = useState<Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackActivityNameDto[]>([]);
    useEffect(() => {
        setFilteredDataFeedbackActivityNames(state.dataFeedbackActivityType ? dataFeedbackActivityNames.filter((n) => n.dataFeedbackActivityTypeId === state.dataFeedbackActivityType!.id) : []);
    }, [dataFeedbackActivityNames, state.dataFeedbackActivityType]);

    const [filteredDataMonitoringItemTypes, setFilteredDataMonitoringItemTypes] = useState<Client.GetCreatePublicFeedbackReferenceDataResponseDataMonitoringItemTypeDto[]>([]);
    useEffect(() => {
        setFilteredDataMonitoringItemTypes(state.dataFeedbackActivityType ? dataMonitoringItemTypes.filter((n) => n.dataFeedbackActivityTypeId === state.dataFeedbackActivityType!.id) : []);
    }, [dataMonitoringItemTypes, state.dataFeedbackActivityType]);

    function onReset() {
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.initialiseRequested();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    function onSubmit() {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        actions.submitRequested();
    }

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="honorificField">Title</Forms.Label>
                                <KendoComboBox
                                    id="honorificField"
                                    data={honorifics}
                                    keyField="id"
                                    textField="name"
                                    filterable={false}
                                    suggest={true}
                                    value={state.honorific}
                                    onChange={(value) => updateStateAndViewModel({ ...state, honorific: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerFirstNameField"
                                    label="First Name"
                                    value={state.customerFirstName}
                                    valid={validation.applyValidity("CreateFeedback.CustomerFirstName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerFirstName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerFirstName" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerLastNameField"
                                    label="Last Name"
                                    value={state.customerLastName}
                                    valid={validation.applyValidity("CreateFeedback.CustomerLastName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerLastName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerLastName" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerCompanyNameField"
                                    label="Company"
                                    value={state.customerCompanyName}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerCompanyName: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerContactNumberField"
                                    label="Contact Number"
                                    value={state.customerContactNumber}
                                    valid={validation.applyValidity("CreateFeedback.CustomerContactNumber")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerContactNumber: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerContactNumber" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerEmailAddressField"
                                    label="Email"
                                    value={state.customerEmailAddress}
                                    valid={validation.applyValidity("CreateFeedback.CustomerEmailAddress")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerEmailAddress: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerEmailAddress" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Request Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="feedbackTypeField">Type</Forms.Label>
                                <KendoDropDownList
                                    id="feedbackTypeField"
                                    data={feedbackTypes}
                                    keyField="id"
                                    textField="name"
                                    value={state.feedbackType}
                                    valid={validation.applyValidity("CreateFeedback.FeedbackType")}
                                    onChange={(value) =>
                                        updateStateAndViewModel({
                                            ...state,
                                            feedbackType: value,
                                            dataFeedbackActivityType: undefined,
                                            dataFeedbackActivityName: undefined,
                                            dataMonitoringItemType: undefined,
                                        })
                                    }
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.FeedbackType" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {state.feedbackType?.isDataFeedbackType && (
                        <>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.Label htmlFor="dataFeedbackActivityTypeField">Activity Type</Forms.Label>
                                        <KendoDropDownList
                                            id="dataFeedbackActivityTypeField"
                                            data={dataFeedbackActivityTypes}
                                            keyField="id"
                                            textField="name"
                                            value={state.dataFeedbackActivityType}
                                            valid={validation.applyValidity("CreateFeedback.DataFeedbackActivityType")}
                                            onChange={(value) => updateStateAndViewModel({ ...state, dataFeedbackActivityType: value })}
                                            onBlur={updateViewModel}
                                        />
                                        <ValidationMessageView name="CreateFeedback.DataFeedbackActivityType" validationModel={validation} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.Label htmlFor="dataFeedbackActivityNameField">Activity Name</Forms.Label>
                                        <KendoLargeComboBox
                                            id="dataFeedbackActivityNameField"
                                            data={filteredDataFeedbackActivityNames}
                                            keyField="wellId"
                                            textField="name"
                                            filterable={true}
                                            suggest={true}
                                            value={state.dataFeedbackActivityName}
                                            disabled={!state.dataFeedbackActivityType}
                                            valid={validation.applyValidity("CreateFeedback.DataFeedbackActivityName")}
                                            onChange={(value) => updateStateAndViewModel({ ...state, dataFeedbackActivityName: value })}
                                            onBlur={updateViewModel}
                                        />
                                        <ValidationMessageView name="CreateFeedback.DataFeedbackActivityName" validationModel={validation} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.Label htmlFor="dataMonitoringItemTypeField">Data Type</Forms.Label>
                                        <KendoComboBox
                                            id="dataMonitoringItemTypeField"
                                            data={filteredDataMonitoringItemTypes}
                                            keyField="id"
                                            textField="name"
                                            filterable={true}
                                            suggest={true}
                                            value={state.dataMonitoringItemType}
                                            disabled={!state.dataFeedbackActivityType}
                                            valid={validation.applyValidity("CreateFeedback.DataMonitoringItemType")}
                                            onChange={(value) => updateStateAndViewModel({ ...state, dataMonitoringItemType: value })}
                                            onBlur={updateViewModel}
                                        />
                                        <ValidationMessageView name="CreateFeedback.DataMonitoringItemType" validationModel={validation} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.TextArea
                                    id="customerCommentsField"
                                    label="Comments"
                                    value={state.customerComments}
                                    valid={validation.applyValidity("CreateFeedback.CustomerComments")}
                                    maxLength={4000}
                                    onChange={(value) => setState({ ...state, customerComments: value })}
                                    onBlur={updateViewModel}
                                    rows={5}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerComments" validationModel={validation} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={onReset}>
                            Reset
                        </button>
                        <button className="btn btn-primary me-2" type="button" onClick={onSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
