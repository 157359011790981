import * as LicenceDetailsHelper from "../../oei/licence/details/licenceDetailsHelper";

// *** Get Details
enum OpenLicenceActions {
    openLicence,
    downloadMemorialDocument,
}

interface IOpenLicenceDetails {
    action: OpenLicenceActions;
    licenceNumber: string;
}

interface IDownloadMemorialDocumentDetails extends IOpenLicenceDetails {
    applicationId: string;
}

// *** Generate URLs
export function generateOpenLicenceUrl(licenceNumber: string): string {
    const hostName = window.location.origin;
    return `${hostName}/Open/Licence?licenceNumber=${licenceNumber}&action=${OpenLicenceActions[OpenLicenceActions.openLicence]}`;
}

export function generateDownloadMemorialDocumentUrl(licenceNumber: string, applicationId: string): string {
    const hostName = window.location.origin;
    return `${hostName}/Open/Licence?licenceNumber=${licenceNumber}&action=${OpenLicenceActions[OpenLicenceActions.downloadMemorialDocument]}&applicationId=${applicationId}`;
}

export function tryGetDetails(params: URLSearchParams): IOpenLicenceDetails | null {
    const action = resolveAction(params);

    try {
        switch (action) {
            case null:
                return null;
            case OpenLicenceActions.openLicence:
                return getOpenLicenceActionDetails(params);
            case OpenLicenceActions.downloadMemorialDocument:
                return getDownloadMemorialDocumentActionDetails(params);
        }
    } catch (e) {
        return null;
    }
}

function resolveAction(params: URLSearchParams): OpenLicenceActions | null {
    const action = params.get("action");

    if (!action) return OpenLicenceActions.openLicence; // default to Open Licence

    if (stringCaseInsensitiveEquals(action!, OpenLicenceActions[OpenLicenceActions.openLicence])) return OpenLicenceActions.openLicence;
    if (stringCaseInsensitiveEquals(action!, OpenLicenceActions[OpenLicenceActions.downloadMemorialDocument])) return OpenLicenceActions.downloadMemorialDocument;

    return null;
}

function stringCaseInsensitiveEquals(a: string, b: string) {
    return a.localeCompare(b, undefined, { sensitivity: "base" }) === 0;
}

function getOpenLicenceActionDetails(params: URLSearchParams): IOpenLicenceDetails {
    const licenceNumber = params.get("licenceNumber");

    if (!licenceNumber) throw new Error("Unable to resolve Licence Number!");

    return {
        action: OpenLicenceActions.openLicence,
        licenceNumber: licenceNumber,
    };
}

function getDownloadMemorialDocumentActionDetails(params: URLSearchParams): IDownloadMemorialDocumentDetails {
    const licenceNumber = params.get("licenceNumber");
    const applicationId = params.get("applicationId");

    if (!licenceNumber) throw new Error("Unable to resolve Licence Number!");
    if (!applicationId) throw new Error("Unable to resolve Application ID!");

    return {
        action: OpenLicenceActions.downloadMemorialDocument,
        licenceNumber: licenceNumber,
        applicationId: applicationId,
    };
}

// *** Redirect URI

export function getRedirectUri(details: IOpenLicenceDetails, licenceId: string): string {
    switch (details.action) {
        case OpenLicenceActions.openLicence:
            return LicenceDetailsHelper.getOpenLicenceRedirectUri(licenceId);
        case OpenLicenceActions.downloadMemorialDocument:
            const d = details as IDownloadMemorialDocumentDetails;
            return LicenceDetailsHelper.getDownloadMemorialDocumentUri(licenceId, d.applicationId);
        default:
            throw new Error("Unsupported action!");
    }
}
