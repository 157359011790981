// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import { getOeiApplicationTrackingRequestAction } from "../redux/oeiApplicationTrackingActions";
// children
import OeiApplicationTrackingListView from "./OeiApplicationTrackingListView";
import { IOeiApplicationTrackingState } from "../redux/oeiApplicationTrackingReducers";
import * as Client from "../../../../api/Client";

function OeiApplicationTrackingController(props: any) {
    const { setLayout, getOeiApplicationTrackingRequestAction } = props;

    const state: IOeiApplicationTrackingState = props.state;
    const isCompleted = state.isCompleted ?? false;
    const applicationTrackingType = state.applicationTrackingType;
    // Application Tracking
    const response = state.response;
    const applicationTrackingTypes = response?.applicationTrackingTypes ?? new Array<Client.GetPublicOeiApplicationsTrackingTrackingTypeDto>();
    const typeSummaryItems = response?.applicationTrackingTypeSummary ?? new Array<Client.GetPublicOeiApplicationsTrackingTrackingTypeSummaryItemDto>();
    const statusSummaryItems = response?.applicationTrackingStatusSummary ?? new Array<Client.GetPublicOeiApplicationsTrackingTrackingStatusSummaryItemDto>();
    const listItems = response?.applicationList ?? new Array<Client.GetPublicOeiApplicationsTrackingApplicationListItemDto>();

    // initial load
    useEffect(() => {
        setLayout("OEI Application Tracking");
        getOeiApplicationTrackingRequestAction(isCompleted, applicationTrackingType);
    }, [setLayout, getOeiApplicationTrackingRequestAction, isCompleted, applicationTrackingType]);

    // change
    function onFormSubmit(isCompleted: boolean, applicationTrackingType: Client.OeiApplicationTrackingTypeEnum | undefined) {
        getOeiApplicationTrackingRequestAction(isCompleted, applicationTrackingType);
    }

    // return
    return (
        <OeiApplicationTrackingListView
            isCompleted={isCompleted}
            applicationTrackingType={applicationTrackingType}
            applicationTrackingTypes={applicationTrackingTypes}
            typeSummaryItems={typeSummaryItems}
            statusSummaryItems={statusSummaryItems}
            listItems={listItems}
            onFormSubmit={onFormSubmit}
        />
    );
}

// connect redux
export default connect((state: any) => ({ state: state.oeiApplicationTracking }), { setLayout, getOeiApplicationTrackingRequestAction })(OeiApplicationTrackingController);
