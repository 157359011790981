// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// api
import { refreshStaticConfigurationRequestAction } from "../redux/shellActions";
import * as Actions from "../redux/shellActions";
import * as Reducers from "../redux/shellReducers";

interface IConfigurationControllerProps {
    state: Reducers.IShellState;
    children: React.ReactNode;
    refreshStaticConfigurationRequestAction(): Actions.IShellActions;
}

function ConfigurationController(props: IConfigurationControllerProps) {
    const { state, children, refreshStaticConfigurationRequestAction } = props;

    useEffect(() => {
        console.debug("Updating Static Configuration...");
        refreshStaticConfigurationRequestAction();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // return - if the static configuration data is loaded
    if (!state.isStaticConfigurationLoaded) return <></>;
    return <>{children}</>;
}

// connect redux
export default connect((state: any) => ({ state: state.shellConfiguration }), {
    refreshStaticConfigurationRequestAction,
})(ConfigurationController);
