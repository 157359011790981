// framework
import { ReactNode } from "react";
// other
import { MailIcon } from "@primer/octicons-react";

export default function EmailLinkView(props: {
    emailAddress: string | undefined;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    children?: ReactNode | undefined;
    title?: string | undefined;
}) {
    // suppressing line breaks for emails as emails cannot have spaces in them, hence they can get a little long and the line break gets orphaned
    return (
        <span style={{ whiteSpace: "nowrap" }}>
            <a title={props.title ?? "Create a new email."} href={props.emailAddress ? "mailto:" + props.emailAddress : "#"} className={props.className} style={props.style}>
                {props.children ?? props.emailAddress}
                <MailIcon className="ms-1" size={12} verticalAlign="text-top" />
            </a>
        </span>
    );
}
