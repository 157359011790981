import { IValidationModel, ValidationVisibilityEnum } from "./ValidationModel";

export default function ValidationMessageView(props: { name: string; validationModel: IValidationModel }) {
    const name = props.name;
    const vm = props.validationModel;

    // not visible
    if (vm.visibility === ValidationVisibilityEnum.None) return <></>;

    const errors = vm.getErrors(name);

    // no errors
    if (errors.length === 0) return <></>;

    // errors
    // note: intentionally not using className="invalid-feedback" because this is only visible when preceded by an element with className="is-invalid"
    // and we want to be able to control the visibility of adorners and messages independently
    return (
        <div className="text-danger small mt-1">
            {errors.map((e, index) => (
                <div key={`${name}/${index}`}>{e}</div>
            ))}
        </div>
    );
}
